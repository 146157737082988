import { Card, InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

type ParamterProp = {
  defaultValue?: any;
  onChange?: (data) => void;
  hasCute?: boolean;
};

export type ParamData = {
  param1: {
    progressRate: number;
    accumulateRate: number;
    cuteEate: number;
  };
  param2: {
    progressRate: number;
    accumulateRate: number;
    cuteEate: number;
  };
  param3: {
    progressRate: number;
    accumulateRate: number;
    cuteEate: number;
  };
};

const Paramter: React.FC<ParamterProp> = (props: ParamterProp) => {
  const { defaultValue, onChange, hasCute = true } = props;

  const gridStyle: React.CSSProperties = {
    width: hasCute ? '25%' : '33.33%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 8px'
  };

  const [data, setData] = useState<ParamData>(
    defaultValue ||
      (hasCute
        ? {
            param1: {
              progressRate: 1,
              accumulateRate: 0.66,
              cuteEate: 1,
            },
            param2: {
              progressRate: 1,
              accumulateRate: 0.66,
              cuteEate: 1,
            },
            param3: {
              progressRate: 1,
              accumulateRate: 0.66,
              cuteEate: 1,
            },
          }
        : {
            param1: {
              progressRate: 1,
              accumulateRate: 0.66,
              cuteEate: 1,
            },
            param2: {
              progressRate: 1,
              accumulateRate: 0.66,
              cuteEate: 1,
            },
          })
  );

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <Card
        title="パラメータ"
        styles={{
          body: {
            display: 'flex',
            flexWrap: 'wrap',
            padding: 6,
          },
        }}
      >
        <>
          <Card.Grid hoverable={false} style={gridStyle}></Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            施策進捗率
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            積込率
          </Card.Grid>
          {hasCute && (
            <Card.Grid hoverable={false} style={gridStyle}>
              刈取率
            </Card.Grid>
          )}
        </>
        <>
          <Card.Grid hoverable={false} style={gridStyle}>
            パラメータ1
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <InputNumber
              value={data.param1.progressRate}
              step={0.01}
              onChange={(value) => {
                setData((pref) => {
                  pref.param1.progressRate = value;
                  return cloneDeep(pref);
                });
              }}
            />
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <InputNumber
              value={data.param1.accumulateRate}
              step={0.01}
              onChange={(value) => {
                setData((pref) => {
                  pref.param1.accumulateRate = value;
                  return cloneDeep(pref);
                });
              }}
            />
          </Card.Grid>
          {hasCute && (
            <Card.Grid hoverable={false} style={gridStyle}>
              <InputNumber
                value={data.param1.cuteEate}
                step={0.01}
                onChange={(value) => {
                  setData((pref) => {
                    pref.param1.cuteEate = value;
                    return cloneDeep(pref);
                  });
                }}
              />
            </Card.Grid>
          )}
        </>
        <>
          <Card.Grid hoverable={false} style={gridStyle}>
            パラメータ2
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <InputNumber
              value={data.param2.progressRate}
              step={0.01}
              onChange={(value) => {
                setData((pref) => {
                  pref.param2.progressRate = value;
                  return cloneDeep(pref);
                });
              }}
            />
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <InputNumber
              value={data.param2.accumulateRate}
              step={0.01}
              onChange={(value) => {
                setData((pref) => {
                  pref.param2.accumulateRate = value;
                  return cloneDeep(pref);
                });
              }}
            />
          </Card.Grid>
          {hasCute && (
            <Card.Grid hoverable={false} style={gridStyle}>
              <InputNumber
                value={data.param2.cuteEate}
                step={0.01}
                onChange={(value) => {
                  setData((pref) => {
                    pref.param2.cuteEate = value;
                    return cloneDeep(pref);
                  });
                }}
              />
            </Card.Grid>
          )}
        </>
        <>
          <Card.Grid hoverable={false} style={gridStyle}>
            パラメータ3
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <InputNumber
              value={data.param3.progressRate}
              step={0.01}
              onChange={(value) => {
                setData((pref) => {
                  pref.param3.progressRate = value;
                  return cloneDeep(pref);
                });
              }}
            />
          </Card.Grid>
          <Card.Grid hoverable={false} style={gridStyle}>
            <InputNumber
              value={data.param3.accumulateRate}
              step={0.01}
              onChange={(value) => {
                setData((pref) => {
                  pref.param3.accumulateRate = value;
                  return cloneDeep(pref);
                });
              }}
            />
          </Card.Grid>
          {hasCute && (
            <Card.Grid hoverable={false} style={gridStyle}>
              <InputNumber
                value={data.param3.cuteEate}
                step={0.01}
                onChange={(value) => {
                  setData((pref) => {
                    pref.param3.cuteEate = value;
                    return cloneDeep(pref);
                  });
                }}
              />
            </Card.Grid>
          )}
        </>
      </Card>
    </>
  );
};

export default Paramter;
