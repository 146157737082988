/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import { Select, Switch, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import { TypicalKpiContext } from '../contexts/typical-kpi.context';
import { useAccountPerspective } from '../hooks/useAccountPerspective';
import APIList from '../http/ApiList';
import LayoutSwitcher from './common/layout/layout-switcher';
import { exportTableFromExcelTemp } from '../utils/xlsxReader';
import dayjs from 'dayjs';

type TypicalKpiHeaderProp = {
  children: React.ReactNode;
  snapshot: string;
  snapshotVersion: string;
};

const TypicalKpiHeader: React.FC<any> = (props: TypicalKpiHeaderProp) => {
  const { children, snapshot, snapshotVersion } = props;
  const { t } = useTranslation();
  const { periodList, color } = useContext(AppContext);
  const {
    kpiSwitch,
    kpiTree,
    tgc,
    setKpiTree,
    setTgc,
    kpiTreeList,
    tgcList,
    setKpiTreeList,
    setTgcList,
    setKpiSwitch,
    viewMode,
    setViewMode,
    setJapanInvolved,
  } = useContext(TypicalKpiContext);

  const maxListHeight = document.body.clientHeight - 200;
  const [kpiTreeListHeight, setKpiTreeListHeight] = useState<number>(maxListHeight);

  const [exportLoading, setExportLoading] = useState(false);

  const {
    perspectiveOptions,
    setAccountPerspective,
    accountingPerspective,
    filterTgcOptions,
    selectedTgc,
    perspectiveState: { japanInvolved },
  } = useAccountPerspective(tgcList);

  useEffect(() => {
    setJapanInvolved(Boolean(japanInvolved));
  }, [japanInvolved]);

  useEffect(() => {
    if (snapshot && snapshotVersion) {
      setKpiTreeList([
        {
          label: t('aipcmcty.page.projectQuantity'),
          options: [
            {
              label: t('aipcmcty.page.annualGrossProfitExpected'),
              value: '案件の量-年度別粗利額（期待値）',
              key: '案件の量-年度別粗利額（期待値）',
            },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.grossProfitPlanValue')}</span>,
              value: '年度別粗利額（期待値）-粗利計画値',
              key: '年度別粗利額（期待値）-粗利計画値',
            },
            { label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.grossProfitAmt')}</span>, value: '1-2-KPJ004', key: '1-2-KPJ004' },
            { label: <span style={{ marginLeft: 15 }}>Won％</span>, value: '1-3-KPJ014', key: '1-3-KPJ014' },
            { label: t('aipcmcty.page.planAchievementRate'), value: '案件の量-計画達成率', key: '案件の量-計画達成率' },
            { label: t('aipcmcty.page.orderAmount'), value: '1-KPJ002', key: '1-KPJ002' },
            { label: t('aipcmcty.page.sales'), value: '1-KPJ003', key: '1-KPJ003' },
            { label: `${t('aipcmcty.page.newVsExistingRatio')}`, value: '1-KPJ006', key: '1-KPJ006' },
            { label: `${t('aipcmcty.page.nonEPC')}/EPC比率`, value: '1-KPJ005', key: '1-KPJ005' },
          ],
        },
        {
          label: t('aipcmcty.page.projectQuality'),
          options: [
            { label: t('aipcmcty.page.grossProfitAmt'), value: '2-GrossProfitAvg', key: '2-GrossProfitAvg' },
            { label: t('aipcmcty.page.NPVgrossProfitRate'), value: '2-KPJ013', key: '2-KPJ013' },
            { label: 'Won%', value: '2-KPJ014', key: '2-KPJ014' },
            { label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.accuracy')}</span>, value: '案件の質-確度', key: '案件の質-確度' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.stage')}</span>,
              value: '案件の質-ステージ',
              key: '案件の質-ステージ',
            },
            { label: t('aipcmcty.page.grossProfitRate'), value: '2-KPJ011', key: '2-KPJ011' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.grossProfitAmt')}</span>,
              value: '2-3-GrossProfitAvg',
              key: '2-3-GrossProfitAvg',
            },
            { label: t('aipcmcty.page.grossProfitPerMH'), value: '2-KPJ012', key: '2-KPJ012' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.grossProfitAmt')}</span>,
              value: '2-4-GrossProfitAvg',
              key: '2-4-GrossProfitAvg',
            },
            { label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.totalManHours')}</span>, value: '2-TotalMH', key: '2-TotalMH' },
            { label: t('aipcmcty.page.strategicProjectRatio'), value: '案件の質-戦略案件比率', key: '案件の質-戦略案件比率' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.strategicProjectQuantity')}</span>,
              value: '案件の質-戦略案件数',
              key: '案件の質-戦略案件数',
            },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.importantClientRelationshipProjectQuantity')}</span>,
              value: '案件の質-顧客関係重要案件数',
              key: '案件の質-顧客関係重要案件数',
            },
          ],
        },
        {
          label: t('aipcmcty.page.resourceQuantity'),
          options: [
            { label: t('aipcmcty.page.ESMHutilizationRate'), value: '3-KRS002', key: '3-KRS002' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.resourceDemandMH')}</span>,
              value: '3-ResourceDemandMh',
              key: '3-ResourceDemandMh',
            },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.resourceSupplyMH')}</span>,
              value: '3-ResourceSupplyMh',
              key: '3-ResourceSupplyMh',
            },
            { label: t('aipcmcty.page.assumedProductivity'), value: 'リソースの量-想定生産性', key: 'リソースの量-想定生産性' },
            { label: t('aipcmcty.page.demandSupplyGapMH'), value: '3-KRS003', key: '3-KRS003' },
          ],
        },
        {
          label: t('aipcmcty.page.resourceQuality'),
          options: [
            { label: t('aipcmcty.page.jobRate'), value: '4-KRS004', key: '4-KRS004' },
            { label: t('aipcmcty.page.talentTurnoverRate'), value: '4-KRS005', key: '4-KRS005' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.grossProfitPerMH')}</span>,
              value: '4-1-KPJ012',
              key: '4-1-KPJ012',
            },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.manHoursUnitPrice')}</span>,
              value: 'リソースの質-MHあたり工数単価',
              key: 'リソースの質-MHあたり工数単価',
            },
          ],
        },
        {
          label: t('aipcmcty.page.DXapplicationQuantity'),
          options: [{ label: t('aipcmcty.page.DXapplicationRatio'), value: '5-KDX002', key: '5-KDX002' }],
        },
        {
          label: t('aipcmcty.page.dxApplicableQuality'),
          options: [
            { label: t('aipcmcty.page.grossProfitPerMH'), value: '6-KPJ012', key: '6-KPJ012' },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.DXallowanceFullPotentialAmount')}</span>,
              value: '6-1-KDX003',
              key: '6-1-KDX003',
            },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.DXallowanceDiscountRate')}</span>,
              value: '6-2-KDX004',
              key: '6-2-KDX004',
            },
            {
              label: <span style={{ marginLeft: 15 }}>{t('aipcmcty.page.DXallowanceAccumulatedAmount')}</span>,
              value: '6-3-KDX005',
              key: '6-3-KDX005',
            },
          ],
        },
      ]);
      APIList.getCmcOptions()
        .get({ category: 'tgc', snapshot, snapshotVersion })
        .then((data) => {
          setTgcList(data);
        });
    }
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    const itemHeight = 32;
    let count = 0;
    for (let index = 0; index < kpiTreeList.length; index++) {
      const element = kpiTreeList[index];
      count += element.options.length + 1;
    }
    const listHeight = count * itemHeight;
    setKpiTreeListHeight(listHeight > maxListHeight ? maxListHeight : listHeight);
  }, [kpiTreeList]);

  useEffect(() => {
    const kpiTrees = [];
    kpiTreeList.forEach((item) => {
      item.options.forEach((node) => {
        kpiTrees.push(node.value);
      });
    });
    setKpiTree(kpiTrees);
  }, [kpiTreeList, periodList]);

  useEffect(() => {
    setTgc(selectedTgc);
  }, [selectedTgc]);

  const exportTypicalKpis = () => {
    setExportLoading(true);
    APIList.exportTypicalKpis()
      .get({
        snapshot,
        snapshotVersion,
        year: 'FY24',
      })
      .then((data) => {
        // Toyo-Japan(単体)
        const toyoJ = data['Toyo-J'];
        // CN本部
        const cnHeadquarters = data['CN本部'];
        // 海外営業
        const overseasSales = data['海外'];
        // 国内営業
        const domesticSales = data['国内'];
        // OFS本部単体
        const ofsHeadquarters = data['OFS本部'];
        // Toyo-India
        const toyoI = data['Toyo-I'];
        // Toyo-Korea
        const toyoK = data['Toyo-K'];
        // Toyo-Malaysia
        const toyoM = data['Toyo-M'];
        // Toyo-China
        const toyoC = data['Toyo-C'];
        // IKPT
        const ikpt = data['IKPT'];
        // TPS
        const tps = data['TPS'];
        // TSPI
        const tspi = data['TSPI'];
        // OFS
        const ofs = data['OFS'];

        exportTableFromExcelTemp({
          fileName: `typical_kpi_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`,
          templateFilePath: '/assets/typical_kpi_export_template.xlsx',
          cellData: [
            {
              sheet: 'Sheet1',
              data: {
                E4: toyoJ?.orderAmount,
                D4: toyoJ?.orderAmountGoal,
                H4: toyoJ?.orderGrossProfit,
                G4: toyoJ?.orderGrossProfitGoal,
                E6: cnHeadquarters?.orderAmount,
                D6: cnHeadquarters?.orderAmountGoal,
                H6: cnHeadquarters?.orderGrossProfit,
                G6: cnHeadquarters?.orderGrossProfitGoal,
                E7: overseasSales?.orderAmount,
                D7: overseasSales?.orderAmountGoal,
                H7: overseasSales?.orderGrossProfit,
                G7: overseasSales?.orderGrossProfitGoal,
                E8: domesticSales?.orderAmount,
                D8: domesticSales?.orderAmountGoal,
                H8: domesticSales?.orderGrossProfit,
                G8: domesticSales?.orderGrossProfitGoal,
                E9: ofsHeadquarters?.orderAmount,
                D9: ofsHeadquarters?.orderAmountGoal,
                H9: ofsHeadquarters?.orderGrossProfit,
                G9: ofsHeadquarters?.orderGrossProfitGoal,
                E10: toyoI?.orderAmount,
                D10: toyoI?.orderAmountGoal,
                H10: toyoI?.orderGrossProfit,
                G10: toyoI?.orderGrossProfitGoal,
                E11: toyoK?.orderAmount,
                D11: toyoK?.orderAmountGoal,
                H11: toyoK?.orderGrossProfit,
                G11: toyoK?.orderGrossProfitGoal,
                E12: toyoM?.orderAmount,
                D12: toyoM?.orderAmountGoal,
                H12: toyoM?.orderGrossProfit,
                G12: toyoM?.orderGrossProfitGoal,
                E13: toyoC?.orderAmount,
                D13: toyoC?.orderAmountGoal,
                H13: toyoC?.orderGrossProfit,
                G13: toyoC?.orderGrossProfitGoal,
                E14: ikpt?.orderAmount,
                D14: ikpt?.orderAmountGoal,
                H14: ikpt?.orderGrossProfit,
                G14: ikpt?.orderGrossProfitGoal,
                E15: tps?.orderAmount,
                D15: tps?.orderAmountGoal,
                H15: tps?.orderGrossProfit,
                G15: tps?.orderGrossProfitGoal,
                E16: tspi?.orderAmount,
                D16: tspi?.orderAmountGoal,
                H16: tspi?.orderGrossProfit,
                G16: tspi?.orderGrossProfitGoal,
                E17: ofs?.orderAmount,
                D17: ofs?.orderAmountGoal,
                H17: ofs?.orderGrossProfit,
                G17: ofs?.orderGrossProfitGoal,
              },
            },
          ],
        });
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  return (
    <>
      <div className="select-group-container flex-container">
        <div
          style={{
            display: 'flex',
            padding: '8px 0',
            justifyContent: 'space-between',
            width: 'calc(100% - 280px)',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <label>{t('aipcmcty.page.comparison.accountingPerspective')}: </label>
              <div style={{ marginLeft: 8 }}>
                <Select
                  value={accountingPerspective}
                  className="select-height-32"
                  style={{ width: 220 }}
                  onChange={(e) => setAccountPerspective(e)}
                  options={perspectiveOptions}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <label>TGC: </label>
              <div style={{ marginLeft: 8 }}>
                <Select
                  mode="multiple"
                  value={tgc}
                  className="select-height-32"
                  style={{ width: 220 }}
                  maxTagCount={1}
                  maxTagTextLength={6}
                  onChange={(value) => {
                    setTgc(value);
                  }}
                  options={filterTgcOptions}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <Switch
                defaultChecked
                checkedChildren={t('aipcmcty.page.allKPI')}
                unCheckedChildren={t('aipcmcty.page.representativeKPI')}
                checked={kpiSwitch}
                style={{ width: 80, background: color.primaryColor, margin: '0 8px' }}
                onChange={(value) => {
                  setKpiSwitch(value);
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <label>{`${t('aipcmcty.page.KPITree')}: `}</label>
              <div style={{ marginLeft: 8 }}>
                <Select
                  mode="multiple"
                  value={kpiTree}
                  className="select-height-32"
                  style={{ width: 220 }}
                  maxTagCount={1}
                  maxTagTextLength={6}
                  onChange={(value) => {
                    setKpiTree(value);
                  }}
                  options={kpiTreeList}
                  listHeight={kpiTreeListHeight}
                />
              </div>
            </div>
          </div>
        </div>
        <LayoutSwitcher viewMode={viewMode} setViewMode={setViewMode} />
        <Button
          loading={exportLoading}
          type="primary"
          onClick={() => {
            exportTypicalKpis();
          }}
          style={{
            marginRight: 12,
          }}
        >
          エクスポート
        </Button>
      </div>
      {React.Children.map(children, (child: React.ReactElement) => React.cloneElement(child, { viewMode }))}
    </>
  );
};

export default TypicalKpiHeader;
