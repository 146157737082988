import { useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep, every, groupBy, meanBy, sumBy, uniqBy } from 'lodash';
import { Spin } from 'antd';
import { AipcmctyContext } from '../aipcmcty.context';
import { AppContext } from '../AppContext';
import { useLocation } from 'react-router-dom';
import APIList from '../../http/ApiList';
import { round } from '../../utils/commonUtil';

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export type RecordItem = {
  id?: string;
  actionId?: string;
  projectId?: string;
  projectUuid?: string;
  snapshot?: string;
  snapshotVersion?: string;
  field?: string;
  type?: 'CHANGE' | 'RESET' | 'FORMATION';
  before?: any;
  after?: any;
  createdBy?: string;
  updatedAt?: string;
  canOperate?: boolean;
  hasEffimate?: boolean;
};

const AipcmctyContextProvider = ({ children }) => {
  const location = useLocation();
  const {
    setSizeMode,
    certainty,
    aipcmctyVersion,
    cmcSelectedVersion,
    versionList,
    authInfo: { user },
  } = useContext(AppContext);

  // snapshot
  const { snapshot: snapshotL, snapshotVersion: snapshotVersionL } = JSON.parse(aipcmctyVersion);
  const { userId: selectedUserId, snapshot: snapshotS, snapshotVersion: snapshotVersionS, isShared } = cmcSelectedVersion;
  const sameUserVersion = selectedUserId === user?.attributes?.email;
  const [snapshot, snapshotVersion, comment] = useMemo(() => {
    const s = snapshotL === '' && versionList.length ? snapshotS : snapshotL;
    const sv = snapshotVersionL === '' && versionList.length ? snapshotVersionS : snapshotVersionL;
    const cm = versionList.find((item) => item.snapshot === s && item.snapshotVersion === sv)?.comment;
    return [s, sv, cm];
  }, [aipcmctyVersion, versionList.length]);
  const tableCanOperation =
    Object.keys(groupBy(versionList, 'snapshot')).sort((a, b) => {
      if (b > a) {
        return 1;
      }
      if (b < a) {
        return -1;
      }
      return 0;
    })[0] === snapshot &&
    !isShared &&
    sameUserVersion;

  // 共通ChartSize
  const [sizeMapBase] = useState<any>({
    small: {
      title: 18,
      legend: 10,
      yAxis: 12,
      xAxis: 12,
      series: 12,
      gridTop: 40,
      gridLeft: 40,
      gridRight: 10,
      gridBottom: 40,
    },
    big: {
      title: 24,
      legend: 20,
      yAxis: 20,
      xAxis: 20,
      series: 20,
      gridTop: 75,
      gridLeft: 60,
      gridRight: 50,
      gridBottom: 40,
    },
  });
  // KPI
  const [orgKpiInfos, setOrgKpiInfos] = useState<any>({});
  const [kpiInfos, setKpiInfos] = useState<any>({});

  const [kpiComputeRule, setKpiComputeRule] = useState<any>();

  // β版Viz paramter
  const [paramData, setParamData] = useState<any>({
    param1: {
      progressRate: 1,
      accumulateRate: 0.6,
      cuteEate: 1,
    },
    param2: {
      progressRate: 1,
      accumulateRate: 0.6,
      cuteEate: 1,
    },
    param3: {
      progressRate: 1,
      accumulateRate: 0.6,
      cuteEate: 1,
    },
  });

  const isArrayOfString = (arr) => every(arr, (item) => typeof item === 'string');

  const initKpiData = async (
    kpiCodes: any[],
    consolidated,
    { isDXMode = false, division = '', discipline = '', partial = false } = {
      isDXMode: false,
      division: '',
      discipline: '',
      partial: false,
    }
  ) => {
    if (partial) {
      setOrgKpiInfos((prev) => {
        const p = { ...prev };
        kpiCodes.forEach((kpi) => {
          p[kpi] = { loading: true };
        });
        return p;
      });
    } else {
      setOrgKpiInfos((prev) => ({}));
    }
    let kpisData: any = {};
    if (isArrayOfString(kpiCodes)) {
      const response = await APIList.getCmcKpis().post({
        kpis: kpiCodes,
        consolidated,
        snapshot,
        snapshotVersion,
      });
      kpiCodes.forEach((kpi, index) => {
        kpisData[kpi] = {
          data: response[index],
          loading: false,
        };
      });
      setOrgKpiInfos((prev) => ({ ...prev, ...kpisData }));
    } else {
      await kpiCodes.forEach(async (kpiCodeArr) => {
        if (kpiCodeArr.includes('KOT001')) {
          const data = await APIList.getCmcDsKpis().post({
            kpis: ['KOT001'],
            snapshot,
            snapshotVersion,
          });
          kpisData.KOT001 = { data: data[0], loading: false };
        }
        if (kpiCodeArr.includes('KOT002')) {
          const data = await APIList.getCmcDsKpis().post({
            kpis: ['KOT002'],
            snapshot,
            snapshotVersion,
          });
          kpisData.KOT002 = { data: data[0], loading: false };
        }
        if (kpiCodeArr.includes('KOT003')) {
          const data = await APIList.getCmcDsKpis().post({
            kpis: ['KOT003'],
            snapshot,
            snapshotVersion,
          });
          kpisData.KOT003 = { data: data[0], loading: false };
        }
        if (kpiCodeArr.includes('KOT004')) {
          const data = await APIList.getCmcDsKpis().post({
            kpis: ['KOT004'],
            snapshot,
            snapshotVersion,
          });
          kpisData.KOT004 = { data: data[0], loading: false };
        }
        if (kpiCodeArr.includes('GrossProfitResource')) {
          const data = await APIList.getCmcDsKpis().post({
            kpis: ['GrossProfitResource'],
            consolidated,
            snapshot,
            snapshotVersion,
            isDXMode,
            division,
            discipline,
          });
          kpisData.GrossProfitResource = { data: data[0], loading: false };
        }
        const filteredKpis = kpiCodeArr.filter(
          (code) => !(code === 'KOT001' || code === 'KOT002' || code === 'KOT003' || code === 'KOT004' || code === 'GrossProfitResource')
        );
        if (filteredKpis.length > 0) {
          const response = await APIList.getCmcKpis().post({
            kpis: filteredKpis,
            consolidated,
            snapshot,
            snapshotVersion,
            isDXMode,
            division,
            discipline,
          });
          if (partial) {
            const oldInfos = cloneDeep(orgKpiInfos);
            filteredKpis.forEach((kpi, index) => {
              oldInfos[kpi] = {
                data: response[index],
                loading: false,
              };
              kpisData = oldInfos;
            });
          } else {
            filteredKpis.forEach((kpi, index) => {
              kpisData[kpi] = {
                data: response[index],
                loading: false,
              };
            });
          }
        }
        setOrgKpiInfos((prev) => ({ ...prev, ...kpisData }));
      });
    }
  };

  useEffect(() => {
    setKpiComputeRule({
      // 連結売上高
      KMT001: (data) => round(sumBy(data, certainty ? 'salesWon' : 'sales') || 0),
      // 連結粗利
      KMT002: (data) => round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') || 0),
      // 連結純利益
      KMT003: (data) => round(sumBy(data, certainty ? 'netProfitWon' : 'netProfit') || 0),
      // 粗利構成比(EPC/非EPC)
      KMT101: (data, all) =>
        round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit')),
      // 粗利構成比(新規事業/既存事業)
      KMT102: (data, all) =>
        round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit')),
      // 粗利構成比(Japan/その他)
      KMT103: (data, all) =>
        round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit')),
      // 戦略マトリックス(案件)
      KPJ001: () => ({}),
      // 受注金額
      KPJ002: (data) => round(sumBy(data, certainty ? 'orderAmountWon' : 'orderAmount') || 0),
      // 受注粗利
      KPJ020: (data) => round(sumBy(data, certainty ? 'orderGrossProfitWon' : 'orderGrossProfit') || 0),
      // 売上
      KPJ003: (data) => round(sumBy(data, certainty ? 'salesWon' : 'sales') || 0),
      // 粗利額
      KPJ004: (data) => round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') || 0),
      // 粗利構成比(非EPC/EPC)
      KPJ005: (data, all) =>
        sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit'),
      // 粗利構成比(新規/既存)
      KPJ006: (data, all) =>
        sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit'),
      // 粗利構成比(Japan/その他)
      KPJ007: (data, all) =>
        sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit'),
      // 粗利構成比(協業/独自/JV等)
      KPJ008: (data, all) =>
        sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit'),
      // 粗利構成比(建設地)
      KPJ009: (data, all) =>
        sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit'),
      // 粗利構成比(商品)
      KPJ010: (data, all) =>
        sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(all, certainty ? 'grossProfitWon' : 'grossProfit'),
      // 粗利額・粗利率
      KPJ011: {
        grossProfit: (data) => round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') || 0),
        grossProfitRate: (data) =>
          sumBy(data, certainty ? 'salesWon' : 'sales')
            ? sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(data, certainty ? 'salesWon' : 'sales')
            : 0,
      },
      // MHあたり粗利
      KPJ012: (data) =>
        round(
          sumBy(data, certainty ? 'mhWon' : 'mh')
            ? sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(data, certainty ? 'mhWon' : 'mh')
            : 0
        ),
      // NPV粗利率
      KPJ013: {
        grossProfit: (data) => round(sumBy(data, certainty ? 'orderAmountGrossMarginWon' : 'orderAmountGrossMargin')),
        npv: (data) => round(sumBy(data, certainty ? 'orderAmountNpvWon' : 'orderAmountNpv')),
        npvRate: (data) =>
          round(
            sumBy(data, certainty ? 'projectOrderAmountWon' : 'projectOrderAmount')
              ? sumBy(data, certainty ? 'orderAmountNpvWon' : 'orderAmountNpv') /
                  sumBy(data, certainty ? 'projectOrderAmountWon' : 'projectOrderAmount')
              : 0
          ),
      },
      // Won％
      KPJ014: {
        won: (data) => round(sumBy(data, 'won')),
        orderAmount: (data) => round(sumBy(data, certainty ? 'projectOrderAmountWon' : 'projectOrderAmount')),
        wonRate: (data) =>
          sumBy(data, 'projectOrderAmount') ? sumBy(data, 'projectOrderAmountWon') / sumBy(data, 'projectOrderAmount') : 0,
      },
      // 戦略案件比率
      KPJ015: () => ({}),
      // リスクマップ
      KPJ016: () => ({}),
      // 戦略マトリックス(リソース)
      KRS001: () => ({}),
      // ESMH稼働率
      KRS002: (data) => (sumBy(data, 'resourceSupplyMh') ? sumBy(data, 'resourceDemandMh') / sumBy(data, 'resourceSupplyMh') : 0),
      // 需給過不足MH
      KRS003: (data) => round(sumBy(data, 'imbalancedMh') || 0),
      // Job率
      KRS004: (date) => {
        const mhData = sumBy(date, 'resourceSupplyMh');
        const rate = sumBy(date, 'mhJobRate');
        return rate ? mhData / rate : 0;
      },
      // 人財回転率
      KRS005: (hr, grossProfit) =>
        sumBy(hr, 'hr') ? sumBy(grossProfit, certainty ? 'grossProfitWon' : 'grossProfit') / sumBy(hr, 'hr') : 0,
      // 戦略マトリックス(DX適用)
      KDX001: () => ({}),
      // DX適用比率
      KDX002: (data) => {
        if (!data) {
          return 0;
        }
        return (
          uniqBy(
            data.filter((item) => item.dxApplicable),
            'projectId'
          ).length / uniqBy(data, 'projectId').length
        );
      },
      // DX Allowanceフルポテ
      KDX003: (data) => round(sumBy(data, certainty ? 'dxAlloFullPoteWon' : 'dxAlloFullPote') || 0),
      // DX Allowance割引率
      KDX004: (data) => {
        const rate = sumBy(data, certainty ? 'dxAlloFullPoteWon' : 'dxAlloFullPote')
          ? sumBy(data, certainty ? 'dxAlloDiscountedWon' : 'dxAlloDiscounted') /
            sumBy(data, certainty ? 'dxAlloFullPoteWon' : 'dxAlloFullPote')
          : 0;
        if (!rate) {
          return null;
        }
        return 1 - rate;
      },
      // DX Allowance積上額
      KDX005: (data) => round(sumBy(data, certainty ? 'dxAlloDiscountedWon' : 'dxAlloDiscounted') || 0),
      // 需給マッチング(Japan)
      KOT001: (data) => round(sumBy(data, 'demandMh')),
      // 需給マッチング(India)
      KOT002: (data) => round(sumBy(data, 'demandMh')),
      // 需給マッチング(Sum)
      KOT003: (data) => round(sumBy(data, 'demandMh')),
      // 需給マッチング(Total)
      KOT004: (data) => round(sumBy(data, 'demandMh')),
      // 総工数(MH)
      TotalMH: (data) => round(sumBy(data, certainty ? 'mhWon' : 'mh') || 0),
      // リソース需要MH
      ResourceDemandMh: (data) => round(sumBy(data, 'resourceDemandMh') || 0),
      // リソース供給MH
      ResourceSupplyMh: (data) => round(sumBy(data, 'resourceSupplyMh') || 0),
      // 粗利額（案件の質）
      GrossProfitAvg: (data) => round(meanBy(data, certainty ? 'grossProfitWonAvg' : 'grossProfitAvg')) || 0,
      // 1人あたり営業利益額
      OperatingIncomePerCapita: {
        mart: (data) => round(sumBy(data, 'mh') ? sumBy(data, 'operatingProfit') / (sumBy(data, 'mh') / 1920) : 0),
        goal: (data) => sumBy(data, 'operatingProfitPerMp'),
      },
      // 年度別マッチング率
      MatchingRateByYear: {
        resourceSupplyMh: (data) => sumBy(data, 'resourceSupplyMh'),
        imbalancedMh: (data) => sumBy(data, 'imbalancedMh'),
        rate: (data) => (sumBy(data, 'resourceDemandMh') ? sumBy(data, 'resourceSupplyMh') / sumBy(data, 'resourceDemandMh') : 0),
      },
      OrderGrossProfitResource: (data, isAmount) => round(sumBy(
        data,
        !isAmount
          ? certainty ? 'orderGrossProfitWon' : 'orderGrossProfit'
          : 'orderAmount'
      ) || 0),
      GrossProfitResource: (data) => round(sumBy(data, certainty ? 'grossProfitWon' : 'grossProfit') || 0),
    });
  }, [certainty]);

  useEffect(() => {
    filterKpiData({});
  }, [orgKpiInfos]);

  const filterKpiData = ({ tgc, japanInvolved, consolidated, tgcCanEmpty = false, departments = null }: any) => {
    const propertyWhiteList = [];
    const japanInvolvedWhileList = [
      // resource
      'KRS002',
      'KRS003',
      'KRS004',
      'KRS005',
      // project
      'KDX002',
      'KPJ001',
      'KPJ013',
      'KPJ014',
      'KRS001',
      'KDX001',
      'KDX002',
      'KOT001',
      'KOT002',
      'ResourceSimulation',
      'ResourceSimulationSub',
    ];
    const tgcWhiteList = ['KOT001', 'KOT002', 'KOT003', 'KMT003', 'ResourceSimulation', 'ResourceSimulationSub'];
    const deepInfos = cloneDeep(orgKpiInfos);
    Object.keys(deepInfos).forEach((kpiCode) => {
      if (deepInfos[kpiCode].data) {
        Object.keys(deepInfos[kpiCode].data).forEach((key) => {
          if (!propertyWhiteList.includes(key) && deepInfos[kpiCode].data[key] && deepInfos[kpiCode].data[key].length > 0) {
            deepInfos[kpiCode].data[key] = deepInfos[kpiCode].data[key].filter((kpiInfo) => {
              // プロパティーを絞り込む
              const resultFlag = [];
              // 追加需求
              customBusiness({
                kpiInfo,
                key,
                resultFlag,
                japanInvolved,
                consolidated,
                tgc,
                tgcWhiteList,
                kpiCode,
                departments,
              });
              if (!(consolidated && key === 'goal')) {
                if (tgc && !tgcWhiteList.includes(kpiCode)) {
                  resultFlag.push(tgcCanEmpty ? !tgc.length || tgc.includes(kpiInfo.tgc) : tgc.includes(kpiInfo.tgc));
                }
              }
              if (key !== 'goal' && japanInvolved && !japanInvolvedWhileList.includes(kpiCode)) {
                resultFlag.push(kpiInfo.japanInvolved === 'Toyo-J関与');
              }
              return resultFlag && resultFlag.length > 0 ? resultFlag.every((flag) => flag) : true;
            });
          }
        });
      }
    });
    setKpiInfos(deepInfos);
  };

  const customBusiness = ({ kpiInfo, key, resultFlag, japanInvolved, consolidated, tgc, tgcWhiteList, kpiCode, departments }) => {
    // goalについて、会計観点によると、tgcを絞り込む
    if (key === 'goal') {
      if (departments && departments.kpiCode === kpiCode && departments.items.length) {
        resultFlag.push(departments.items.includes(kpiInfo.tgc));
      } else {
        // 純利益
        if (kpiCode === 'KMT003') {
          resultFlag.push(['ALL'].includes(kpiInfo.tgc));
        } else if (!tgcWhiteList.includes(kpiCode)) {
          // 連結＋持分 / 連結 TGC = ALL
          if (!japanInvolved && consolidated) {
            resultFlag.push(['ALL'].includes(kpiInfo.tgc));
          }
          // Toyo-J連結 TGC = Toyo-J連結
          if (japanInvolved && consolidated) {
            resultFlag.push(['Toyo-J連結'].includes(kpiInfo.tgc));
          }

          if (!japanInvolved && !consolidated) {
            // 拠点単体 TGC
            if (tgc) {
              resultFlag.push(tgc.includes(kpiInfo.tgc));
            } else {
              resultFlag.push(['ALL'].includes(kpiInfo.tgc));
            }
          }
        }
      }
    }
  };

  const setKpiLoadingStatus = (status) => {
    const newKpiInfos = cloneDeep(kpiInfos);
    Object.keys(newKpiInfos).forEach((k) => {
      newKpiInfos[k] = {
        ...newKpiInfos[k],
        loading: status,
      };
    });
    setKpiInfos(newKpiInfos);
  };

  const getKpiData = (kpiCode: string) => kpiInfos[kpiCode] || { loading: true, titleLoading: true };
  const getOrgKpiData = (kpiCode: string) => orgKpiInfos[kpiCode] || { loading: true, titleLoading: true };
  const removeOrgKpiData = () => setOrgKpiInfos(prev => ({}));

  useEffect(() => {
    setSizeMode('small');
  }, [location.pathname]);

  // compareVersion
  const [compareVersion, setCompareVersion] = useState({
    snapshot: null,
    snapshotVersion: null,
  });

  return (
    <AipcmctyContext.Provider
      value={{
        sizeMapBase,
        kpiInfos,
        getKpiData,
        getOrgKpiData,
        removeOrgKpiData,
        initKpiData,
        filterKpiData,
        kpiComputeRule,
        snapshot,
        snapshotVersion,
        comment,
        tableCanOperation,
        setKpiLoadingStatus,
        orgKpiInfos,
        setOrgKpiInfos,
        versionList,
        compareVersion,
        setCompareVersion,
        user: user?.attributes?.email,
        paramData,
        setParamData,
      }}
    >
      {versionList.length ? (
        children
      ) : (
        <div
          style={{
            width: '100%',
            height: '90vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      )}
    </AipcmctyContext.Provider>
  );
};

export default AipcmctyContextProvider;
