/* eslint-disable no-template-curly-in-string */
import {
  FileExclamationOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Popover,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { AppContext } from '../../contexts/AppContext';
import { round } from '../../utils/commonUtil';

export type ProjectAttrsType = {
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
};

export type ProjectKPIType = {
  key: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: string;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  won: number;
};

/* @noflow */
export interface ResourceRegulationTableItem {
  key: string;
  // 案件 UUID
  id: string;
  // 案件ID
  projectId: string;
  // 案件名
  projectName: string;
  // TGC
  tgc: string;
  // 予算カテゴリ
  budgetCategory: string;
  budgetCategoryDefault: string;
  budgetCategoryCustom: string;
  // 最優先
  priorityMatching: boolean;
  // 優先度
  priorityDefault: number;
  // 受注金額
  orderAmount: number;
  // 受注金額_default
  orderAmountDefault: number;
  orderAccumulation: number;
  // 粗利率
  grossMargin: number;
  grossMarginDefault: number;
  grossProfit: number;
  grossProfitDefault: number;
  profitAccumulation: number;
  // 需要Mh
  demandMh: number;
  // Won%
  won: number;
  // フェーズ / ステージ
  phase: string;
  phaseDefault: string;
  // 確度
  certainty: string;
  // 開始予定日
  projectStartDate: Date;
  projectStartDateDefault: Date;
  // 終了予定日
  projectEndDate: Date;
  projectEndDateDefault: Date;
  // 期間
  duration: number;
  durationDefault: number;
  // Feed開始予定日
  feedStartDate: Date;
  feedStartDateDefault: Date;
  // Feed終了予定日
  feedEndDate: Date;
  feedEndDateDefault: Date;
  // Feed期間
  feedDuration: number;
  feedDurationDefault: number;
  // DX適用
  dxAvailable: boolean;
  dxApplicable: boolean;
  formJvFeedE: number;
  formJvFeedP: number;
  formJvFeedC: number;
  formJvFeedPjt: number;
  formJvEpcE: number;
  formJvEpcP: number;
  formJvEpcC: number;
  formJvEpcPjt: number;
  formTgcFeedE: string;
  formTgcFeedP: string;
  formTgcFeedC: string;
  formTgcFeedPjt: string;
  formTgcEpcE: string;
  formTgcEpcP: string;
  formTgcEpcC: string;
  formTgcEpcPjt: string;
  // アクション / アラート
  action: string[];
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: number;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  demandForecastType: string;
  constructionLocation: string;
  memoCount: number;
  monthDemandMH: number;
  dxDemandMh: number;
  dxGrossProfit: number;
  orderScheduledDate: Date;
  orderScheduledDateDefault: Date;
  isForPpReport?: boolean;
}

const ResourceRegulationTable: React.FC<{
  loading: boolean;
  data: ResourceRegulationTableItem[];
  viewMode?: string;
  columnFilter?: {
    update: string[];
    query: string[];
    hidden: string[];
  };
  onPageSizeChange: (pageSize: number) => void;
  tableColsToShow: string[];
  pageSize: number;
  needDisable: boolean;
  onSelected: (record: ResourceRegulationTableItem, selected: boolean) => void;
  selectedProjects: any[];
  demandMonth: string;
  selectedDivDis: [string, string];
  onDemandUpdate: () => void;
  isDxMode: boolean;
  consolidated: 0 | 1;
}> = (props) => {
  // Common Config
  const {
    data,
    viewMode,
    loading,
    onPageSizeChange,
    pageSize,
    needDisable,
    onSelected,
    selectedProjects,
    onDemandUpdate,
    isDxMode,
    consolidated,
    tableColsToShow
  } = props;

  const { selectorHeight4Table } = useWindowSize({
    selector: '.project-table .cmcty-table-body',
    viewMode,
    watcherSelector: ['.operation-container'],
  });
  const {
    setIsModalOpen,
    optData,
    setCurModalItem,
    handleTableChange
  } = useContext(ProjectSetupContext);

  const { t } = useTranslation();

  const { certainty } = useContext(AppContext);

  const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

  // Table Config
  const columns: ColumnType<ResourceRegulationTableItem>[] = [
    {
      title: t('aipcmcty.page.priorityLevel'),
      dataIndex: 'priorityDefault',
      key: 'priorityDefault',
      fixed: 'left',
      width: 80,
      className: 'align-right small-column',
    },
    {
      title: t('aipcmcty.page.projectCaseId'),
      dataIndex: 'projectId',
      key: 'projectId',
      width: 80,
      fixed: 'left',
      className: 'align-right small-column',
      sorter: (a, b) => a.projectId.localeCompare(b.projectId),
      render: (val) => {
        const ids = val ? val.split(',') : '-';
        return ids.map((id) => (
          <div key={id} style={{ lineHeight: '16px' }}>
            {id}
          </div>
        ));
      },
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      dataIndex: 'projectName',
      key: 'projectName',
      fixed: 'left',
      width: 240,
      className: 'align-left small-column',
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render(val, item) {
        const attrSource = {
          accountingYear: item.accountingYear ?? '-',
          scope: item.scope ?? '-',
          isExisted: item.isExisted ?? '-',
          isEpc: item.isEpc ?? '-',
          businessType: item.businessType ?? '-',
          goodsType: item.goodsType ?? '-',
          strategic: item.strategic ?? '-',
          customerRelationship: item.customerRelationship ?? '-',
        };
        const grossProfitPerMh = !!Number(item.grossProfitPerMh) && Number(item.grossProfitPerMh) !== 0
          ? Number(item.grossProfitPerMh).toFixed(2)
          : item.grossProfitPerMh;
        const kpiSource = {
          grossProfitPerMh: grossProfitPerMh ? Number(Number(grossProfitPerMh).toFixed(0)).toLocaleString() : '-',
          npvGrossMargin: item.npvGrossMargin ? `${round(item.npvGrossMargin * 100).toFixed(1)}%` : '-',
          totalWorkload: item.totalWorkload ? Number(item.totalWorkload).toLocaleString() : '-',
          expectedProductivity: item.expectedProductivity ? Number(item.totalWorkload).toLocaleString() : '-',
          resourceDemandMh: item.resourceDemandMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          resourceSupplyMh: item.resourceSupplyMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          imbalancedMh: item.imbalancedMh ? Number(round(item.resourceDemandMh)).toLocaleString() : '-',
          dxFullPotential: item.dxFullPotential
            ? certainty === 1 ? Math.round(item.dxFullPotential * item.won) : Number(item.dxFullPotential).toLocaleString()
            : '-',
          dxDiscountRate: item.dxDiscountRate ?? '-',
          dxDiscounted: item.dxDiscounted
            ? certainty === 1 ? Math.round(item.dxDiscounted * item.won) : Number(item.dxDiscounted).toLocaleString()
            : '-',
          won: item.won,
        };
        const contentPop = (
          <div className="project-info-pop">
            <h4 style={{ fontWeight: 'bold' }}>{t('aipcmcty.page.projectAttribute')}</h4>
            <div className="grid-table">
              <div className="table-name">
                <div>{t('aipcmcty.page.fiscalYear')}</div>
                <div>{t('aipcmcty.page.serviceScope')}</div>
                <div>{`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}</div>
                <div>{`${t('aipcmcty.page.nonEPC')}/EPC`}</div>
                <div>{t('aipcmcty.page.businessType')}</div>
                <div>{t('aipcmcty.page.productType')}</div>
                <div>{t('aipcmcty.page.strategic')}</div>
                <div>{t('aipcmcty.page.customerRelationship')}</div>
              </div>
              <div className="table-value">
                <div style={{ maxWidth: 240 }}>{attrSource.accountingYear}</div>
                <Tooltip title={attrSource.scope}><div style={{ maxWidth: 240 }}>{attrSource.scope}</div></Tooltip>
                <div style={{ maxWidth: 240 }}>{attrSource.isExisted}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.isEpc}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.businessType}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.goodsType}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.strategic}</div>
                <div style={{ maxWidth: 240 }}>{attrSource.customerRelationship}</div>
              </div>
            </div>
            <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>{t('aipcmcty.page.projectKpi')}</h4>
            <div className="grid-table" style={{ width: '100%' }}>
              <div className="table-name" style={{ minWidth: 200 }}>
                <div>{t('aipcmcty.page.grossProfitPerMH')}</div>
                <div>{t('aipcmcty.page.NPVgrossProfitRate')}</div>
                <div>{t('aipcmcty.page.totalWorkVolume')}</div>
                <div>{t('aipcmcty.page.assumedProductivity')}</div>
                <div>{t('aipcmcty.page.resourceDemandMH')}</div>
                <div>{t('aipcmcty.page.resourceSupplyMH')}</div>
                <div>{t('aipcmcty.page.demandSupplyGapMH')}</div>
                <div>{t('aipcmcty.page.dxAllowanceFullPot')}</div>
                <div>{t('aipcmcty.page.DXallowanceDiscountRate')}</div>
                <div>{t('aipcmcty.page.dxAllowanceAmount')}</div>
              </div>
              <div className="table-value">
                <div style={{ maxWidth: 240 }}>{kpiSource.grossProfitPerMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.npvGrossMargin}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.totalWorkload}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.expectedProductivity}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.resourceDemandMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.resourceSupplyMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.imbalancedMh}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxFullPotential}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxDiscountRate}</div>
                <div style={{ maxWidth: 240 }}>{kpiSource.dxDiscounted}</div>
              </div>
            </div>
          </div>
        );
        return (
          <Row align="middle" style={{ width: '100%' }} >
            <Col span={22}>
              <Tooltip
                mouseLeaveDelay={0}
                mouseEnterDelay={0.8}
                title={val}
                placement="topLeft"
              >
                <Button
                  className="project-name multi-ellipsis-btn"
                  type="link"
                  onClick={() => {
                    setIsModalOpen(true);
                    setCurModalItem({ ...item });
                  }}
                >
                  {val}
                </Button>
              </Tooltip>
            </Col>
            <Col span={2}>
              <Popover
                content={contentPop}
                trigger="hover"
                placement="right"
              >
                <FileExclamationOutlined />
              </Popover>
            </Col>
          </Row >
        );
      },
    },
    {
      title: t('aipcmcty.page.year'),
      dataIndex: 'accountingYear',
      key: 'accountingYear',
      width: 60,
      className: 'align-left small-column',
      sorter: (a, b) => a.accountingYear.localeCompare(b.accountingYear),
      render: (val) => val ?? '-',
    },
    {
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      width: 90,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aTgc = a.tgc ?? '';
        const bTgc = b.tgc ?? '';
        return aTgc.localeCompare(bTgc);
      },
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.resSimulation.relatedTgc'),
      dataIndex: 'relatedTgc',
      key: 'relatedTgc',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aTgc = a.tgc ?? '';
        const bTgc = b.tgc ?? '';
        return aTgc.localeCompare(bTgc);
      },
      render: (val) => val ?? '-'
    },
    {
      title: t('aipcmcty.page.budgetCategory'),
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom',
      width: 120,
      className: 'small-column',
      sorter: (a, b) => a.budgetCategory.localeCompare(b.budgetCategory),
      render(val, item) {
        const curOrder = optData?.budgetCategory.find(
          (p) => p.value === (val ?? item.budgetCategory)
        )?.order;
        const defaultOrder = optData?.budgetCategory.find(
          (p) => p.value === item.budgetCategoryDefault
        ).order;
        let classNames = 'project-select left';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (curOrder > defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select greater';
        }
        if (curOrder < defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select less';
        }
        const content = (
          <>
            <span>{`SFDC: ${item.budgetCategoryDefault}`}</span>
            <br />
            <span>{`CMC: ${item.budgetCategory}`}</span>
          </>
        );
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={content}>
            <div style={{ textAlign: 'center' }}>{val ?? item.budgetCategory}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.resSimulation.demandForecastType'),
      dataIndex: 'demandForecastType',
      key: 'demandForecastType',
      className: 'align-center small-column',
      width: 120,
      render: (val) =>  <div>{val}</div>
    },
    {
      title: t('aipcmcty.page.resSimulation.stackMH'),
      dataIndex: 'monthDemandMH',
      key: 'monthDemandMH',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => Number(a.monthDemandMH ?? 0) - Number(b.monthDemandMH ?? 0),
      render: (val) => <div>{val ? Number(val).toLocaleString() : val || '-'}</div>
    },
    {
      title: `${t('aipcmcty.page.demand')}MH`,
      dataIndex: 'demandMh',
      key: 'demandMh',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => a.demandMh - b.demandMh,
      render: (val) => <div>{val ? Number(Number(val).toFixed(0)).toLocaleString() : val || '-'}</div>
    },
    {
      title: `DX${t('aipcmcty.page.demand')}MH`,
      dataIndex: 'dxDemandMh',
      key: 'dxDemandMh',
      className: 'align-right small-column',
      width: 120,
      sorter: (a, b) => a.dxDemandMh - b.dxDemandMh,
      render: (val) => <div>{val ? Number(Number(val).toFixed(0)).toLocaleString() : val || '-'}</div>
    },
    {
      title: t('aipcmcty.page.orderAmount'),
      dataIndex: 'orderAmount',
      key: 'orderAmount',
      width: 150,
      className: 'align-right small-column',
      sorter: (a, b) => a.orderAmount - b.orderAmount,
      render(val, item) {
        const toolTip = (
          <>
            <span>
              {`SFDC: ${fixed(
                item.orderAmountDefault
              ).toLocaleString()}`}
            </span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={toolTip}
            trigger="hover"
            placement="top"
          >
            <div>{fixed(val).toLocaleString()}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 130,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossProfit - b.grossProfit,
      render: (val, item) => {
        let classNames = 'project-number';
        const toolTip = (
          <>
            <span>
              {`SFDC: ${fixed(
                item.grossProfitDefault
              ).toLocaleString()}`}
            </span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={toolTip}
            trigger="hover"
            placement="top"
          >
            <span className={classNames}>{fixed(val).toLocaleString()}</span>
          </Tooltip>
        );
      },
    },
    {
      title: `DX${t('aipcmcty.page.grossProfitAmt')}`,
      dataIndex: 'dxGrossProfit',
      key: 'dxGrossProfit',
      width: 130,
      className: 'align-right small-column',
      sorter: (a, b) => a.dxGrossProfit - b.dxGrossProfit,
      render: (val) => fixed(val).toLocaleString() ?? '-'
    },
    {
      title: t('aipcmcty.page.grossProfitRate'),
      dataIndex: 'grossMargin',
      key: 'grossMargin',
      width: 90,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossMargin - b.grossMargin,
      render(val, item) {
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.grossMarginDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.grossMarginDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${round(item.grossMarginDefault * 100).toFixed(1)}%`}
          >
            <div>
              <span>{`${round(val * 100).toFixed(1)}%`}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: `${t('aipcmcty.page.grossProfit')}/MH`,
      dataIndex: 'grossProfitPerMh',
      key: 'grossProfitPerMh',
      width: 90,
      className: 'align-right small-column',
      sorter: (a, b) => a.grossProfitPerMh - b.grossProfitPerMh,
      render: (val) => (val ? fixed(val).toLocaleString() : val || '-'),
    },
    {
      title: t('aipcmcty.page.startDate'),
      dataIndex: 'projectStartDate',
      key: 'projectStartDate',
      width: 130,
      className: 'align-right small-column',
      sorter: (a, b) => {
        if (moment(a.projectStartDate).isBefore(b.projectStartDate)) {
          return -1;
        }
        if (moment(a.projectStartDate).isAfter(b.projectStartDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const curMoment = moment(val);
        const defaultMoment = moment(item.projectStartDateDefault);
        let classNames = '';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (curMoment.isBefore(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date less';
        }
        if (curMoment.isAfter(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date greater';
        }
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}
          >
            <div style={{ textAlign: 'center' }}>{curMoment.format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.endDate'),
      dataIndex: 'projectEndDate',
      key: 'projectEndDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (moment(a.projectEndDate).isBefore(b.projectEndDate)) {
          return -1;
        }
        if (moment(a.projectEndDate).isAfter(b.projectEndDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const defaultMoment = moment(item.projectEndDateDefault);
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}
          >
            <div style={{ textAlign: 'center' }}>{moment(val).format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.resSimulation.orderScheduledDate'),
      dataIndex: 'orderScheduledDate',
      key: 'orderScheduledDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (moment(a.orderScheduledDate).isBefore(b.orderScheduledDate)) {
          return -1;
        }
        if (moment(a.orderScheduledDate).isAfter(b.orderScheduledDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const defaultMoment = moment(item.orderScheduledDateDefault);
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}
          >
            <div style={{ textAlign: 'center' }}>{moment(val).format('YYYY-MM-DD')}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.duration'),
      dataIndex: 'duration',
      key: 'duration',
      width: 70,
      className: 'align-right small-column',
      sorter: (a, b) => a.duration - b.duration,
      render(val, item) {
        let classNames = 'project-number';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (val < item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }

        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${item.durationDefault}`}
          >
            <div>{val}</div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Won%',
      dataIndex: 'won',
      key: 'won',
      width: 80,
      className: 'align-right small-column',
      sorter: (a, b) => a.won - b.won,
      render: (val) => `${round(val * 100)}%`,
    },
    {
      title: t('aipcmcty.page.phase'),
      dataIndex: 'phase',
      key: 'phase',
      width: 150,
      className: 'small-column',
      sorter: (a, b) => a.phase.localeCompare(b.phase),
      render(val, item) {
        const curOrder = optData?.phase.find((p) => p.value === val)?.order;
        const defaultOrder = optData?.phase.find(
          (p) => p.value === item.phaseDefault
        )?.order;
        let classNames = 'project-select left';
        if (!needDisable) {
          classNames += ' table-item-disable';
        }
        if (curOrder > defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select greater';
        }
        if (curOrder < defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select less';
        }

        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${item.phaseDefault}`}
          >
            <div style={{ textAlign: 'center' }}>{val}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.accuracy'),
      dataIndex: 'certainty',
      key: 'certainty',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => a.phase.localeCompare(b.certainty),
      render: (val) => (
        <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val}>
          <div
            className="multi-ellipsis-text"
          >
            {val || '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: `${t('aipcmcty.page.constructionSite')}`,
      dataIndex: 'constructionLocation',
      key: 'constructionLocation',
      width: 90,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aLoc = a.constructionLocation ?? '';
        const bLoc = b.constructionLocation ?? '';
        return aLoc.localeCompare(bLoc);
      },
      render: (val) => (
        <Tooltip
          mouseLeaveDelay={0}
          mouseEnterDelay={0.8}
          title={val ?? '-'}
          placement="topLeft"
        >
          <div
            className="multi-ellipsis-text"
          >
            {val ?? '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('aipcmcty.page.accumulatedOrderAmount'),
      dataIndex: 'orderAccumulation',
      key: 'orderAccumulation',
      fixed: 'right',
      width: 150,
      className: 'align-right small-column',
      render: (val) => fixed(val).toLocaleString(),
    },
    {
      title: t('aipcmcty.page.accumulatedGrossProfit'),
      dataIndex: 'profitAccumulation',
      key: 'profitAccumulation',
      fixed: 'right',
      width: 130,
      className: 'align-right small-column',
      render: (val) => fixed(val).toLocaleString()
    },
    {
      title: t('aipcmcty.page.dxApplicable'),
      dataIndex: 'dxApplicable',
      key: 'dxApplicable',
      fixed: 'right',
      width: 80,
      className: 'small-column',
      sorter: (a, b) => Number(a.dxApplicable) - Number(b.dxApplicable),
      render: (val, item) => (
        <Switch
          // disabled={!needDisable || !isDxMode || !item.dxAvailable || item.budgetCategoryCustom.endsWith('Awarded')}
          disabled={!isDxMode || !item.dxAvailable || item.budgetCategoryCustom.endsWith('Awarded')}
          checked={val}
          onChange={e => handleTableChange('dxApplicable', e, item.id)}
        />
      )
    },
  ];

  /**
   * Table高度Map
   */
  const tableHeightMap = {
    'chart-table': selectorHeight4Table - 40,
    'table-only': selectorHeight4Table,
    'chart-only': 0,
  };

  const colsFixedLeft = columns.filter(c => c.fixed === 'left');
  const colsFixedRight = columns.filter(c => c.fixed === 'right');
  const colsShow = columns.filter(c => tableColsToShow.includes(c.key as string));
  const colsCombine = [...colsFixedLeft, ...colsShow, ...colsFixedRight];
  const filteredColumns = colsCombine.filter(c => isDxMode && consolidated ? true : c.key !== 'dxGrossProfit');
  const scrollX = filteredColumns.reduce((prev: number, curCol) => prev + (curCol.width as number), 0);

  return (
    <div className="table-container" style={{ position: 'relative' }}>
      <Table
        loading={loading}
        className="project-table"
        style={{ marginTop: 5, paddingBottom: 40, backgroundColor: '#fff' }}
        columns={filteredColumns}
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys: selectedProjects.map(s => s.projectId),
          getCheckboxProps: record => ({
            disabled: record.budgetCategoryCustom === 'Awarded',
          }),
          onSelect: onSelected
        }}
        dataSource={data}
        scroll={{ x: scrollX, y: tableHeightMap[viewMode] }}
        rowKey="projectId"
        size="small"
        pagination={{
          position: ['bottomRight'],
          pageSize: pageSize,
          pageSizeOptions: [50, 100, 200, 500, 1000],
        }}
        rowClassName={(record) => {
          // const addBgKeys = ['Toyo-J', 'Toyo-I'];
          // const {
          //   imbalancedMh, budgetCategoryCustom, tgc, resourceDemandMh
          // } = record;
          // if (record.budgetCategoryCustom === 'Exclusion') {
          //   return 'light-gray';
          // }
          // if (
          //   addBgKeys.includes(tgc)
          //   && imbalancedMh > (resourceDemandMh * demandMhPercent) / 100
          // ) {
          //   return 'light-red';
          // }
          // if (
          //   addBgKeys.includes(tgc)
          //   && ['Awarded', 'Budget'].includes(budgetCategoryCustom)
          //   && imbalancedMh < (resourceDemandMh * demandMhPercent) / 100
          // ) {
          //   return 'light-green';
          // }
          if (selectedProjects.map(s => s.projectId).includes(record.projectId)) {
            return 'light-selected';
          }
          return '';
        }}
        locale={{
          emptyText: (
            <Empty
              style={{ height: selectorHeight4Table - 105 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('aipcmcty.page.noData')}
            />
          ),
        }}
        onChange={(pagination) => {
          onPageSizeChange(pagination.pageSize);
        }}
      />
      <Space style={{
        position: 'absolute', left: 4, zIndex: 100, bottom: 6
      }}
      >
        <Button type="primary" disabled={!needDisable || loading} onClick={onDemandUpdate}>
          {t('aipcmcty.page.resSimulation.saveChanges')}
        </Button>
      </Space>
    </div>
  );
};

export default ResourceRegulationTable;
