import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import Ribbon from '../widget/ribbon';
import { AppContext } from '../../contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { cloneDeep, groupBy } from 'lodash';
import { orderMonthRange as monthRange } from '../../pages/simulation/resource-regulation';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { ProfitTransferItem } from './turnover-resource.chart';
import { Switch, Tag } from 'antd';
dayjs.extend(quarterOfYear);

export type DateTransferItem = {
  subProjectId: string;
  changeType: 'DATE_CHANGE',
  value: string;
  beforeValue: string;
};

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  title?: ReactElement | string[];
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  periodSwitch?: boolean;
  loading?: boolean;
  selectedProjects: any[];
  unselectBudgets: any[];
  isDxMode: boolean;
  orderYearMonths: string[];
  selectedFiscalYear: number[];
  consolidated: 0 | 1;
  department: string[];
  idKey?: string;
  dateTransferProjects?: DateTransferItem[];
  profitTransferProjects?: ProfitTransferItem[];
  onOrderYearMonthChange: (yearMonth: string) => void;
  onOrderFYChange: (e: number[]) => void;
};

export const genAndChangeFiscalInfo = (
  tgc: string,
  japanInvolved: string,
  newYear: string,
  newMonth: string
) => {
  if (
    ['Toyo-J', 'Toyo-I', 'TPS'].includes(tgc)
    || japanInvolved === 'Toyo-J関与'
  ) {
    const fiscalDate = dayjs(`${newYear}-${newMonth}`).subtract(3, 'month');
    return {
      fiscalYear: `FY${(fiscalDate.year() - 2000)}`,
      fiscalQuarter: `Q${fiscalDate.quarter()}`
    };
  }
  return null;
};

const TurnoverFlowChart: React.FC<ChartProps> = (props) => {
  const { t } = useTranslation();
  const {
    kpiCode,
    ribbonText,
    ribbonTop,
    ribbonColor,
    title,
    height,
    loading,
    data,
    selectedProjects = [],
    unselectBudgets = [],
    fiscalQuarter,
    periodSwitch,
    onOrderYearMonthChange,
    orderYearMonths,
    selectedFiscalYear,
    onOrderFYChange,
    isDxMode,
    consolidated,
    department,
    idKey = 'projectId',
    dateTransferProjects,
    profitTransferProjects
  } = props;

  const { color, sizeMode, defaultFiscalQuarter } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const [isAmount, setIsAmount] = useState(false);
  const mainFieldKey = isAmount ? 'orderAmount' : 'orderGrossProfit';
  const isSubPage = idKey === 'subProjectId';

  const customChartGroupTitle = (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
    <div style={{ paddingBottom: 4 }} key={chartName}>
      <Tag style={{ height: 22 }} color={groupColor}>
        {groupName}
      </Tag>
      {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
    </div>
  );

  let chartTitle = null;
  if (Array.isArray(title)) {
    chartTitle = customChartGroupTitle(
      t('aipcmcty.page.project'),
      isAmount ? title[1] : title[0],
      color.successColor
    );
  } else {
    chartTitle = title;
  }

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 40,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };
  const selectedProjectIdsIF = selectedProjects.filter((s) => s.budgetCategoryCustom.endsWith('IF')).map((s) => s[idKey]);
  const selectedProjectIdsOthers = selectedProjects.filter((s) => s.budgetCategoryCustom.endsWith('Others')).map((s) => s[idKey]);
  const unselectedIds = unselectBudgets.map((u) => u[idKey]);

  const fiscalYearOpts = (periodSwitch ? defaultFiscalQuarter : fiscalQuarter).map((f) => ({
    label: f,
    value: Number(`20${f.slice(-2)}`),
    key: f,
  }));

  const [options, setOptions] = useState({
    title: {
      value: isAmount ? title[1] : title[0],
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        confine: true,
        appendToBody: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          const paramsNeedRender = params.slice(1).reverse();
          let tooltip = '<div style="text-align: left;">';
          for (let index = 0; index < paramsNeedRender.length; index++) {
            const element = paramsNeedRender[index];
            tooltip += `${element.marker}${element.seriesName}: ${round(element.value / 100000000)} <br/>`;
          }
          tooltip += '</div>';
          return tooltip;
        },
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        type: 'scroll',
        left: 50,
        data: [],
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: t('aipcmc.charts.hundredMillion'),
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => value / 100000000,
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
        splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });

  const genFixedFilterMarts = orgMarts => {
    const copiedMart = cloneDeep(orgMarts);
    if (dateTransferProjects?.length) {
      dateTransferProjects.forEach(dp => {
        const cps = copiedMart.filter(cp => cp.subProjectId === dp.subProjectId);
        cps.forEach(cp => {
          const { tgc, japanInvolved } = cp;
          const [nYear, nMonth] = dp.value.split('-');
          cp.year = nYear;
          cp.month = Number(nMonth).toString();
          const fiscalInfo = genAndChangeFiscalInfo(tgc, japanInvolved, nYear, nMonth);
          if (fiscalInfo) {
            const { fiscalYear, fiscalQuarter } = fiscalInfo;
            cp.fiscalYear = fiscalYear;
            cp.fiscalQuarter = fiscalQuarter;
          }
        });
      });
    }
    if (profitTransferProjects?.length) {
      profitTransferProjects.forEach(p => {
        const cp = copiedMart.find(cp => cp.subProjectId === p.subProjectId);
        if (cp) {
          if (isAmount) {
            cp.orderAmount = p.value;
          } else {
            cp.orderGrossProfit = p.grossProfit;
          }
        }
      });
    }
    return copiedMart
      .filter((m) => !department.length || department.includes(m.department))
      .filter((m) => {
        if (m.budgetCategoryCustom.endsWith('Budget')) {
          return !unselectedIds.includes(m[idKey]);
        }
        if (m.budgetCategoryCustom.endsWith('IF')) {
          return selectedProjectIdsIF.includes(m[idKey]);
        }
        if (m.budgetCategoryCustom.endsWith('Others')) {
          return selectedProjectIdsOthers.includes(m[idKey]);
        }
        if (department.length > 0) {
          return department.includes(m.department);
        }
        return true;
      });
  };

  const updateChartData = () => {
    if (!data) {
      return;
    }
    const { mart, goal } = data;
    const goalByYear = groupBy(
      goal.filter((g) => !department.length || department.includes(g.tgc)),
      'fiscalYear'
    );
    const goals = Object.keys(goalByYear).reduce((prev, cur) => {
      prev[cur] = goalByYear[cur].reduce((pr, c) => pr + c[mainFieldKey], 0);
      return prev;
    }, {} as any);
    const filteredMart = genFixedFilterMarts(mart);
    const martToYearMonth = filteredMart
      .filter((m) => orderYearMonths.includes(`${m.fiscalYear}-${m.month}`))
      .map((m) => ({
        ...m,
        yearMonth: `${m.fiscalYear}-${m.month}`,
      }));
    const martByYM = groupBy(martToYearMonth, 'yearMonth');
    const martCurYear = orderYearMonths.reduce((prev, cur) => {
      const awards = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'Awarded') ?? [];
      const budgets = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'Budget') ?? [];
      const ifs = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'IF') ?? [];
      const others = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'Others') ?? [];
      const dxAwards = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_Awarded') ?? [];
      const dxBudgets = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_Budget') ?? [];
      const dxIfs = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_IF') ?? [];
      const dxOthers = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_Others') ?? [];
      prev[cur] = {
        awards: kpiComputeRule[kpiCode](awards ?? 0, isAmount),
        budgets: kpiComputeRule[kpiCode](budgets ?? 0, isAmount),
        ifs: kpiComputeRule[kpiCode](ifs ?? 0, isAmount),
        others: kpiComputeRule[kpiCode](others ?? 0, isAmount),
        dxAwards: kpiComputeRule[kpiCode](dxAwards ?? 0, isAmount),
        dxBudgets: kpiComputeRule[kpiCode](dxBudgets ?? 0, isAmount),
        dxIfs: kpiComputeRule[kpiCode](dxIfs ?? 0, isAmount),
        dxOthers: kpiComputeRule[kpiCode](dxOthers ?? 0, isAmount),
        total: kpiComputeRule[kpiCode](martByYM[cur] ?? 0, isAmount),
      };
      return prev;
    }, {} as any);
    const total = Object.values(martCurYear).reduce(
      (prev: any, cur: any) => {
        prev.awards += cur.awards;
        prev.budgets += cur.budgets;
        prev.ifs += cur.ifs;
        prev.others += cur.others;
        prev.dxAwards += cur.dxAwards;
        prev.dxBudgets += cur.dxBudgets;
        prev.dxIfs += cur.dxIfs;
        prev.dxOthers += cur.dxOthers;
        return prev;
      },
      {
        awards: 0,
        budgets: 0,
        ifs: 0,
        others: 0,
        dxAwards: 0,
        dxBudgets: 0,
        dxIfs: 0,
        dxOthers: 0,
      }
    );
    options.chartOptions.xAxis.data = [...monthRange.map((month) => month), 'Total'];
    let transparentBase = 0;
    const transparentData = [];
    Object.values(martCurYear).forEach((m: any) => {
      transparentData.push(transparentBase);
      transparentBase += m.total;
    });
    const transparentSeries = {
      name: 'transparent',
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      itemStyle: {
        color: 'transparent',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: [...transparentData, 0],
    };
    const commonSeries = [
      {
        name: 'Awarded',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#093B5E',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.awards), (total as any).awards],
      },
      {
        name: 'Budget',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#3299D9',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.budgets), (total as any).budgets],
      },
      {
        name: 'IF',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#9FD9F6',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.ifs), (total as any).ifs],
      },
      {
        name: 'Others',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#B0CEDB',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.others), (total as any).others],
      },
    ];
    const dxSeries = [
      {
        name: 'DX_Awarded',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#093B5E',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxAwards), (total as any).dxAwards],
      },
      {
        name: 'DX_Budget',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#195D87',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxBudgets), (total as any).dxBudgets],
      },
      {
        name: 'DX_IF',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#507FA2',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxIfs), (total as any).dxIfs],
      },
      {
        name: 'DX_Others',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#619BC5',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxOthers), (total as any).dxOthers],
      },
    ];
    const goalSeries = {
      name: 'Goal',
      type: 'line',
      symbol: 'none',
      itemStyle: {
        color: '#DD6B66',
      },
      lineStyle: {
        cap: 'square',
      },
      data: Array.from({ length: monthRange.length + 1 }, () => goals[`FY${selectedFiscalYear[0].toString().slice(-2)}`] ?? 0),
    };
    options.chartOptions.series =
      isDxMode && consolidated
        ? [transparentSeries, ...commonSeries, ...dxSeries, goalSeries]
        : [transparentSeries, ...commonSeries, goalSeries];
    options.chartOptions.legend.data =
      isDxMode && consolidated
        ? ['Awarded', 'Budget', 'IF', 'Others', 'DX_Awarded', 'DX_Budget', 'DX_IF', 'DX_Others']
        : ['Awarded', 'Budget', 'IF', 'Others'];
    // options.chartOptions.legend.left = isDxMode && consolidated ? 'left' : 'center';
    // (options.chartOptions.legend as any).left = 50;
    // (options.chartOptions.legend as any).width = 330;
    setOptions(cloneDeep(options));
  };

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const updateChartTable = () => {
    if (!data) {
      return;
    }
    const { mart, goal } = data;
    const filteredMart = genFixedFilterMarts(mart);
    const fiscalYearT = filteredMart.reduce(
      (prev, cur) => {
        if (defaultFiscalQuarter.includes(cur.fiscalYear)) {
          prev[cur.fiscalYear] += cur[mainFieldKey];
        }
        return prev;
      },
      Object.fromEntries(defaultFiscalQuarter.map(fy => [fy, 0]))
    );

    const fiscalYearG = goal.reduce(
      (prev, cur) => {
        if (defaultFiscalQuarter.includes(cur.fiscalYear)) {
          prev[cur.fiscalYear] += cur[mainFieldKey];
        }
        return prev;
      },
      Object.fromEntries(defaultFiscalQuarter.map(fy => [fy, 0]))
    );
    const fiscalGPercent = Object.keys(fiscalYearT)
      .reduce((prev, k) => {
        prev[k] = fiscalYearG[k] !== 0 ? `${(fiscalYearT[k] / fiscalYearG[k] * 100).toFixed(1)}%` : '-';
        return prev;
      }, {} as any);

    const cols = Object.keys(fiscalYearT)
      .sort((a, b) => a < b ? -1 : 1)
      .map(fy => {
        return {
          title: fy,
          dataIndex: fy,
          className: 'text-right',
          width: sizeMode === 'big' ? 100 : 50,
          render: val => {
            if (typeof val === 'number') {
              return Number((val / 100000000).toFixed(2)).toLocaleString()
            }
            return val;
          }
        };
      });
    cols.unshift({
      title: '',
      dataIndex: 'type',
      fixed: 'left',
      width: sizeMode === 'big' ? 50 : 40,
    } as any);
    setTableColumns(cols);
    setTableData([
      {
        ...fiscalYearT,
        type: `${t('aipcmcty.page.value')}(${t('aipcmcty.page.billion')})`,
        key: 'value'
      },
      {
        ...fiscalGPercent,
        key: 'goal',
        type: t('aipcmcty.page.achievementRate')
      }
    ]);
  }

  useEffect(() => {
    updateChartData();
    if (isSubPage) {
      updateChartTable();
    }
  }, [
    data,
    kpiComputeRule,
    dateTransferProjects,
    profitTransferProjects,
    selectedProjects,
    department,
    selectedFiscalYear[0],
    isAmount
  ]);

  const handleChartEvent = (e) => {
    if (!e || e.chartTitle.value.key !== chartTitle.key) {
      return;
    }
    const { eventType, event } = e;
    if (
      eventType === ECHART_EVENT.CHART_CLEAR_SELECTED ||
      (eventType === ECHART_EVENT.CHART_ITEM_SELECTED && event.dataIndex > orderYearMonths.length - 1)
    ) {
      onOrderYearMonthChange(null);
    }
    if (eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      if (event.dataIndex > orderYearMonths.length - 1) return;
      const [year, month] = orderYearMonths[event.dataIndex].replace('FY', '20').split('-');
      let numYear = Number(year);
      if (Number(month) <= 3) {
        numYear += 1;
      }
      onOrderYearMonthChange(`${numYear}-${Number(month) < 10 ? `0${month}` : month}`);
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} top={ribbonTop} color={ribbonColor || color.primaryColor} />
      ) : (
        <></>
      )}
      <Switch
        style={{
          position: 'absolute',
          top: 15,
          right: 80,
          zIndex: 10,
          background: color.primaryColor
        }}
        disabled={loading}
        checked={isAmount}
        checkedChildren="金額"
        unCheckedChildren="粗利"
        onChange={checked => setIsAmount(checked)}
      />
      <TableChart
        showTable={Boolean(tableData.length)}
        tableColumns={tableColumns}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={
          chartTitle
            ? {
                value: chartTitle,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : options.title
        }
        height={height}
        loading={loading}
        isBank={!data || data.length === 0}
        tableOptions={null}
        onChartEvent={handleChartEvent}
        select={{
          type: 'cascader',
          value: selectedFiscalYear,
          list: fiscalYearOpts,
          styles: {
            position: 'absolute',
            right: 0,
            zIndex: 1,
          },
          notMinus: true,
          onChange: (value) => {
            onOrderFYChange(value);
          },
        }}
      />
    </>
  );
};

export default TurnoverFlowChart;
