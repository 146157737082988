/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Select, message } from 'antd';
import { uniqBy } from 'lodash';
import { ProjectSetupDetailContext } from '../contexts/project-setup-detail.context';
import { useTranslation } from 'react-i18next';

type ProjectSetupDetailHeaderProp = {
  loading?: boolean;
}

const ProjectSetupSupplyHeader: React.FC<any> = (props: ProjectSetupDetailHeaderProp) => {
  const {
    loading
  } = props;
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const [supplyOption, setSupplyOption] = useState<any>({
    division: [],
    discipline: [],
    role: []
  });

  const [division, setDivision] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [roles, setRoles] = useState([]);

  const [init, setInit] = useState(true);

  const {
    setSearchSupplyForm,
    supplyTableData,

  } = useContext(ProjectSetupDetailContext);

  useEffect(() => {
    if (supplyTableData && init) {
      const divisionOption = uniqBy(supplyTableData.map(item => ({
        key: item.division,
        label: item.division,
        value: item.division,
      })), 'key');
      const disciplineOption = uniqBy(supplyTableData.map(item => ({
        key: item.discipline,
        label: item.discipline,
        value: item.discipline,
      })), 'key');
      const roleOption = uniqBy(supplyTableData.map(item => ({
        key: item.role,
        label: item.role,
        value: item.role,
      })), 'key');
      setSupplyOption({
        division: divisionOption,
        discipline: disciplineOption,
        role: roleOption
      });
      const divisionValue = divisionOption.map((item: any) => item.value);
      const disciplineValue = disciplineOption.map((item: any) => item.value);
      const roleValue = roleOption.map((item: any) => item.value);
      setDivision(divisionOption.map((item: any) => item.value));
      setDiscipline(disciplineOption.map((item: any) => item.value));
      setRoles(roleOption.map((item: any) => item.value));
      setSearchSupplyForm({
        division: divisionValue,
        discipline: disciplineValue,
        roles: roleValue
      });
      setInit(false);
    }
  }, [supplyTableData]);

  return (
    <>
      {contextHolder}
      <div className="select-group-container flex-container" style={{ flexDirection: 'column' }}>
        <div style={{
          display: 'flex', padding: '8px 0', justifyContent: 'space-between', width: '100%'
        }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label>Division</label>
              <div style={{ marginLeft: 30 }}>
                <Select
                  mode="multiple"
                  value={division}
                  className="select-no-warp"
                  style={{ width: 160 }}
                  maxTagCount={1}
                  maxTagTextLength={4}
                  options={supplyOption.division}
                  onChange={(value) => {
                    setDivision(value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <label>Discipline</label>
              <div style={{ marginLeft: 30 }}>
                <Select
                  mode="multiple"
                  value={discipline}
                  className="select-no-warp"
                  style={{ width: 160 }}
                  maxTagCount={1}
                  maxTagTextLength={4}
                  options={supplyOption.discipline}
                  onChange={(value) => {
                    setDiscipline(value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <label>Role</label>
              <div style={{ marginLeft: 30 }}>
                <Select
                  mode="multiple"
                  value={roles}
                  className="select-no-warp"
                  style={{ width: 160 }}
                  maxTagCount={1}
                  maxTagTextLength={4}
                  options={supplyOption.role}
                  onChange={(value) => {
                    setRoles(value);
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <Button
                disabled={loading} onClick={() => {
                  setSearchSupplyForm({
                    division, discipline, roles
                  });
                }}
              >
                {t('aipcmcty.page.extraction')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSetupSupplyHeader;