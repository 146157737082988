/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-shadow */
import {
  Button,
  Modal,
  Space,
  message,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Slider,
  Tabs,
  AutoComplete,
  Input,
  Tag,
  InputNumber,
  Tooltip,
  Divider,
  Card,
  Cascader,
  Switch,
  Table,
} from 'antd';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { BarsOutlined } from '@ant-design/icons';
import moment from 'moment';
import { cloneDeep, debounce, groupBy, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import APIList from '../../http/ApiList';
import ResourceRegulationTable, { ResourceRegulationTableItem } from '../../components/tables/resource-regulation.table';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import { localeMapping } from '../../consts/locale.mapping';
import CustomerScrollBar from '../../components/widget/customer-scrollbar';
import { RecordItem } from '../../contexts/provider/aipcmcty.provider.context';
import { convertToJSON } from '../../utils/commonUtil';
import ResourceSimulationChart from '../../components/charts/resource-simulation.chart';
import TurnoverResource from '../../components/charts/turnover-resource.chart';
import usePageLeaveConfirm from '../../hooks/usePageLeaveConfirm';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { ChartTableLayout } from '@meteor/frontend-core';
import ProjectSetupDetail from '../../components/project-setup-detail';
import TurnoverFlowChart from '../../components/charts/turnover-flow.chart';
import { ColumnsType } from 'antd/lib/table';
import { departmentOpts } from './resource-simulation';

const timeout = 360000;

// generate demand year month options
const genYearMonths = () => {
  const currentYear = Number(moment().subtract(1, 'year').format('yyyy'));
  return Array.from({ length: 5 }, (x, i) => (currentYear + i).toString()).reduce((prev, cur) => {
    prev.push(
      ...Array.from({ length: 12 }, (_, i) => {
        if (i + 1 < 10) {
          return `${cur}-0${i + 1}`;
        }
        return `${cur}-${i + 1}`;
      })
    );
    return prev;
  }, [] as string[]);
};


// generate fiscal month range
// sample: FY24 Apr ~ Dec, FY25 Jan ~ Mar
export const orderMonthRange = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

// relative item change maps
export const tableChangeMap = {
  orderAmount: (item) => {
    item.grossProfit = Math.round(item.orderAmount * item.grossMargin);
  },
  grossMargin: (item) => {
    item.grossProfit = Math.round(item.orderAmount * item.grossMargin);
  },
  projectStartDate: (item, diffDay) => {
    item.projectStartDate = moment(item.projectStartDate).format('YYYY-MM-DD');
    item.projectEndDate = moment(item.projectEndDate).add(diffDay, 'days').format('YYYY-MM-DD');
    item.orderScheduledDate = moment(item.orderScheduledDate).add(diffDay, 'days').format('YYYY-MM-DD');
  },
  duration: (item) => {
    if (item.duration <= 0) {
      item.duration = 1;
    }
    item.projectEndDate = moment(item.projectStartDate).add(item.duration, 'months').format('YYYY-MM-DD');
  },
  phase: (item, opts = []) => {
    const filterStr = item.certainty + item.phase;
    item.won = Number(opts.find((w) => w.label === filterStr).value);
  },
};

// filter rules for table fields
const filterMethods = {
  search: (item, v = '') => item.projectName?.includes(v) || item.projectId.includes(v),
  accountingYear: (item, v) => !v?.length || v.some((i) => i.includes(item.accountingYear)),
  // tgc: (item, v) => !v?.length || v.includes(item.tgc),
  japanInvolved: (item, v) => {
    if (v) {
      return item.japanInvolved === 'Toyo-J関与';
    }
    return true;
  },
  scope: (item, v) => !v?.length || v.includes(item.scope),
  isExisted: (item, v) => (v ? item.isExisted === v : true),
  isEpc: (item, v) => (v ? item.isEpc === v : true),
  businessType: (item, v) => !v?.length || v.includes(item.businessType),
  goodsType: (item, v) => !v?.length || v.includes(item.goodsType),
  budgetCategoryCustom: (item, v) => !v?.length || v.includes(item.budgetCategoryCustom ?? item.budgetCategory),
  priorityMatching: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
  priorityDefault: (item, v, k) => !v || (item[k] >= v[0] && item[k] <= v[1]),
  orderAmount: (item, v, k) => {
    const minLimit = 5000000000;
    const maxLimit = 60000000000;
    const sortedVString = v?.sort().join('');
    switch (sortedVString) {
      case 'lt50':
        return item[k] < minLimit;
      case 'gt600':
        return item[k] > maxLimit;
      case '50_600':
        return item[k] >= minLimit && item[k] <= maxLimit;
      case '50_600gt600':
        return item[k] >= minLimit;
      case '50_600lt50':
        return item[k] <= maxLimit;
      case 'gt600lt50':
        return item[k] > maxLimit || item[k] < minLimit;
      default:
        return true;
    }
  },
  grossMargin: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
  imbalancedMh: (item, v, k) => {
    switch (v) {
      case '0':
        return item[k] === 0;
      case '1':
        return item[k] > 0;
      default:
        return true;
    }
  },
  won: (item, v, k) => !v || (item[k] * 100 >= v[0] && item[k] * 100 <= v[1]),
  phase: (item, v) => !v?.length || v.includes(item.phase),
  certainty: (item, v) => !v?.length || v.includes(item.certainty),
  projectStartDate: (item, v) => {
    if (!v) {
      return true;
    }
    const { projectStartDate } = item;
    return moment(projectStartDate).isSameOrAfter(moment(v));
  },
  projectEndDate: (item, v) => {
    if (!v) {
      return true;
    }
    const { projectEndDate } = item;
    return moment(projectEndDate).isSameOrBefore(moment(v));
  },
  dxApplicable: (item, v, k) => !v || item[k] === Boolean(Number(v)),
  orderScheduledDate: (item, v, k) => !v || item[k].startsWith(v),
  isForPpReport: (item, v, k) => [undefined, null].includes(v) || item[k] === v,
  department: (item, v, k) => !v?.length || v.includes(item[k]),
};

const initialKpiOrder = [33, 35, 36];

const ResourceRegulation: React.FC = () => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('chart-table');
  // about drawer
  const { fiscalQuarter, periodSwitch, setMenuCollapsed, color, locale, canLeave, setCanLeave } = useContext(AppContext);

  const { snapshot, snapshotVersion, tableCanOperation, user, getOrgKpiData } = useContext(AipcmctyContext);

  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
    maxWidth: 320,
    minWidth: 0,
  };
  // about drawer form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  // initialize the table data list and options
  const initializeTableData = async () => {
    const [data, options] = await Promise.all([
      APIList.getProjectCase().get({
        snapshot,
        snapshotVersion,
        budgetRange: ['Awarded', 'Budget', 'IF', 'Others'].join(','),
      }) as Promise<ResourceRegulationTableItem[]>,
      APIList.getCmcOptions().get({
        category: 'all',
        snapshot,
        snapshotVersion,
      }) as Promise<any>,
    ]);
    setTableDataNeedFilter(data);
    setUnselectBudgets([]);
    setSelectedProjects([]);
    setResSimCacheData({});

    // 日本関与有無
    const japanInvolved = [
      {
        key: 1,
        value: 1,
        label: t('aipcmcty.page.have'),
      },
      {
        key: 0,
        value: 0,
        label: t('aipcmcty.page.no'),
      },
    ];

    // 会計対象
    const consolidated = [
      {
        key: t('aipcmcty.page.consolidated'),
        value: 1,
        label: t('aipcmcty.page.consolidated'),
      },
      {
        key: t('aipcmcty.page.single'),
        value: 0,
        label: t('aipcmcty.page.single'),
      },
    ];

    const initialFiltersData = { ...filtersData, tgc: ['Toyo-J', 'Toyo-I'] };
    const sliderProps = data.reduce(
      (prev, cur, index) => {
        prev.priorityDefault.push(cur.priorityDefault ?? index + 1);
        prev.grossMargin.push(cur.grossMargin * 100);
        prev.won.push(cur.won * 100);
        return prev;
      },
      {
        priorityDefault: [],
        grossMargin: [],
        won: [],
      }
    );
    const sliderOpts = Object.keys(sliderProps).reduce((prev, k) => {
      let min = 0;
      let max = 100;
      if (k === 'priorityDefault') {
        const range = sliderProps[k].length > 0 ? sliderProps[k] : [1, 100];
        min = Math.round(Math.min(...range));
        max = Math.round(Math.max(...range));
      }
      const stepLen = max.toFixed(0).length - 2;
      const step = ['won', 'grossMargin'].includes(k) ? 1 : Number(1 + Array.from({ length: stepLen }, () => 0).join(''));
      const markMin = ['won', 'grossMargin'].includes(k) ? `${min}%` : min;
      const markMax = ['won', 'grossMargin'].includes(k) ? `${max}%` : max;
      initialFiltersData[k] = [min, max];
      prev[`${k}SliderOpts`] = {
        min,
        max,
        range: true,
        step,
        marks: {
          [min]: markMin,
          [max]: markMax,
        },
      };
      return prev;
    }, {});
    initialFiltersData.mhDepartment = ['All', 'All'];
    initialFiltersData.demandYearMonth = null;
    setFiltersData(initialFiltersData);
    form.setFieldsValue(initialFiltersData);
    setOptOrgData({
      ...options,
      japanInvolved,
      consolidated,
      ...sliderOpts,
    });
  };

  const [tableDataNeedFilter, setTableDataNeedFilter] = useState([]);
  const [optOrgData, setOptOrgData] = useState(null);
  const [optData, setOptData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  // 会計観点
  const [accountingPerspective, setAccountingPerspective] = useState<number>(4);
  const [accountingPerspectiveOpt, setAccountingPerspectiveOpt] = useState([
    {
      key: 1,
      value: 1,
      label: `${t('aipcmcty.page.consolidated')}＋${t('aipcmcty.page.equity')}`,
    },
    {
      key: 2,
      value: 2,
      label: t('aipcmcty.page.consolidated'),
    },
    {
      key: 3,
      value: 3,
      label: t('aipcmcty.page.toyoJConsolidated'),
    },
    {
      key: 4,
      value: 4,
      label: t('aipcmcty.page.baseUnit'),
    },
  ]);
  // 会計対象
  const [consolidated, setConsolidated] = useState();
  // PP連絡報告
  const useCaseOpts = [
    /** 事業ポートフォリオ委員会 */
    t('aipcmcty.page.resSimulation.businessPortfolio'),
    /** PP連絡会議 */
    t('aipcmcty.page.resSimulation.ppContact'),
  ].map((u, i) => ({
    label: u,
    value: i,
  }));
  const [useCase, setUseCase] = useState(useCaseOpts[0].value);
  const handleUseCaseChange = (v: (typeof useCaseOpts)[number]['value']) => {
    setUseCase(v);
    switch (v) {
      case 1:
        setAccountingPerspective(2);
        setKpiOrder([33, 37, 36]);
        setFiltersData((prev) => ({
          ...prev,
          accountingYear: orderFiscalYear.map(oy => `FY${oy.toString().slice(-2)}`),
          budgetCategoryCustom: ['Awarded', 'Budget'],
          department: []
        }));
        form.setFieldsValue({
          accountingYear: orderFiscalYear.map(oy => `FY${oy.toString().slice(-2)}`),
          budgetCategoryCustom: ['Awarded', 'Budget'],
        });
        break;
      case 0:
      default:
        setAccountingPerspective(4);
        setKpiOrder(initialKpiOrder);
        setFiltersData((d) => ({
          ...d,
          orderScheduledDate: null,
          department: []
        }));
        form.setFieldsValue({
          orderScheduledDate: null,
        });
        break;
    }
  };
  const useCaseTableColsMap = {
    0: [
      'accountingYear',
      'tgc',
      'relatedTgc',
      'budgetCategoryCustom',
      'orderScheduledDate',
      'demandForecastType',
      'monthDemandMH',
      'demandMh',
      'dxDemandMh',
      'orderAmount',
      'grossProfit',
      'dxGrossProfit',
      'grossMargin',
      'grossProfitPerMh',
      'projectStartDate',
      'projectEndDate',
      'duration',
      'won',
      'certainty',
      'constructionLocation',
    ],
    1: ['accountingYear', 'tgc', 'orderScheduledDate', 'budgetCategoryCustom', 'orderAmount', 'grossProfit'],
  };
  const tableColsToShow = useMemo(() => useCaseTableColsMap[useCase], [useCase]);
  const [filtersData, setFiltersData] = useState({
    tgc: ['Toyo-J', 'Toyo-I'],
    japanInvolved: null,
    consolidated: null,
    accountingYear: [],
    scope: [],
    isExisted: null,
    isEpc: null,
    businessType: [],
    goodsType: [],
    priorityMatching: null,
    priorityDefault: [],
    budgetCategoryCustom: [],
    orderAmount: [],
    grossMargin: [],
    imbalancedMh: null,
    won: [],
    phase: [],
    certainty: [],
    projectStartDate: null,
    projectEndDate: null,
    dxApplicable: null,
    demandYearMonth: null,
    mhDepartment: ['All', 'All'],
    orderScheduledDate: null,
    isForPpReport: null,
    department: [],
  });

  const demandYearMonthRange = genYearMonths();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [unselectBudgets, setUnselectBudgets] = useState([]);
  const [listNeedRender, setListNeedRender] = useState([]);
  const handleTableRowSelected = (record, selected) => {
    setSelectedProjects(selected ? selectedProjects.concat(record) : selectedProjects.filter((s) => s.projectId !== record.projectId));
    if (record.budgetCategoryCustom === 'Budget') {
      if (selected) {
        setUnselectBudgets((prev) => prev.filter((p) => p.projectId !== record.projectId));
      } else {
        setUnselectBudgets((prev) => [...prev, record]);
      }
    }
  };

  const handleDemandProjects = async (yearMonth: string) => {
    setFiltersData((fData) => ({ ...fData, demandYearMonth: yearMonth }));
    form.setFieldsValue({ demandYearMonth: yearMonth });
    changeRenderList({
      demandYearMonth: yearMonth,
      mhDepartment: filtersData.mhDepartment as [string, string],
    });
  };

  const [orderFiscalYear, setOrderFiscalYear] = useState([moment().year()]);
  const orderYearMonths = orderMonthRange.map(
    (m) => `FY${orderFiscalYear[0].toString().slice(-2)}-${m}`
  );

  const orderYMOpts = orderYearMonths.map((ym) => {
    const [year, month] = ym.replace('FY', '20').split('-');
    let numYear = Number(year);
    if (Number(month) <= 3) {
      numYear += 1;
    }
    return {
      label: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
      key: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
      value: `${numYear}-${Number(month) < 10 ? `0${month}` : month}`,
    };
  });
  const handleOrderFYChange = (e: number[]) => {
    setOrderFiscalYear(e);
    setFiltersData(prev => ({
      ...prev,
      accountingYear: [`FY${e[0].toString().slice(-2)}`],
      orderScheduledDate: null
    }));
    form.setFieldsValue({
      accountingYear: [`FY${e[0].toString().slice(-2)}`],
      orderScheduledDate: null
    });
  };
  const handleOrderScheduledDateChange = (yearMonth: string) => {
    setFiltersData((fData) => ({ ...fData, orderScheduledDate: yearMonth }));
    form.setFieldsValue({ orderScheduledDate: yearMonth });
    setMenuCollapsed(true);
    setFilterCollapsed(false);
  };

  useEffect(() => {
    const getTableData = async () => {
      setTableLoading(true);
      await initializeTableData();
      setTableLoading(false);
    };
    getTableData();
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    if (!optOrgData) {
      return;
    }
    const optOrgDataCopy = cloneDeep(optOrgData);
    switch (accountingPerspective) {
      // 連結＋持分
      case 1:
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // 連結
      case 2:
        optOrgDataCopy.tgc = optOrgDataCopy.tgc.filter((item: any) => item.attribute1 !== 'equity');
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // Toyo-J連結
      case 3:
        optOrgDataCopy.tgc = optOrgDataCopy.tgc.filter((item: any) => item.attribute1 !== 'equity');
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[0]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[0]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: optOrgDataCopy.tgc.map((item) => item.value),
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      // 拠点単体
      case 4:
        optOrgDataCopy.japanInvolved = [optOrgDataCopy.japanInvolved[1]];
        optOrgDataCopy.consolidated = [optOrgDataCopy.consolidated[1]];
        setFiltersData((fData) => ({
          ...fData,
          tgc: ['Toyo-J', 'Toyo-I'],
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
      default:
        setFiltersData((fData) => ({
          ...fData,
          tgc: ['Toyo-J'],
          japanInvolved: optOrgDataCopy.japanInvolved[0].value,
        }));
        setConsolidated(optOrgDataCopy.consolidated[0].value);
        break;
    }
    setOptData(optOrgDataCopy);
  }, [optOrgData, accountingPerspective]);

  const [dxOn, setDxOn] = useState(false);

  useEffect(() => {
    setTableDataNeedFilter(
      tableDataNeedFilter.map((t) => {
        return {
          ...t,
          dxApplicable: dxOn ? t.dxAvailable : false,
        };
      })
    );
  }, [dxOn]);

  useEffect(() => {
    if (!isNil(consolidated)) {
      getChartData();
    }
  }, [consolidated, snapshot, snapshotVersion, fiscalQuarter, periodSwitch, dxOn]);

  const handleAccountPerspectiveChange = (v: number) => {
    setAccountingPerspective(v);
    if (v !== 3) {
      setFiltersData(fData => ({ ...fData, department: [] }));
    }
  }

  // about filter
  const handleFilterChange = (k, v) => {
    setFiltersData((fData) => {
      if (k === 'tgc' && v.length !== 1 && v[0] !== 'Toyo-J') {
        return { ...fData, [k]: v, department: [] };
      }
      return { ...fData, [k]: v };
    });
  };

  const [pageSize, setPageSize] = useState(50);
  const handlePageSizeChange = (pZ: number) => {
    setPageSize(pZ);
  };

  const handleSiderFilterChange = debounce(async (changedValue) => {
    setFiltersData((fData) => ({ ...fData, ...changedValue }));
    const key = Object.keys(changedValue)[0];
    if (['demandYearMonth', 'mhDepartment'].includes(key)) {
      const otherKey = ['demandYearMonth', 'mhDepartment'].find((k) => k !== key);
      const opts = {
        ...changedValue,
        [otherKey]: filtersData[otherKey],
      };
      if (key === 'mhDepartment') {
        await initKpiData([['ResourceSimulation']], !!consolidated, {
          isDXMode: dxOn,
          division: changedValue.mhDepartment[0] === 'All' ? '' : changedValue.mhDepartment[0],
          discipline: changedValue.mhDepartment[1] === 'All' ? '' : changedValue.mhDepartment[1],
          partial: true,
        });
      }
      await changeRenderList(opts);
    }
  }, 300);

  const [form] = Form.useForm();

  const [imbalancedMhOpt] = useState(
    [
      { label: '需給均衡', value: '0' },
      { label: '需給不足', value: '1' },
    ].map((item) => ({
      ...item,
      label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
    }))
  );

  const [priorityMatchingOpt] = useState([
    { label: `${t('aipcmcty.page.have')}`, value: true },
    { label: `${t('aipcmcty.page.no')}`, value: false },
  ]);

  const [mhDepartmentOpts, setMHDepartmentOpts] = useState([]);

  const tabs = [
    {
      key: '1',
      label: t('aipcmcty.page.refinement'),
      children: (
        <CustomerScrollBar style={{ height: 'calc(100% - 16px)', padding: '0 10px' }}>
          <Form {...layout} onValuesChange={handleSiderFilterChange} form={form} initialValues={filtersData}>
            <Divider>MH</Divider>
            <Form.Item label={t('aipcmcty.page.resSimulation.demandYearMonth')} name="demandYearMonth">
              <Select allowClear options={demandYearMonthRange.map((ym) => ({ label: ym, key: ym, value: ym }))} />
            </Form.Item>
            <Form.Item label={t('aipcmcty.page.resSimulation.mhDepartment')} name="mhDepartment">
              <Cascader allowClear={false} options={mhDepartmentOpts} />
            </Form.Item>
            {useCase === 1 && (
              <>
                <Divider>{t('aipcmcty.page.orderGrossProfit')}</Divider>
                <Form.Item label={t('aipcmcty.page.resSimulation.orderYearMonth')} name="orderScheduledDate">
                  <Select allowClear options={orderYMOpts} />
                </Form.Item>
              </>
            )}
            <Divider>{t('aipcmcty.page.projectDivider')}</Divider>
            <Form.Item name="search" wrapperCol={{ span: 24 }}>
              <AutoComplete
                options={tableDataNeedFilter.map((t) => ({
                  label: t.projectName,
                  value: t.projectName,
                }))}
              >
                <Input.Search placeholder={`${t('aipcmcty.page.projectCaseName')} / ${t('aipcmcty.page.projectCaseId')}`} allowClear />
              </AutoComplete>
            </Form.Item>
            <Form.Item label={t('aipcmcty.page.resSimulation.ppReport')} name="isForPpReport">
              <Select
                allowClear
                options={[
                  { label: t('aipcmcty.page.resSimulation.isReportObj'), value: true, key: t('aipcmcty.page.resSimulation.isReportObj') },
                  {
                    label: t('aipcmcty.page.resSimulation.isNotReportObj'),
                    value: false,
                    key: t('aipcmcty.page.resSimulation.isNotReportObj'),
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label={`${t('aipcmcty.page.fiscalYear')}`} name="accountingYear">
              <Select allowClear mode="multiple" maxTagCount={2} options={optData?.accountingYear} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.serviceScope')} className="ellipsis">
                  {t('aipcmcty.page.serviceScope')}
                </div>
              }
              name="scope"
            >
              <Select allowClear mode="multiple" maxTagCount={1} options={optData?.scope} />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`} className="ellipsis">
                  {`${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`}
                </div>
              }
              name="isExisted"
            >
              <Select
                allowClear
                options={optData?.isExisted.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={`${t('aipcmcty.page.nonEPC')}/EPC`} className="ellipsis">
                  {`${t('aipcmcty.page.nonEPC')}/EPC`}
                </div>
              }
              name="isEpc"
            >
              <Select
                allowClear
                options={optData?.isEpc.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.businessType')} className="ellipsis">
                  {t('aipcmcty.page.businessType')}
                </div>
              }
              name="businessType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.businessType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.productType')} className="ellipsis">
                  {t('aipcmcty.page.productType')}
                </div>
              }
              name="goodsType"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.goodsType.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.priorityLevel')} className="ellipsis">
                  {t('aipcmcty.page.priorityLevel')}
                </div>
              }
              name="priorityDefault"
            >
              <Slider {...optData?.priorityDefaultSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.budgetCategory')} className="ellipsis">
                  {t('aipcmcty.page.budgetCategory')}
                </div>
              }
              name="budgetCategoryCustom"
            >
              <Select allowClear mode="multiple" options={optData?.budgetCategory} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.orderAmount')} className="ellipsis">
                  {t('aipcmcty.page.orderAmount')}
                </div>
              }
              name="orderAmount"
            >
              <Select
                allowClear
                mode="multiple"
                options={optData?.orderAmount.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
                maxTagCount={1}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.grossProfitRate')} className="ellipsis">
                  {t('aipcmcty.page.grossProfitRate')}
                </div>
              }
              name="grossMargin"
            >
              <Slider {...optData?.grossMarginSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.demandSupplyFilterMH')} className="ellipsis">
                  {t('aipcmcty.page.demandSupplyFilterMH')}
                </div>
              }
              name="imbalancedMh"
            >
              <Select allowClear options={imbalancedMhOpt} maxTagCount={1} />
            </Form.Item>
            <Form.Item
              label={
                <div title="Won%" className="ellipsis">
                  Won%
                </div>
              }
              name="won"
            >
              <Slider {...optData?.wonSliderOpts} />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.phase')} className="ellipsis">
                  {t('aipcmcty.page.phase')}
                </div>
              }
              name="phase"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.phase.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.accuracy')} className="ellipsis">
                  {t('aipcmcty.page.accuracy')}
                </div>
              }
              name="certainty"
            >
              <Select
                allowClear
                mode="multiple"
                maxTagCount={1}
                options={optData?.certainty.map((item) => ({
                  ...item,
                  label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
                }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.startDate')} className="ellipsis">
                  {t('aipcmcty.page.startDate')}
                </div>
              }
              name="projectStartDate"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={
                <div title={t('aipcmcty.page.endDate')} className="ellipsis">
                  {t('aipcmcty.page.endDate')}
                </div>
              }
              name="projectEndDate"
            >
              <DatePicker />
            </Form.Item>
          </Form>
        </CustomerScrollBar>
      ),
    },
  ];

  // about formations modal and table
  const [isFormationModalOpen, setFormationModalOpen] = useState(false);
  const [curTableItem, setCurTableItem] = useState<ResourceRegulationTableItem>(null);
  const [formationTableLoading, setFormationTableLoading] = useState(false);

  const calcFeedActive = (item: ResourceRegulationTableItem) => {
    if (!item) {
      return false;
    }
    const { projectId, scope, demandForecastType } = item;
    const pIds = projectId.split(',');
    switch (pIds.length) {
      case 1:
        return scope.includes('FEED');
      case 2:
        return demandForecastType.split(',')[0] === 'effimate';
      default:
        return false;
    }
  };

  const calcEpcActive = (item: ResourceRegulationTableItem) => {
    if (!item) {
      return false;
    }
    const { projectId, scope, demandForecastType } = item;
    const pIds = projectId.split(',');
    switch (pIds.length) {
      case 1:
        return !scope.includes('FEED');
      case 2:
        return demandForecastType.split(',')[1] === 'effimate';
      default:
        return false;
    }
  };

  const isFeedActive = calcFeedActive(curTableItem);
  const isEpcActive = calcEpcActive(curTableItem);

  const getRatio = (ratioStr: string | null | undefined) => {
    if (!ratioStr) {
      return [null, null];
    }
    const reg = /^Toyo-J:(\d+)\|Toyo-I:(\d+)$/;
    const { 1: japanRatio, 2: indiaRatio } = ratioStr.match(reg);
    return [Number(japanRatio), Number(indiaRatio)];
  };
  const [tgcFeedJE, tgcFeedIE] = getRatio(curTableItem?.formTgcFeedE);
  const [tgcEpcJE, tgcEpcIE] = getRatio(curTableItem?.formTgcEpcE);
  const [tgcFeedJP, tgcFeedIP] = getRatio(curTableItem?.formTgcFeedP);
  const [tgcEpcJP, tgcEpcIP] = getRatio(curTableItem?.formTgcEpcP);
  const [tgcFeedJC, tgcFeedIC] = getRatio(curTableItem?.formTgcFeedC);
  const [tgcEpcJC, tgcEpcIC] = getRatio(curTableItem?.formTgcEpcC);

  const formationTableData = [
    {
      formType: 'E',
      jvFeed: curTableItem?.formJvFeedE,
      tgcFeedJ: tgcFeedJE,
      tgcFeedI: tgcFeedIE,
      jvEpc: curTableItem?.formJvEpcE,
      tgcEpcJ: tgcEpcJE,
      tgcEpcI: tgcEpcIE,
      isFeedActive,
      isEpcActive,
    },
    {
      formType: 'P',
      jvFeed: curTableItem?.formJvFeedP,
      tgcFeedJ: tgcFeedJP,
      tgcFeedI: tgcFeedIP,
      jvEpc: curTableItem?.formJvEpcP,
      tgcEpcJ: tgcEpcJP,
      tgcEpcI: tgcEpcIP,
      isFeedActive,
      isEpcActive,
    },
    {
      formType: 'C',
      jvFeed: curTableItem?.formJvFeedC,
      tgcFeedJ: tgcFeedJC,
      tgcFeedI: tgcFeedIC,
      jvEpc: curTableItem?.formJvEpcC,
      tgcEpcJ: tgcEpcJC,
      tgcEpcI: tgcEpcIC,
      isFeedActive,
      isEpcActive,
    },
  ];

  const [cachedFormation, setCachedFormation] = useState(null);

  const handleFormationJVChange = (e, type, formType) => {
    const newItem = { ...curTableItem };
    switch (true) {
      case type === 'JV_FEED' && formType === 'E':
        newItem.formJvFeedE = e;
        break;
      case type === 'JV_FEED' && formType === 'P':
        newItem.formJvFeedP = e;
        break;
      case type === 'JV_FEED' && formType === 'C':
        newItem.formJvFeedC = e;
        break;
      case type === 'JV_FEED' && formType === 'PJT':
        newItem.formJvFeedPjt = e;
        break;
      case type === 'JV_EPC' && formType === 'E':
        newItem.formJvEpcE = e;
        break;
      case type === 'JV_EPC' && formType === 'P':
        newItem.formJvEpcP = e;
        break;
      case type === 'JV_EPC' && formType === 'C':
        newItem.formJvEpcC = e;
        break;
      case type === 'JV_EPC' && formType === 'PJT':
        newItem.formJvEpcPjt = e;
        break;
      default:
        break;
    }
    setCurTableItem(newItem);
  };

  const handleFormationTGCChange = (e, type, formType) => {
    const newItem = { ...curTableItem };
    const testNil = e === null || e === undefined || e === '';
    switch (true) {
      case type === 'TGC_FEED' && formType === 'E':
        newItem.formTgcFeedE = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'P':
        newItem.formTgcFeedP = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'C':
        newItem.formTgcFeedC = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_FEED' && formType === 'PJT':
        newItem.formTgcFeedPjt = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'E':
        newItem.formTgcEpcE = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'P':
        newItem.formTgcEpcP = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'C':
        newItem.formTgcEpcC = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      case type === 'TGC_EPC' && formType === 'PJT':
        newItem.formTgcEpcPjt = testNil ? null : `Toyo-J:${e}|Toyo-I:${100 - e}`;
        break;
      default:
        break;
    }
    setCurTableItem(newItem);
  };

  const formationTableCols: ColumnsType<any> = [
    {
      dataIndex: 'formType',
      key: 'formType',
      align: 'center',
      width: 50,
    },
    {
      title: 'FEED',
      dataIndex: 'FEED',
      key: 'FEED',
      children: [
        {
          title: 'JV',
          dataIndex: 'jvFeed',
          key: 'jvFeed',
          width: 100,
          align: 'center',
          render(val, record) {
            const type = 'JV_FEED';
            const { isFeedActive: feedActive } = record;
            return (
              <InputNumber
                className="project-number right"
                disabled={!feedActive || !tableCanOperation || record.formType === 'P'}
                min={0}
                max={100}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                value={val}
                onChange={(e) => handleFormationJVChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-J',
          dataIndex: 'tgcFeedJ',
          key: 'tgcFeedJ',
          width: 220,
          align: 'center',
          render(val, record) {
            const type = 'TGC_FEED';
            const { isFeedActive: feedActive } = record;
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled={!feedActive || !tableCanOperation || record.formType === 'P'}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                onChange={(e) => handleFormationTGCChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-I',
          dataIndex: 'tgcFeedI',
          key: 'tgcFeedI',
          width: 220,
          align: 'center',
          render(val) {
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
              />
            );
          },
        },
      ],
    },
    {
      title: 'EPC',
      dataIndex: 'EPC',
      key: 'EPC',
      children: [
        {
          title: 'JV',
          dataIndex: 'jvEpc',
          key: 'jvEpc',
          width: 100,
          align: 'center',
          render(val, record) {
            const type = 'JV_EPC';
            const { isEpcActive: epcActive } = record;
            return (
              <InputNumber
                className="project-number right"
                disabled={!epcActive || !tableCanOperation || record.formType === 'P'}
                min={0}
                max={100}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                value={val}
                onChange={(e) => handleFormationJVChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-J',
          dataIndex: 'tgcEpcJ',
          key: 'tgcEpcJ',
          width: 220,
          align: 'center',
          render(val, record) {
            const type = 'TGC_EPC';
            const { isEpcActive: epcActive } = record;
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                disabled={!epcActive || !tableCanOperation || record.formType === 'P'}
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                onChange={(e) => handleFormationTGCChange(e, type, record.formType)}
              />
            );
          },
        },
        {
          title: 'TGC-Toyo-I',
          dataIndex: 'tgcEpcI',
          key: 'tgcEpcI',
          width: 220,
          align: 'center',
          render(val) {
            return (
              <InputNumber
                className="project-number right"
                min={0}
                max={100}
                value={val}
                controls
                formatter={(value: number | string) => {
                  if (value === '' || value === null || value === undefined) {
                    return '';
                  }
                  return `${value}%`;
                }}
                parser={(value) => value.replace('%', '')}
                disabled
              />
            );
          },
        },
      ],
    },
  ];

  const demandForecastAction = (item) => {
    setTableLoading(true);
    setKpiLoadingStatus(true);
    APIList.demandForecastAction()
      .post({
        projectId: item.projectId,
        snapshotVersion,
        snapshot,
      })
      .then((res) => {
        const { sessionId } = res as any;
        let timeoutTimer = setTimeout(async () => {
          if (timer) {
            clearInterval(timer);
            timer = null;
            message.error('Connection timeout.');
            setTableLoading(false);
            setKpiLoadingStatus(false);
          }
        }, timeout);
        let timer = setInterval(() => {
          APIList.getSimulationStatus()
            .get({ sessionId })
            .then(async (r: any) => {
              if (r.status === 'forecastDone') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                getChartData();
                await initializeTableData();
                setTableLoading(false);
                message.success(t('aipcmcty.page.updateScheduleProjectId').replace('${item.projectId}', item.projectId));
                console.log('forecastDone');
              } else if (r.status === 'forecastError') {
                if (timer) {
                  clearInterval(timer);
                  timer = null;
                }
                if (timeoutTimer) {
                  clearTimeout(timeoutTimer);
                  timeoutTimer = null;
                }
                setTableLoading(false);
                setKpiLoadingStatus(false);
                message.error('Forecast Failed.');
                console.log('forecastError');
              }
            })
            .catch(() => {
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = null;
              }
              setTableLoading(false);
              setKpiLoadingStatus(false);
              message.error('Forecast Failed.');
              console.log('forecastError');
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        message.error('Connecting timeout.');
        setTableLoading(false);
        setKpiLoadingStatus(false);
      });
  };

  // about table
  const handleTableChange = (elem: string, value: any, id: string) => {
    const newTableData = [...tableDataNeedFilter];
    const item = newTableData.find((t) => t.id === id);
    item[elem] = value;
    setTableDataNeedFilter(newTableData);
  };

  const filteredTableData = useMemo(() => {
    const newTableData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method ? tData.filter((t) => method(t, filtersData[cur], cur, filtersData)) : tData;
      },
      [...listNeedRender]
    );
    let dxItems = [];
    if (dxOn) {
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      dxItems = orgMart.filter((o) => o.budgetCategoryCustom.startsWith('DX_'));
    }
    return newTableData
      .map((d, i) => {
        if (i === 0) {
          d.orderAccumulation = d.orderAmount;
          d.profitAccumulation = d.grossProfit;
        } else {
          d.orderAccumulation = d.orderAmount + newTableData[i - 1].orderAccumulation;
          d.profitAccumulation = d.grossProfit + newTableData[i - 1].profitAccumulation;
        }
        if (dxOn) {
          const dxGrossProfit = dxItems.filter((dx) => d.projectId === dx.projectId).reduce((pr, c) => pr + c.grossProfit, 0);
          d.dxGrossProfit = Number(dxGrossProfit.toFixed(0));
        }
        return { ...d };
      })
      .sort((a, b) => a.priorityDefault - b.priorityDefault);
  }, [filtersData, listNeedRender]);

  useEffect(() => {
    setSelectedProjects(
      tableDataNeedFilter.filter(
        (d) =>
          d.budgetCategoryCustom === 'Awarded' ||
          (d.budgetCategoryCustom === 'Budget' && !unselectBudgets.map((u) => u.projectId).includes(d.projectId)) ||
          selectedProjects.map((s) => s.projectId).includes(d.projectId)
      )
    );
  }, [tableDataNeedFilter]);

  // about modal
  const [curModalItem, setCurModalItem] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // about chart
  const { kpiInfos, initKpiData, getKpiData, setKpiLoadingStatus, filterKpiData, orgKpiInfos, removeOrgKpiData } = useContext(AipcmctyContext);
  const [kpiOrder, setKpiOrder] = useState(initialKpiOrder);

  const [resSimCacheData, setResSimCacheData] = useState<any>({});
  useEffect(() => {
    const resSim = getKpiData('ResourceSimulation')?.data;
    if (resSim) {
      const { projectDemand, projectIds } = resSim;
      const pids = convertToJSON(projectIds);
      const pds = convertToJSON(projectDemand);
      const { tgc } = filtersData;
      const ids = pids.filter((p) => !tgc.length || tgc.includes(p.tgc));
      const dms = pds.filter((p) => !tgc.length || tgc.includes(p.tgc));
      setResSimCacheData({
        projectDemand: dms,
        resSimIds: ids,
      });
    }
  }, [kpiInfos, filtersData.tgc]);

  const combineGraphIdsAndTgcs = useMemo(() => {
    const resSim = getKpiData('ResourceSimulation')?.data;
    const orderGProfit = getKpiData('OrderGrossProfitResource')?.data;
    const gProfit = getKpiData('GrossProfitResource')?.data;
    const { tgc } = filtersData;
    let resSimData = [];
    let orderGProfitData = [];
    let gProfitData = [];

    if (resSim) {
      const { projectIds } = resSim;
      const pids = convertToJSON(projectIds);
      const filteredData = pids.filter((p) => !tgc.length || tgc.includes(p.tgc)).map((p) => p.projectId);
      resSimData = filteredData.reduce((pr, id) => {
        const tgcItems = pids.filter((p) => p.projectId === id).map((p) => p.tgc);
        pr[id] = tgcItems;
        return pr;
      }, {} as any);
    }
    if (orderGProfit) {
      const { mart } = orderGProfit;
      const orgMart = getOrgKpiData('OrderGrossProfitResource')?.data?.mart ?? [];
      const martIds = mart.map(m => m.projectId);
      const idTgcs = orgMart.reduce((pr, c) => {
        if (
          pr[c.projectId]
          && !pr[c.projectId].includes(c.tgc)
        ) {
          pr[c.projectId].push(c.tgc);
        } else {
          pr[c.projectId] = [c.tgc];
        }
        return pr;
      }, {} as any);
      orderGProfitData = martIds.reduce((pr, c) => {
        pr[c] = idTgcs[c];
        return pr;
      }, {} as any);
    }
    if (gProfit) {
      const { mart } = gProfit;
      const orgMart = getOrgKpiData('GrossProfitResource')?.data?.mart ?? [];
      const martIds = mart.map(m => m.projectId);
      const idTgcs = orgMart.reduce((pr, c) => {
        if (
          pr[c.projectId]
          && !pr[c.projectId].includes(c.tgc)
        ) {
          pr[c.projectId].push(c.tgc);
        } else {
          pr[c.projectId] = [c.tgc];
        }
        return pr;
      }, {} as any);
      gProfitData = martIds.reduce((pr, c) => {
        pr[c] = idTgcs[c];
        return pr;
      }, {} as any);
    }

    return Object.keys({
      ...resSimData,
      ...orderGProfitData,
      ...gProfitData,
    }).reduce((pr, id) => {
      const rTgc = resSimData[id] ?? [];
      const oTgc = orderGProfitData[id] ?? [];
      const gTgc = gProfitData[id] ?? [];
      const mergedTgc = Array.from(new Set([...rTgc, ...oTgc, ...gTgc]));
      pr[id] = mergedTgc;
      return pr;
    }, {} as any);
  }, [kpiInfos, filtersData.tgc]);

  useEffect(() => {
    const {
      demandYearMonth,
      mhDepartment: [div, dis],
    } = filtersData;
    if (!demandYearMonth && div === 'All' && dis === 'All') {
      const combineGraphIds = Object.keys(combineGraphIdsAndTgcs);
      const listRendering = tableDataNeedFilter.filter((t) => combineGraphIds.includes(t.projectId));
      listRendering.forEach((l) => {
        const mh = (resSimCacheData.resSimIds || []).filter((r) => r.projectId === l.projectId).reduce((pr, c) => pr + c.MhPeak, 0);
        const tgc = combineGraphIdsAndTgcs[l.projectId] ?? [];
        l.monthDemandMH = Number(mh.toFixed(0));
        l.relatedTgc = tgc.length ? tgc.sort().join(', ') : null;
      });
      setListNeedRender(listRendering);
    }
    initialKpiOrder;
  }, [combineGraphIdsAndTgcs, filtersData.mhDepartment, filtersData.demandYearMonth, tableDataNeedFilter, resSimCacheData]);

  const changeRenderList = async ({ demandYearMonth, mhDepartment }: { demandYearMonth?: string; mhDepartment?: [string, string] }) => {
    if (!resSimCacheData.projectDemand) {
      return;
    }
    const [div, dis] = mhDepartment;
    const { tgc } = filtersData;
    if (!demandYearMonth && div === 'All' && dis === 'All') {
      return;
    }
    // select year month or div/dis
    const getDemandInfo = async () => {
      let year = '';
      let month = '';
      if (demandYearMonth) {
        [year, month] = demandYearMonth?.split('-');
      }
      setTableLoading(true);
      const demandProjectIds = await APIList.getDemandProjects().get({
        snapshot,
        snapshotVersion,
        year,
        month,
        division: div === 'All' ? '' : div,
        discipline: dis === 'All' ? '' : dis,
        tgc: tgc.join(','),
      });
      const { resSimIds, projectDemand } = resSimCacheData;
      const groupedProjectDemand = groupBy(projectDemand, (x) => x.projectId);
      const budgetStep2 = Object.keys(groupedProjectDemand).reduce((prev, cur) => {
        demandYearMonthRange.forEach((ym) => {
          const list = groupedProjectDemand[cur].filter((g) => g.yearMonth === ym);
          const total = list.reduce((pr, c) => pr + (c.resourceDemandMH ?? 0), 0);
          if (prev[cur]) {
            prev[cur][ym] = total;
          } else {
            prev[cur] = {
              [ym]: total,
            };
          }
        });
        return prev;
      }, {} as any);
      const gAllData = Object.entries(budgetStep2).map(([k, v]) => ({
        projectId: k,
        mh: Number(
          demandYearMonth
            ? v[demandYearMonth].toFixed(0)
            : Object.values(v)
                .reduce((pr, c) => pr + c, 0)
                .toFixed(0)
        ),
      }));
      const orgSimIds = convertToJSON(getOrgKpiData('ResourceSimulation').data.projectIds);
      const tgcGroupById = resSimIds.reduce((pr, c) => {
        const { projectId } = c;
        const tgcs = orgSimIds.filter((o) => o.projectId === projectId).map((o) => o.tgc);
        pr[projectId] = Array.from(new Set(tgcs));
        return pr;
      }, {} as any);
      const projects = tableDataNeedFilter
        .filter((s) => demandProjectIds.includes(s.projectId))
        .map((s) => {
          const mh = gAllData.find((a) => a.projectId === s.projectId)?.mh;
          const tgc = tgcGroupById[s.projectId] ?? [];
          return {
            ...s,
            monthDemandMH: mh,
            relatedTgc: tgc.length ? tgc.sort().join(', ') : null,
          };
        });
      setListNeedRender(projects);
      setTableLoading(false);
      setMenuCollapsed(true);
      setFilterCollapsed(false);
    };
    await getDemandInfo();
  };

  useEffect(() => {
    changeRenderList({
      demandYearMonth: filtersData.demandYearMonth,
      mhDepartment: filtersData.mhDepartment as [string, string],
    });
  }, [filtersData.tgc, tableDataNeedFilter]);

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const {
    tgc,
    demandYearMonth,
    mhDepartment,
    department
  } = filtersData;

  const kpiMapping = useMemo(() => {
    console.log('kpi mapping render');
    return {
      33: (
        <ResourceSimulationChart
          kpiCode="ResourceSimulation"
          data={getKpiData('ResourceSimulation').data}
          loading={getKpiData('ResourceSimulation').loading}
          projects={selectedProjects}
          unselectBudgets={unselectBudgets}
          tgcs={tgc}
          height={330}
          yearMonthRange={demandYearMonthRange}
          curYearMonth={demandYearMonth}
          onDemandSelect={handleDemandProjects}
          setMHDepartmentOpts={setMHDepartmentOpts}
          selectedDivDis={mhDepartment}
          isDxMode={dxOn}
          department={department}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resSimulation.mhForecast'), color.warningColor)}
        />
      ),
      35: (
        <TurnoverResource
          consolidated={consolidated}
          height={330}
          kpiCode="OrderGrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('OrderGrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('OrderGrossProfitResource').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderGrossProfit'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={department}
        />
      ),
      36: (
        <TurnoverResource
          consolidated={consolidated}
          height={330}
          kpiCode="GrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('GrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('GrossProfitResource').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            `${t('aipcmcty.page.grossProfit')}(${t('aipcmcty.page.resSimulation.rebound')})`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={department}
        />
      ),
      37: (
        <TurnoverFlowChart
          consolidated={consolidated}
          height={330}
          kpiCode="OrderGrossProfitResource"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('OrderGrossProfitResource').data}
          selectedProjects={selectedProjects}
          unselectBudgets={unselectBudgets}
          loading={getKpiData('OrderGrossProfitResource').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderGrossProfit'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          isDxMode={dxOn}
          department={department}
          onOrderYearMonthChange={handleOrderScheduledDateChange}
          orderYearMonths={orderYearMonths}
          onOrderFYChange={handleOrderFYChange}
          selectedFiscalYear={orderFiscalYear}
        />
      ),
    };
  }, [
    kpiInfos,
    tgc,
    demandYearMonth,
    mhDepartment,
    department,
    periodSwitch,
    selectedProjects,
    dxOn,
    consolidated,
    orderFiscalYear[0]
  ]);

  useEffect(() => {
    console.log('filter change');
    setTimeout(() => {
      filterKpiData({
        tgc: filtersData.tgc,
        japanInvolved: filtersData.japanInvolved,
        consolidated,
        tgcCanEmpty: true,
        departments: {
          kpiCode: 'OrderGrossProfitResource',
          items: filtersData.department
        }
      });
    }, 0);
  }, [orgKpiInfos, filtersData.tgc, filtersData.japanInvolved, filtersData.department]);

  const getChartData = () => {
    initKpiData([['OrderGrossProfitResource', 'GrossProfitResource'], ['ResourceSimulation']], !!consolidated, {
      isDXMode: dxOn,
      division: filtersData.mhDepartment[0] === 'All' ? '' : filtersData.mhDepartment[0],
      discipline: filtersData.mhDepartment[1] === 'All' ? '' : filtersData.mhDepartment[1],
    });
  };

  // confirm select and unselect modal
  const confirmTableColumns = [
    {
      title: t('aipcmcty.page.projectCaseId'),
      key: 'projectId',
      dataIndex: 'projectId',
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      key: 'projectName',
      dataIndex: 'projectName',
    },
    {
      title: `${t('aipcmcty.page.budgetCategory')} ${t('aipcmcty.page.resSimulation.before')}`,
      key: 'bccBefore',
      dataIndex: 'bccBefore',
    },
    {
      title: `${t('aipcmcty.page.budgetCategory')} ${t('aipcmcty.page.resSimulation.after')}`,
      key: 'bccAfter',
      dataIndex: 'bccAfter',
    },
  ];
  const [confirmUpdateModal, setConfirmUpdateModel] = useState(false);
  const [confirmSelectIds, setConfirmSelectIds] = useState([]);
  const confirmTableData = useMemo(() => {
    const asBudgets = selectedProjects
      .filter((s) => ['IF', 'Others'].includes(s.budgetCategoryCustom))
      .map((p) => {
        return {
          projectId: p.projectId,
          projectName: p.projectName,
          bccBefore: p.budgetCategoryCustom,
          bccAfter: 'Budget',
        };
      });
    const asIFs = unselectBudgets.map((u) => {
      return {
        projectId: u.projectId,
        projectName: u.projectName,
        bccBefore: u.budgetCategoryCustom,
        bccAfter: 'IF',
      };
    });
    return [...asBudgets, ...asIFs];
  }, [selectedProjects, unselectBudgets]);
  const handleConfirmModal = () => {
    setConfirmUpdateModel(true);
    setConfirmSelectIds(confirmTableData.map((c) => c.projectId));
  };

  const handleConfirmTableSelect = (item: any, selected: boolean) => {
    if (selected) {
      setConfirmSelectIds([...confirmSelectIds, item.projectId]);
      return;
    }
    setConfirmSelectIds(confirmSelectIds.filter((c) => c !== item.projectId));
  };

  const handleCTableSelectAll = (selected: boolean, selectedRows: any[]) => {
    if (selected) {
      setConfirmSelectIds(selectedRows.map((s) => s.projectId));
      return;
    }
    setConfirmSelectIds([]);
  };

  const handleConfirmModalOK = async () => {
    setConfirmUpdateModel(false);
    await handleDemandUpdate();
    setConfirmSelectIds([]);
  };

  const handleConfirmModalCancel = () => {
    setConfirmUpdateModel(false);
    setConfirmSelectIds([]);
  };

  const handleDemandUpdate = async () => {
    const demandProjects = confirmTableData.filter((c) => confirmSelectIds.includes(c.projectId));
    setTableLoading(true);
    const updateStr = await APIList.updateDemandProjects().post({
      snapshot,
      snapshotVersion,
      demandProjects,
    });
    if (updateStr === 'SUCCESS') {
      setKpiLoadingStatus(true);
      const isUpdateSuccess = await APIList.budgetCategoryUpdate().post({
        mode: '10',
        snapshot,
        snapshotVersion,
      });
      if (isUpdateSuccess) {
        getChartData();
        await initializeTableData();
        message.success('予算カテゴリの意思入れ値が正常に保存されました。');
      }
      setTableLoading(false);
    } else {
      message.error('ERROR');
      setTableLoading(false);
    }
  };

  const hasNotValueChanged = useMemo(() => {
    return unselectBudgets.length === 0 && !selectedProjects.some((s) => ['IF', 'Others'].includes(s.budgetCategoryCustom));
  }, [selectedProjects, unselectBudgets]);
  usePageLeaveConfirm(!hasNotValueChanged, canLeave, setCanLeave, () => confirm('Are you sure leaving this page without save?'));

  useEffect(() => {
    return () => removeOrgKpiData();
  }, []);

  return (
    <ProjectSetupContext.Provider
      value={{
        handleTableChange,
        setIsModalOpen,
        setMenuCollapsed,
        setFilterCollapsed,
        setFormationModalOpen,
        setCurTableItem,
        optData,
        setCurModalItem,
        calcFeedActive,
        setCachedFormation,
        filtersData,
        accountingPerspective,
        accountingPerspectiveOpt,
        imbalancedMhOpt,
        priorityMatchingOpt
      }}
    >
      <div className="dashboard project-setup resource-regulation" style={{ height: 'calc(100vh - 85px)' }}>
        <div
          style={{
            marginRight: filterCollapsed ? 0 : drawerOpts.maxWidth,
          }}
        >
          <Row justify="space-between" className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px' }}>
            <Col style={{ padding: '5px 0' }}>
              <Space>
                {`${t('aipcmcty.page.resSimulation.useCase')}:`}
                <Select
                  style={{ width: 200 }}
                  value={useCase}
                  options={useCaseOpts}
                  onChange={(v) => {
                    handleUseCaseChange(v);
                  }}
                />
                {`${t('aipcmcty.page.accountingPerspective')}:`}
                <Select
                  style={{ width: 200 }}
                  value={accountingPerspective}
                  options={accountingPerspectiveOpt}
                  onChange={(v) => {
                    handleAccountPerspectiveChange(v);
                  }}
                />
                TGC:
                <Select
                  allowClear
                  style={{ width: 180 }}
                  value={filtersData.tgc}
                  mode="multiple"
                  maxTagCount="responsive"
                  options={optData?.tgc}
                  onChange={(vs) => {
                    handleFilterChange('tgc', vs);
                  }}
                />
                本部:
                <Tooltip title="会計観点: Toyo-J連結+TGC: Toyo-Jのみ指定可">
                  <Select
                    allowClear
                    style={{ width: 200 }}
                    value={filtersData.department}
                    mode="multiple"
                    maxTagCount="responsive"
                    disabled={accountingPerspective !== 3 || filtersData.tgc.length !== 1 || filtersData.tgc[0] !== 'Toyo-J'}
                    options={departmentOpts}
                    onChange={(vs) => {
                      handleFilterChange('department', vs);
                    }}
                  />
                </Tooltip>
                DX:
                <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={dxOn} onChange={(e) => setDxOn(e)} />
              </Space>
            </Col>
            <Col style={{ padding: '5px 0' }}>
              <Space>
                <Button
                  onClick={() => {
                    setMenuCollapsed(filterCollapsed);
                    setFilterCollapsed(!filterCollapsed);
                  }}
                >
                  <BarsOutlined />
                </Button>
              </Space>
            </Col>
          </Row>
          <ChartTableLayout viewMode={viewMode}>
            {kpiMapping ? (
              <ChartTableLayout.Chart>
                <Row className="chart-group-container">
                  {kpiOrder.map((kpi) => (
                    <Col span={kpi === 33 ? 10 : 7} key={`chartTable-${kpi}`}>
                      <Card style={{ height: 342 }} className="chart-card">
                        {kpiMapping[kpi]}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </ChartTableLayout.Chart>
            ) : (
              <></>
            )}
            <ChartTableLayout.Table>
              <ResourceRegulationTable
                isDxMode={dxOn}
                data={filteredTableData}
                loading={tableLoading}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                tableColsToShow={tableColsToShow}
                needDisable={tableCanOperation}
                selectedProjects={selectedProjects}
                onSelected={handleTableRowSelected}
                demandMonth={filtersData.demandYearMonth}
                selectedDivDis={filtersData.mhDepartment as [string, string]}
                onDemandUpdate={handleConfirmModal}
                consolidated={consolidated}
                viewMode={viewMode}
              />
            </ChartTableLayout.Table>
          </ChartTableLayout>
        </div>
        <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
          {!filterCollapsed && <Tabs type="card" size="small" style={{ height: '100%' }} items={tabs} />}
        </DrawerContainer>
      </div>
      <Modal
        className="aipcmc"
        title={`${curModalItem?.projectId} ${curModalItem?.projectName} ${t('aipcmcty.page.projectDemandSupplyList')}`}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setCurModalItem(null);
        }}
        footer={null}
        width="90%"
        styles={{
          body: {
            height: document.body.clientHeight * 0.8,
          },
        }}
        maskClosable={false}
        centered
      >
        <ProjectSetupDetail project={curModalItem} />
      </Modal>
      <Modal
        className="formation-table"
        title="Formation Table"
        open={isFormationModalOpen}
        okText={t('aipcmcty.page.save')}
        cancelText={t('aipcmcty.page.cancel')}
        onCancel={() => {
          setFormationModalOpen(false);
          setCurTableItem(null);
          setCachedFormation(null);
        }}
        onOk={() => {
          if (!tableCanOperation) {
            setFormationModalOpen(false);
            setCurTableItem(null);
            setCachedFormation(null);
            return;
          }
          const diff = Object.keys(cachedFormation).reduce((acc, cur) => {
            if (cachedFormation[cur] !== curTableItem[cur]) {
              acc.push({
                projectId: curTableItem.projectId,
                field: cur,
                type: 'FORMATION',
                before: cachedFormation[cur],
                after: curTableItem[cur],
                snapshot,
                snapshotVersion,
                createdBy: user,
                projectUuid: curTableItem.id,
                hasEffimate: curTableItem.demandForecastType?.includes('effimate'),
              });
            }
            return acc;
          }, [] as RecordItem[]);
          setFormationTableLoading(true);
          APIList.demandForecast()
            .put({ ...curTableItem, snapshot, snapshotVersion })
            .then(() => {
              const itemIndex = tableDataNeedFilter.findIndex((t) => t.id === curTableItem.id);
              setTableDataNeedFilter([
                ...tableDataNeedFilter.slice(0, itemIndex),
                curTableItem,
                ...tableDataNeedFilter.slice(itemIndex + 1),
              ]);
              setFormationTableLoading(false);
              setFormationModalOpen(false);
              setCurTableItem(null);
              APIList.postOperationHistory().post({ histories: diff });
              setCachedFormation(null);
              message.success(t('aipcmcty.page.updateFormationProjectId').replace('${curTableItem.projectId}', curTableItem.projectId));
              demandForecastAction(curTableItem);
            })
            .finally(() => {
              setFormationTableLoading(false);
            });
        }}
        width="50%"
        maskClosable={false}
      >
        <div style={{ padding: '0 12px 12px' }}>
          <b>{`${t('aipcmcty.page.projectCaseId')}: `}</b>
          {curTableItem?.projectId}
        </div>
        <div style={{ padding: '0 12px 12px' }}>
          <b>{`${t('aipcmcty.page.projectCaseName')}:`}</b> {curTableItem?.projectName}
        </div>
        <Table
          className="project-setup-form"
          columns={formationTableCols}
          dataSource={formationTableData}
          rowKey="index"
          pagination={false}
          size="small"
          loading={formationTableLoading}
        />
      </Modal>
      <Modal
        title={t('aipcmcty.page.resSimulation.confirmUpdateProject')}
        open={confirmUpdateModal}
        onOk={handleConfirmModalOK}
        onCancel={handleConfirmModalCancel}
        width="80%"
        wrapClassName="res-sim-modal"
        okButtonProps={{
          disabled: !confirmSelectIds.length,
        }}
      >
        <Table
          className="res-sim-table"
          dataSource={confirmTableData}
          columns={confirmTableColumns}
          size="small"
          pagination={false}
          rowKey="projectId"
          rowSelection={{
            selectedRowKeys: confirmSelectIds,
            onSelect: handleConfirmTableSelect,
            onSelectAll: handleCTableSelectAll,
          }}
          rowClassName={(record) => {
            if (confirmSelectIds.includes(record.projectId)) {
              return 'light-selected';
            }
            return '';
          }}
        />
      </Modal>
    </ProjectSetupContext.Provider>
  );
};

export default ResourceRegulation;
