import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space, Table } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty, isNil, isNumber, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import APIList from '../../http/ApiList';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TRINITY_CONST } from '../../consts/trinity-const';

const TrinityComparisonPage: React.FC = () => {
  // config
  const { t } = useTranslation();
  const { snapshot: nowSnapshot, snapshotVersion: nowVersion, versionList, comment: nowSVComment } = useContext(AipcmctyContext);

  const { selectorTop } = useWindowSize({
    selector: '.trinity-comparison-table-area',
  });

  // filterOptions
  const [filterOptions, setFilterOptions] = useState({
    fy: [],
    tableNo: [
      { value: 1, label: t('aipcmcty.page.trinityComparison.tableName1') },
      { value: 2, label: t('aipcmcty.page.trinityComparison.tableName2') },
    ],
    snapshot: [],
    snapshotVersion: [],
  });

  // filter
  const [filterForm] = Form.useForm();
  const [listForm] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // snapshot snapshotVersion legend table
  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState({
    tableSource: [],
    tableColumns: [],
  });

  const [trinityTableData1, setTrinityTableData1] = useState({
    tableSource: [],
    tableColumns: [],
  });

  const [tableShowNo, setTableShowNo] = useState(1);
  const [dataLoading, setDataLoading] = useState(true);

  const columnOptions = TRINITY_CONST['SELECT_FOR_TABLE_COLUMN'];
  const [columnList, setColumnList] = useState(['a', 'b']);

  let responseData = [];

  /**
   * 絞り込み条件の列表の初期化
   * @param param0
   */
  const initFilterOptions = ({ ayOptions }) => {
    setFilterOptions((prev) => ({
      ...prev,
      fy: ayOptions,
    }));
    filterForm.setFieldsValue({
      fiscalYears: ['FY24'],
      dxOn: true,
      tableNo: 1,
    });
    getTableData();
  };

  /**
   * 初期化
   */
  useEffect(() => {
    setDataLoading(true);
    // snapshotとversionの列表を取得
    initSVOptions();
    // 会計年度とTGCの列表を取得
    Promise.all([
      APIList.getCmcOptions().get({
        category: 'accountingYear',
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
      }),
      APIList.getCmcOptions().get({
        category: 'tgc',
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
      }),
    ]).then(([ayOptions, tgcOptions]) => {
      initFilterOptions({ ayOptions });
    });
  }, [nowSnapshot, nowVersion]);

  /**
   * snapshotとversionの列表を取得
   */
  const initSVOptions = () => {
    // const list = versionList.filter(item => !(item.snapshot === nowSnapshot && item.snapshotVersion === nowVersion));
    const snapshot = uniqBy(versionList, 'snapshot')
      .map((item: any) => ({ key: item.snapshot, label: item.snapshot, value: item.snapshot }))
      .sort((a, b) => (b.value > a.value ? 1 : -1));
    const snapshotVersion = versionList.map((item: any) => ({
      key: item.snapshotVersion,
      label: item.snapshotVersionName,
      value: item.snapshotVersion,
      snapshot: item.snapshot,
      snapshotVersionName: item.snapshotVersionName,
      comment: item.comment,
      tag: item.tag,
    }));
    setFilterOptions((prev) => ({ ...prev, snapshot, snapshotVersion }));
    let showList = [];
    let localSnapshotList = localStorage.getItem('snapshot-comparison');
    if (!localSnapshotList && !JSON.parse(localSnapshotList)) {
      const findNo1 = versionList.find((item) => item.snapshot === '2024-02-28' && item.snapshotVersion === 'default');
      const findNo2 = versionList.find((item) => item.snapshot === '2024-05-09' && item.snapshotVersion === 'default');
      const findNo3 = versionList.find((item) => item.snapshot === '2024-08-14' && item.snapshotVersion === 'default');
      const findNo4 = versionList.find((item) => item.snapshot === '2024-11-08' && item.snapshotVersion === 'default');
      showList = [findNo1, findNo2, findNo3, findNo4];
    } else {
      showList = JSON.parse(localSnapshotList);
    }
    setListFormValues(showList);
    setTableDataHandler(showList);
  };

  /**
   * snapshot versionのlistフォームをセット
   * @param list
   */
  const setListFormValues = (list) => {
    list.forEach((item, index) => {
      if (!item) {
        item = {};
      }
      item.defaultShow = `No.${index + 1}`;
    });
    listForm.setFieldsValue({ list });
  };

  /**
   * snapshot versionのlistフォームを取得
   * @returns
   */
  const getListFormValues = () => listForm.getFieldValue('list');

  /** tool function */
  const diffCompute = (value1, value2) => {
    if (isNumber(value1) && isNumber(value2)) {
      return value1 - value2;
    } else {
      return '-';
    }
  };
  const computeRate = (targetArray) => {
    if (!isNumber(targetArray.at(-2)) || !isNumber(targetArray.at(-1)) || targetArray.at(-2) === 0) {
      return '-';
    } else {
      return ((targetArray.at(-1) / targetArray.at(-2)) * 100).toFixed(1) + '%';
    }
  };
  /**
   * 設定データ value | -
   * @param rowData
   * @param formatData
   * @param index
   */
  const setRowData = (rowData, formatData, index) => {
    if (index !== -1) {
      rowData.push(formatData[index].orderAmount ?? '-');
      rowData.push(formatData[index].orderGrossProfit ?? '-');
      rowData.push(computeRate(rowData));
      rowData.push(formatData[index].grossProfit ?? '-');
      rowData.push(formatData[index].demandMh ?? '-');
      rowData.push(formatData[index].addedValue ?? '-');
      rowData.push(formatData[index].addedValuePerMd ?? '-');
      // formatData.splice(index, 1);
    } else {
      rowData.push('-');
      rowData.push('-');
      rowData.push('-');
      rowData.push('-');
      rowData.push('-');
      rowData.push('-');
      rowData.push('-');
    }
  };

  // テーブル１のデータrow format
  const getMockData1 = (request) => {
    let response: (string | number)[][] = [];
    const { snapshotVersions, fiscalYears, tableTag, res } = request;
    const table1KeyMaps = TRINITY_CONST['TABLE_DATA_MAPPING_KEY1'];

    let resultData = res;
    let snapshotList = request.snapshotVersions;
    let formatData = cloneDeep(resultData);
    let snapSortArray = snapshotList.map((sl) => {
      return { snapshot: sl.snapshot, snapshotVersion: sl.snapshotVersion, tag: sl.tag };
    });
    snapSortArray = snapSortArray
      .slice(0, -1)
      .sort((a, b) => {
        return a.snapshot > b.snapshot ? 1 : -1;
      })
      .concat(snapSortArray.at(-1));
    const nearestTagIndex = snapSortArray.length - snapSortArray.findLastIndex((s) => !isNil(s.tag));
    let tableData = table1KeyMaps.map((t1) => {
      let rowData: (string | number)[] = [];
      snapSortArray.forEach((sa) => {
        let index = formatData.findIndex((fd) => {
          return t1.key === fd.key && sa.snapshot === fd.snapshot && sa.snapshotVersion === fd.snapshotVersion;
        });
        setRowData(rowData, formatData, index);
      });
      let snapColumnLen = rowData.length / snapSortArray.length;
      let tmpRowData = [...rowData];
      for (let i = 0; i < snapColumnLen; i++) {
        if (i === 2) {
          tmpRowData.push(computeRate(tmpRowData));
        } else {
          tmpRowData.push(diffCompute(rowData.at(0 - snapColumnLen + i), rowData.at(0 - snapColumnLen * 2 + i)));
        }
      }
      for (let i = 0; i < snapColumnLen; i++) {
        if (i === 2) {
          tmpRowData.push(computeRate(tmpRowData));
        } else {
          tmpRowData.push(diffCompute(rowData.at(0 - snapColumnLen + i), rowData.at(0 - snapColumnLen * nearestTagIndex + i)));
        }
      }
      return tmpRowData;
    });
    let homeTotal: number[] = [];
    let ATotal: number[] = [];
    let BTotal: number[] = [];
    let ABTotal: number[] = [];
    let ABCTotal: number[] = [];
    const setGrossRate = (thisRowData) => {
      thisRowData.forEach((trd, i) => {
        if (i % (thisRowData.length / (snapshotVersions.length + 2)) === 2) {
          if (!isNumber(thisRowData[i - 2]) || !isNumber(thisRowData[i - 1]) || thisRowData[i - 2] === 0) {
            thisRowData[i] = '-';
          } else {
            thisRowData[i] = ((thisRowData[i - 1] / thisRowData[i - 2]) * 100).toFixed(1) + '%';
          }
        }
      });
    };
    tableData.forEach((t, i) => {
      if (i === 0) {
        homeTotal = new Array(t.length).fill(0);
        BTotal = new Array(t.length).fill(0);
        ABTotal = new Array(t.length).fill(0);
        ABCTotal = new Array(t.length).fill(0);
      }
      // 本部
      if (i < 3) {
        t.forEach((value, j) => {
          homeTotal[j] = homeTotal[j] + (isNumber(value) ? value : 0);
        });
      }
      if (i === 3) {
        tableData[i] = [...homeTotal];
        setGrossRate(tableData[i]);
        ATotal = [...homeTotal];
      }
      // Toyo-J
      if (i === 4) {
        t.forEach((value, j) => {
          ATotal[j] = ATotal[j] + (isNumber(value) ? value : 0);
        });
      }
      if (i === 5) {
        t.forEach((value, j) => {
          if (j < t.length - 14) {
            ATotal[j] = isNumber(value) ? value : ATotal[j];
          } else if (j < t.length - 7) {
            let diffValue = diffCompute(ATotal[j - 7], ATotal[j - 14]);
            if (diffValue !== '-') ATotal[j] = diffValue as number;
          } else {
            let diffValue = diffCompute(ATotal[j - 14], ATotal[j - (nearestTagIndex + 1) * 7]);
            if (diffValue !== '-') ATotal[j] = diffValue as number;
          }
        });
        tableData[i] = [...ATotal];
        setGrossRate(tableData[i]);
        ABTotal = [...ATotal];
      }
      // B合計
      if (i > 5 && i < 13) {
        t.forEach((value, j) => {
          BTotal[j] = BTotal[j] + (isNumber(value) ? value : 0);
        });
      }
      if (i === 13) {
        tableData[i] = [...BTotal];
        setGrossRate(tableData[i]);
        BTotal.forEach((value, j) => {
          ABTotal[j] = ABTotal[j] + (isNumber(value) ? value : 0);
        });
      }
      //A+B
      if (i === 14) {
        tableData[i] = [...ABTotal];
        setGrossRate(tableData[i]);
        ABCTotal = [...ABTotal];
      }
      // ABC合計
      if (i === 15) {
        t.forEach((value, j) => {
          ABCTotal[j] = ABCTotal[j] + (isNumber(value) ? value : 0);
        });
      }
      if (i === 16) {
        tableData[i] = [...ABCTotal];
        setGrossRate(tableData[i]);
      }
    });
    response = tableData;
    return response;
  };

  // テーブル2のデータrow format
  const getMockData2 = (request) => {
    let response: (string | number)[][] = [];
    const { snapshotVersions, fiscalYears, tableTag, res } = request;
    const table2KeyMaps1 = TRINITY_CONST['TABLE_DATA_MAPPING_KEY2'][0];
    const table2KeyMaps2 = TRINITY_CONST['TABLE_DATA_MAPPING_KEY2'][0];
    const table2KeyMaps3 = TRINITY_CONST['TABLE_DATA_MAPPING_KEY2'][1];
    const formatAreaData = (keyMap, snapArray, targetData) => {
      const nearestTagIndex = snapSortArray.length - snapSortArray.findLastIndex((s) => !isNil(s.tag));
      let tableData = keyMap.map((t1) => {
        let rowData: (string | number)[] = [];
        snapArray.forEach((sa) => {
          let index = targetData.findIndex((fd) => {
            return t1.key === fd.key && sa.snapshot === fd.snapshot && sa.snapshotVersion === fd.snapshotVersion;
          });
          setRowData(rowData, targetData, index);
        });
        let snapColumnLen = rowData.length / snapSortArray.length;
        let tmpRowData = [...rowData];
        for (let i = 0; i < snapColumnLen; i++) {
          if (i === 2) {
            tmpRowData.push(computeRate(tmpRowData));
          } else {
            tmpRowData.push(diffCompute(rowData.at(0 - snapColumnLen + i), rowData.at(0 - snapColumnLen * 2 + i)));
          }
        }
        for (let i = 0; i < snapColumnLen; i++) {
          if (i === 2) {
            tmpRowData.push(computeRate(tmpRowData));
          } else {
            tmpRowData.push(diffCompute(rowData.at(0 - snapColumnLen + i), rowData.at(0 - snapColumnLen * nearestTagIndex + i)));
          }
        }
        return tmpRowData;
      });
      let totalRow = [];
      tableData[0].forEach((columnData, ci) => {
        let columnTotalValue: string | number = 0;
        if (ci % (tableData[0].length / (snapshotVersions.length + 2)) === 2) {
          if (!isNumber(totalRow[ci - 2]) || !isNumber(totalRow[ci - 1]) || totalRow[ci - 2] === 0) {
            columnTotalValue = '-';
          } else {
            columnTotalValue = ((totalRow[ci - 1] / totalRow[ci - 2]) * 100).toFixed(1) + '%';
          }
        } else {
          tableData.forEach((td, i) => {
            columnTotalValue = Number(columnTotalValue) + (isNumber(td[ci]) ? td[ci] : 0);
          });
        }
        totalRow.push(columnTotalValue);
      });
      tableData.push(totalRow);
      return tableData;
    };

    let resultData = res;
    let snapshotList = request.snapshotVersions;
    let snapSortArray = snapshotList.map((sl) => {
      return { snapshot: sl.snapshot, snapshotVersion: sl.snapshotVersion, tag: sl.tag };
    });
    snapSortArray = snapSortArray
      .slice(0, -1)
      .sort((a, b) => {
        return a.snapshot > b.snapshot ? 1 : -1;
      })
      .concat(snapSortArray.at(-1));

    let formatData = [...resultData['area1']];
    let areaData1 = formatAreaData(table2KeyMaps1, snapSortArray, formatData);
    formatData = [...resultData['area2']];
    let areaData2 = formatAreaData(table2KeyMaps2, snapSortArray, formatData);

    let ABAreaData = areaData1.map((ad1, i) => {
      return ad1.map((columnV, j) => {
        if (j % (ad1.length / (snapshotVersions.length + 2)) === 2) {
          if (!isNumber(ad1[j - 2]) || !isNumber(areaData2[i][j - 2]) || !isNumber(ad1[j - 1]) || !isNumber(areaData2[i][j - 1]))
            return '-';
          if (!(ad1[j - 2] + areaData2[i][j - 2]) || ad1[j - 2] + areaData2[i][j - 2] === '-' || ad1[j - 2] + areaData2[i][j - 2] === 0) {
            return '-';
          } else {
            return (((ad1[j - 1] + areaData2[i][j - 1]) / (ad1[j - 2] + areaData2[i][j - 2])) * 100).toFixed(1) + '%';
          }
        } else {
          if (!isNumber(columnV) || !isNumber(areaData2[i][j])) return '-';
          return columnV + areaData2[i][j];
        }
      });
    });
    formatData = [...resultData['area3']];
    let areaData3 = formatAreaData(table2KeyMaps3, snapSortArray, formatData);
    let ABCAreaData = ABAreaData.at(-1).map((ab, abi) => {
      if (abi % (ABAreaData[0].length / (snapshotVersions.length + 2)) === 2) {
        return ABAreaData.at(-1)[abi - 2] === 0 ? 0 : ((ABAreaData.at(-1)[abi - 1] / ABAreaData.at(-1)[abi - 2]) * 100).toFixed(1) + '%';
      } else {
        if (!isNumber(ab) || !isNumber(areaData3.at(-1)[abi])) return '-';
        return ab + areaData3.at(-1)[abi];
      }
    });
    response = areaData1.concat(areaData2, ABAreaData, areaData3, [ABCAreaData]);
    return response;
  };

  // テーブルのcolumn設定
  const formatColumns = (dataRow0, snapshotVersions) => {
    const setValue = (v, base) => {
      if (v === 0) {
        return 0;
      } else if (!v || v === '-') {
        return '-';
      } else {
        return isNumber(v) ? Math.round(v / base).toLocaleString() : v;
      }
    };
    let tableChildrenItemsTmp = [
      {
        title: '受注金額(百万円)',
        width: 130,
        dataIndex: '',
        className: 'children-left',
        key: 'a',
        hidden: !columnList.includes('a'),
        render: (value, record, index) => {
          return setValue(value, 1000000);
        },
      },
      {
        title: '受注粗利(百万円)',
        width: 130,
        dataIndex: '',
        className: 'children-left',
        key: 'b',
        hidden: !columnList.includes('b'),
        render: (value, record, index) => {
          return setValue(value, 1000000);
        },
      },
      {
        title: '粗利率',
        width: 80,
        dataIndex: '',
        className: 'children-left',
        key: 'c',
        hidden: !columnList.includes('c'),
        render: (value, record, index) => {
          return setValue(value, 1);
        },
      },
      {
        title: '跳ね返り粗利(百万円)',
        width: 160,
        dataIndex: '',
        className: 'children-left',
        key: 'd',
        hidden: !columnList.includes('d'),
        render: (value, record, index) => {
          return setValue(value, 1000000);
        },
      },
      {
        title: '需要MH',
        width: 100,
        dataIndex: '',
        className: 'children-left',
        key: 'e',
        hidden: !columnList.includes('e'),
        render: (value, record, index) => {
          return setValue(value, 1);
        },
      },
      {
        title: '付加価値(百万円)',
        width: 140,
        dataIndex: '',
        className: 'children-left',
        key: 'f',
        hidden: !columnList.includes('f'),
        render: (value, record, index) => {
          return setValue(value, 1000000);
        },
      },
      {
        title: 'MDあたり付加価値(円)',
        width: 180,
        minWidth: 180,
        dataIndex: '',
        key: 'g',
        hidden: !columnList.includes('g'),
        render: (value, record, index) => {
          return setValue(value, 1);
        },
      },
    ];
    let childrenCount = dataRow0.length / (snapshotVersions.length + 2);
    let sortVersions = snapshotVersions
      .slice(0, -1)
      .sort((a, b) => (a.snapshot > b.snapshot ? 1 : -1))
      .concat(snapshotVersions.at(-1));
    const tagIndex = sortVersions.findLastIndex((s) => !isNil(s.tag));
    const tag = sortVersions[tagIndex]?.tag === 0 ? '原' : '修';
    let titleCurse = ['a', 'b', 'c', 'd', 'e'];
    let columns = sortVersions.map((sn, si) => {
      let temColumn = {
        title: `${sn.snapshot}\n[${titleCurse[si]}]`,
        className: 'title-warp children-left-border',
        children: [],
      };
      let childrenItems = cloneDeep(tableChildrenItemsTmp);
      temColumn.children = childrenItems;
      for (let i = 0; i < childrenCount; i++) {
        // childrenItems[i].key = sn.snapshot + sn.snapshotVersion + 'ck' + i;
        childrenItems[i].dataIndex = sn.snapshot + sn.snapshotVersion + 'ck' + i;
      }
      return temColumn;
    });
    let diffColumn = {
      title: `差異\n[${titleCurse[columns.length - 1]}] - [${titleCurse[columns.length - 2]}]`,
      className: 'title-warp children-left-border',
    };
    diffColumn['children'] = cloneDeep(tableChildrenItemsTmp);
    for (let i = 0; i < childrenCount; i++) {
      // diffColumn['children'][i].key = 'diffck' + i;
      diffColumn['children'][i].dataIndex = 'diffck' + i;
    }
    let tagDiffColumn = {
      title: `差異\n[${titleCurse[columns.length - 1]}] - [${titleCurse[tagIndex]}${tag}]`,
      className: 'title-warp children-left-border',
    };
    tagDiffColumn['children'] = cloneDeep(tableChildrenItemsTmp);
    for (let i = 0; i < childrenCount; i++) {
      // diffColumn['children'][i].key = 'diffck' + i;
      tagDiffColumn['children'][i].dataIndex = 'tagdiffck' + i;
    }
    columns.push(diffColumn, tagDiffColumn);
    let newColumn = setTrBorderLeft(columns);
    return newColumn;
  };

  const setTrBorderLeft = (columns) => {
    return columns.map((item) => {
      if (item.fixed) {
        return item;
      } else {
        return {
          ...item,
          children: item.children.map((ci, index) => {
            columnList.sort();
            if (ci.key === columnList.at(-1)) {
              return {
                ...ci,
                className: 'children-left-border',
                hidden: !columnList.includes(ci.key as string),
              };
            }
            return {
              ...ci,
              className: 'children-left',
              hidden: !columnList.includes(ci.key as string),
            };
          }),
        };
      }
    });
  };

  useEffect(() => {
    let columns = trinityTableData1.tableColumns;
    let newColumn = setTrBorderLeft(columns);
    setTrinityTableData1({ tableSource: trinityTableData1.tableSource, tableColumns: newColumn });
  }, [columnList]);

  /**
   * APIから、テーブルデータを取得
   */
  const getTableData = () => {
    setDataLoading(true);
    const snapshotVersions = [
      ...listForm.getFieldValue('list').map((item) => ({
        snapshot: item.snapshot,
        snapshotVersion: item.snapshotVersion,
        tag: item.tag,
      })),
      {
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
        tag: null,
      },
    ];
    let { fiscalYears } = filterForm.getFieldsValue();
    if (fiscalYears) {
      fiscalYears = [fiscalYears];
    } else {
      // setDataLoading(false);
      return;
    }

    APIList.getTrinityEvaluationCompare()
      .post({
        snapshotVersions,
        fiscalYears,
        tableTag: tableShowNo,
      })
      .then((res) => {
        if (tableShowNo === 1) {
          let mockData = getMockData1({
            snapshotVersions,
            fiscalYears,
            tableTag: tableShowNo,
            res: res,
          });

          responseData = mockData;

          formatTable1(formatColumns(mockData[0], snapshotVersions));
        } else {
          let mockData = getMockData2({
            snapshotVersions,
            fiscalYears,
            tableTag: tableShowNo,
            res: res,
          });

          responseData = mockData;

          formatTable2(formatColumns(mockData[0], snapshotVersions));
        }
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  /**
   * snapshotVersionの列表を取得
   * @param index
   * @returns
   */
  const getVersionOptions = (index) => {
    if (index === undefined) {
      return [];
    }
    let options = filterOptions.snapshotVersion.filter((item) => {
      const infoList = getListFormValues();
      const versions = infoList
        .filter((info) => info.snapshot === infoList[index]?.snapshot && !(info.snapshotVersion === infoList[index]?.snapshotVersion))
        .map((info) => info.snapshotVersion);
      return infoList[index]?.snapshot === item.snapshot && !versions.includes(item.value);
    });
    let targetIndex = options.findIndex((o) => {
      return o.key === 'default';
    });
    if (targetIndex !== -1) {
      let sortedOption = [options[targetIndex]];
      options.splice(targetIndex, 1);
      options = sortedOption.concat(options);
    }
    return options;
  };

  /**
   * テーブルデータをセット
   * @param list
   */
  const setTableDataHandler = (list) => {
    const snapshotVersion = versionList.find((sv) => sv.snapshot === nowSnapshot && sv.snapshotVersion === nowVersion);
    const snapshotItem = {
      category: 'Snapshot',
      now: snapshotVersion?.snapshot,
    };
    const versionItem = {
      category: 'Version',
      now: snapshotVersion?.snapshotVersionName,
    };
    const tableSource = {
      category: '',
      now: `${nowSnapshot} ${nowSVComment ? `: ${nowSVComment}` : ``}`,
      children: [snapshotItem, versionItem],
    };
    list.forEach((item) => {
      snapshotItem[item.defaultShow] = item.snapshot;
      versionItem[item.defaultShow] = item.snapshotVersionName;
      tableSource[item.defaultShow] = `${item.defaultShow} ${item.comment ? `: ${item.comment}` : ``}`;
    });
    setTableData({
      tableColumns: [
        {
          key: 'category',
          title: '',
          dataIndex: 'category',
          width: 180,
        },
        ...list.map((item) => ({
          key: item.defaultShow,
          title: item.defaultShow,
          dataIndex: item.defaultShow,
          ellipsis: true,
        })),
        {
          key: 'now',
          title: nowVersion,
          dataIndex: 'now',
        },
      ],
      tableSource: [tableSource],
    });
  };

  /**
   * snapshot versionの切り替える操作
   * @param changedFields
   * @returns
   */
  const snapshotChangeHandler = (changedFields) => {
    if (changedFields.length === 0) {
      return;
    }
    const [name, index, key] = changedFields[0].name as any[];
    const infoList = getListFormValues();
    const versionOptions = getVersionOptions(index);
    if (key === 'snapshot') {
      setFilterOptions({ ...filterOptions });
      infoList[index] = {
        ...infoList[index],
        snapshot: infoList[index]?.snapshot,
        snapshotVersion: versionOptions[0]?.value,
        snapshotVersionName: versionOptions[0]?.snapshotVersionName,
        comment: versionOptions[0]?.comment,
        tag: versionOptions[0]?.tag,
      };
    }
    if (key === 'snapshotVersion') {
      const option = versionOptions.find((sv) => sv.value === changedFields[0].value);
      infoList[index] = {
        ...infoList[index],
        snapshotVersionName: option?.snapshotVersionName,
        comment: option?.comment,
        tag: option?.tag,
      };
    }
    setListFormValues(infoList);
  };

  const saveListToLocalStorage = (list) => {
    if (!isEmpty(list)) {
      localStorage.setItem('snapshot-comparison', JSON.stringify(list));
    }
  };

  /**
   * snapshot versionを比較
   */
  const snapshotVersionCompareHandler = () => {
    const list = listForm.getFieldValue('list') || [];
    saveListToLocalStorage(list);
    setIsModalOpen(false);
    setTableDataHandler(list);
    getTableData();
  };

  const getChildrenColumn = (p) => {
    let childrenDataIndex = [];
    if (p['children']) {
      p['children'].forEach((item) => {
        childrenDataIndex = childrenDataIndex.concat(getChildrenColumn(item));
      });
    } else {
      childrenDataIndex.push(p.dataIndex);
    }
    return childrenDataIndex;
  };

  const getData = (rowIndex, columnIndex) => {
    if (responseData && responseData[rowIndex] && responseData[rowIndex][columnIndex]) {
      return responseData[rowIndex][columnIndex];
    } else if (responseData && responseData[rowIndex] && responseData[rowIndex][columnIndex] === 0) {
      return 0;
    }
    return '-';
  };

  const reFormatData = (rows, columns, staticColumn) => {
    let tableData = rows.map((r, i) => {
      let rowData: any = {};
      let columnIndex = 0;
      columns.forEach((c, j) => {
        getChildrenColumn(c).forEach((di, z) => {
          if (!di) {
            return;
          }
          if (j < staticColumn) {
            rowData[di] = r[j + z];
          } else {
            rowData[di] = getData(i, columnIndex);
            columnIndex += 1;
          }
        });
      });
      return rowData;
    });
    return tableData;
  };

  const formatTable1 = (dynamicColumn) => {
    const tableColumn1 = [
      {
        title: '',
        width: 160,
        fixed: 'left',
        children: [
          {
            title: 'ビジネスユニット',
            width: 160,
            dataIndex: 'rowName',
            key: 'rowName',
            fixed: 'left',
          },
        ],
      },
    ];
    let newTableColumn = tableColumn1.concat(dynamicColumn);
    const rowNameList1 = TRINITY_CONST['TABLE_ROW_NAME1'];
    setTrinityTableData1({ tableSource: [...reFormatData(rowNameList1, newTableColumn, 1)], tableColumns: [...newTableColumn] });
    setDataLoading(false);
  };

  const formatTable2 = (dynamicColumn) => {
    const tableColumn3 = [
      {
        title: '',
        dataIndex: 'rowName1',
        key: 'rowName1',
        align: 'center',
        width: 100,
        fixed: 'left',
        className: 'title-warp',
        onCell: (record, index) => {
          let rowSpan = 0;
          if (index === 0 || index === 9 || index === 18) {
            rowSpan = 9;
          } else if (index === 27) {
            rowSpan = 3;
          } else if (index === 30) {
            rowSpan = 1;
          }
          return { rowSpan };
          // rowSpan: record.id === 0 ? 6 : record.id === 6 ? 6 : 0,
        },
      },
      {
        title: 'ビジネスユニット',
        width: 160,
        fixed: 'left',
        align: 'left',
        dataIndex: 'rowName2',
        key: 'rowName2',
      },
    ];
    let newTableColumn = tableColumn3.concat(dynamicColumn);
    const rowNameList2 = TRINITY_CONST['TABLE_ROW_NAME2'];

    setTrinityTableData1({ tableSource: [...reFormatData(rowNameList2, newTableColumn, 2)], tableColumns: [...newTableColumn] });
  };

  useEffect(() => {
    getTableData();
  }, [tableShowNo]);

  return (
    <div>
      <div className="select-group-container flex-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px 18px 0',
            justifyContent: 'space-between',
            width: 'calc(100%)',
          }}
        >
          <Form form={filterForm} style={{ padding: '0 8px' }} name="filters" autoComplete="off">
            <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} align="baseline">
              <Space style={{ display: 'flex' }} align="baseline">
                <Form.Item name="fiscalYears" label={t('aipcmcty.page.trinityEvaluation.fiscalYears')} style={{ marginBottom: 12 }}>
                  <Select
                    className="select-height-32"
                    style={{ width: 220 }}
                    maxTagCount={1}
                    maxTagTextLength={6}
                    options={filterOptions.fy}
                  />
                </Form.Item>
                <Form.Item name="tableNo" label={t('aipcmcty.page.trinityComparison.tableNo')} style={{ marginBottom: 12 }}>
                  <Select
                    className="select-height-32"
                    style={{ width: 220 }}
                    maxTagCount={1}
                    maxTagTextLength={6}
                    onChange={(e) => {
                      setTableShowNo(e);
                    }}
                    options={filterOptions.tableNo}
                  />
                </Form.Item>
                比較KPI :
                <Select
                  mode="multiple"
                  className="select-height-32"
                  style={{ width: 220 }}
                  maxTagCount={1}
                  maxTagTextLength={6}
                  value={columnList}
                  onChange={(e) => {
                    setColumnList([...e]);
                  }}
                  options={columnOptions}
                />
                <Form.Item style={{ marginBottom: 12 }}>
                  <Button
                    disabled={dataLoading}
                    type="dashed"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('aipcmcty.page.trinityComparison.compareVersionAdd')}
                  </Button>
                </Form.Item>
              </Space>
              <Space style={{ display: 'flex', flex: 1 }} align="baseline">
                <Form.Item style={{ marginBottom: 12 }}>
                  <Button
                    disabled={dataLoading}
                    type="primary"
                    onClick={() => {
                      getTableData();
                    }}
                  >
                    {t('aipcmcty.page.trinityComparison.compare')}
                  </Button>
                </Form.Item>
              </Space>
            </Space>
          </Form>
        </div>
      </div>
      <div className="trinity-evaluation">
        <Table
          showHeader={false}
          className="legend-table"
          bordered
          size="small"
          pagination={false}
          columns={tableData.tableColumns}
          dataSource={tableData.tableSource}
          expandable={{
            onExpand: (expanded, record) => {
              setExpanded(expanded);
            },
          }}
        />
      </div>
      <div className="trinity-comparison-table-area">
        {tableShowNo === 1 ? (
          <Table
            bordered
            className="trinity-comparison-table"
            rowClassName={(record, index, indent) => {
              let classStr = '';
              if (
                record.rowName === '[A] Toyo-J(協業)合計' ||
                record.rowName === '[B]拠点(独自)合計' ||
                record.rowName === '[A + B]連結合計' ||
                record.rowName === '[A + B + C]'
              ) {
                classStr = classStr + 'count-row ';
              }
              if (index === 5 || index === 14 || index === 15) {
                classStr = classStr + 'bottom-line ';
              }
              return classStr;
            }}
            loading={dataLoading}
            scroll={{ y: document.body.clientHeight - selectorTop - 120 - (expanded ? 70 : 0) }}
            size="small"
            pagination={false}
            columns={trinityTableData1.tableColumns}
            dataSource={trinityTableData1.tableSource}
          ></Table>
        ) : (
          <></>
        )}
        {tableShowNo === 2 ? (
          <Table
            bordered
            className="trinity-comparison-table"
            rowClassName={(record, index, indent) => {
              let classStr = '';
              if (index === 8 || index === 17 || index === 26 || index === 30) {
                classStr = classStr + 'count-row ';
              }
              if (index === 7 || index === 16 || index === 25 || index === 29) {
                classStr = classStr + 'bottom-line ';
              }
              return classStr;
            }}
            size="small"
            loading={dataLoading}
            scroll={{ y: document.body.clientHeight - selectorTop - 120 - (expanded ? 70 : 0) }}
            pagination={false}
            columns={trinityTableData1.tableColumns}
            dataSource={trinityTableData1.tableSource}
          ></Table>
        ) : (
          <></>
        )}
      </div>
      <Modal
        width={650}
        title={t('aipcmcty.page.trinityComparison.compareVersionAdd')}
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <Form
          name="snapshotVersions"
          form={listForm}
          onFieldsChange={(changedFields, allFields) => {
            snapshotChangeHandler(changedFields);
          }}
        >
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item hidden {...restField} name={[name, 'defaultShow']} style={{ marginBottom: 12 }}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('aipcmcty.page.trinityComparison.compareSnapshot')}
                      name={[name, 'snapshot']}
                      style={{ marginBottom: 12 }}
                    >
                      <Select style={{ width: 150 }} options={filterOptions.snapshot} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('aipcmcty.page.trinityComparison.compareVersion')}
                      name={[name, 'snapshotVersion']}
                      style={{ marginBottom: 12 }}
                    >
                      <Select style={{ width: 150 }} options={getVersionOptions(name)} />
                    </Form.Item>
                    <Form.Item hidden {...restField} name={[name, 'snapshotVersionName']} style={{ marginBottom: 12 }}>
                      <Input />
                    </Form.Item>
                    <Form.Item hidden {...restField} name={[name, 'comment']} style={{ marginBottom: 12 }}>
                      <Input />
                    </Form.Item>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name);
                          setListFormValues(
                            getListFormValues().map((item, index) => ({
                              ...item,
                            }))
                          );
                        }}
                      />
                    )}
                  </Space>
                ))}
                {fields.length < 4 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ width: 510 }}
                      onClick={() => {
                        add();
                        setListFormValues(
                          getListFormValues().map((item, index) => ({
                            ...item,
                          }))
                        );
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item style={{ marginTop: 30 }} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                snapshotVersionCompareHandler();
              }}
            >
              {t('aipcmcty.page.trinityComparison.compare')}
            </Button>
            <Button
              htmlType="button"
              style={{ margin: '0 8px' }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              {t('aipcmcty.page.cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TrinityComparisonPage;
