import { ReactNode, useContext, useEffect, useState } from 'react';
import { cloneDeep, isNil } from 'lodash';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';
import { TableChart } from '@meteor/frontend-core';
import { useTranslation } from 'react-i18next';

type DXDiffChartProps = {
  title: ReactNode;
  loading: boolean;
  graphLoading: boolean;
  graphHeight: number;
  data: any[];
};

const keysNeedFilter = ['planLatest', 'forecastActualSum', 'forecastSum'];
const latestPlan = ['planLatestPreSale', 'planLatestProposal', 'planLatestExecution'];
const actualAndPredict = ['forecastPreSale', 'forecastProposal', 'forecastExecution', 'actual'];

const keyColorMapping = {
  plan01: '#093B5E',
  plan02: '#195d87',
  planLatestPreSale: '#9fd9f6',
  planLatestProposal: '#3299d9',
  planLatestExecution: '#619bc5',
  forecastPreSale: '#b0cedb',
  forecastProposal: '#619bc5',
  forecastExecution: '#195d87',
  actual: '#093B5E',
};

const DXDiffChart = (props: DXDiffChartProps) => {
  const { t } = useTranslation();
  const { sizeMode } = useContext(AppContext);
  const { sizeMapBase, paramData, setParamData } = useContext(AipcmctyContext);
  const { data = {}, title = '', loading = false, graphLoading = false, graphHeight = 340 } = props;

  const keyLabelMappings = {
    plan01: t('aipcmcty.page.dxDiffAnalyze.plan01'),
    plan02: t('aipcmcty.page.dxDiffAnalyze.plan02'),
    planLatest: t('aipcmcty.page.dxDiffAnalyze.planLatest'),
    planLatestPreSale: t('aipcmcty.page.dxDiffAnalyze.dxPreSale'),
    planLatestProposal: t('aipcmcty.page.dxDiffAnalyze.dxProposal'),
    planLatestExecution: t('aipcmcty.page.dxDiffAnalyze.dxExecution'),
    forecastActualSum: t('aipcmcty.page.dxDiffAnalyze.forecastAndActual'),
    forecastSum: t('aipcmcty.page.dxDiffAnalyze.forecast'),
    forecastPreSale: t('aipcmcty.page.dxDiffAnalyze.chargePreSale'),
    forecastProposal: t('aipcmcty.page.dxDiffAnalyze.chargeProposal'),
    forecastExecution: t('aipcmcty.page.dxDiffAnalyze.chargeExecution'),
    actual: t('aipcmcty.page.dxDiffAnalyze.chargeActual'),
  };
  const categoryMappings = {
    allowance: t('aipcmcty.page.dxDiffAnalyze.dxAllowance'),
    billing: t('aipcmcty.page.dxDiffAnalyze.dxCharge'),
  };

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
      xAxis: 10,
      yAxis: 10,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const [dxDiffOptions, setDxDiffOptions] = useState({
    title: {
      value: title,
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        position: (point) => ({
          top: point[1] > 220 ? 220 : point[1],
          left: point[0],
        }),
        appendToBody: true,
        trigger: 'axis',
        formatter(params) {
          let axisValue = '';
          const temp = params.reduce((prev, curP) => {
            const key = Object.entries(keyLabelMappings).find(([k, v]) => curP.seriesName.includes(v))?.[0];
            if (!key) {
              return prev;
            }
            let precessKey = key;
            if (curP.seriesName.includes(`${t('aipcmcty.page.paramter')}1`)) {
              precessKey = `${key}1`;
            }
            if (curP.seriesName.includes(`${t('aipcmcty.page.paramter')}2`)) {
              precessKey = `${key}2`;
            }
            if (curP.seriesName.includes(`${t('aipcmcty.page.paramter')}3`)) {
              precessKey = `${key}3`;
            }
            prev[precessKey] = {
              marker: curP.marker,
              value: Number((curP.value / 1000000).toFixed(0)),
              seriesName: curP.seriesName
                .replace(`(${t('aipcmcty.page.paramter')}1)`, '')
                .replace(`(${t('aipcmcty.page.paramter')}2)`, '')
                .replace(`(${t('aipcmcty.page.paramter')}3)`, ''),
              axisValue: curP.axisValue,
              color: curP.color,
            };
            axisValue = curP.axisValue;
            return prev;
          }, {} as any);
          const latestPlanPadding = temp.planLatestPreSale && temp.planLatestProposal && temp.planLatestExecution ? 14 : 0;
          const predictPadding = temp.forecastPreSale && temp.forecastProposal && temp.forecastExecution ? 14 : 0;
          let predictLeavesPadding = 0;
          if (temp.forecastPreSale && temp.forecastProposal && temp.forecastExecution && temp.actual) {
            predictLeavesPadding = 28;
          } else if (temp.forecastPreSale && temp.forecastProposal && temp.forecastExecution && !temp.actual) {
            predictLeavesPadding = 14;
          }
          let template = `
            <h4 style="display: flex; justify-content: space-between;">
              <span>${axisValue}</span>
              <span>${t('aipcmcty.page.dxDiffAnalyze.unit')}</span>
            </h4>
            <table>
          `;
          if (!isNil(temp.plan01)) {
            template += `
              <tr>
                <td>${temp.plan01.marker}${temp.plan01.seriesName}</td>
                <td style="text-align: right; padding-left: 20px;">${temp.plan01.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.plan02)) {
            template += `
              <tr>
                <td>${temp.plan02.marker}${temp.plan02.seriesName}</td>
                <td style="text-align: right; padding-left: 20px;">${temp.plan02.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestPreSale1) && !isNil(temp.planLatestProposal1) && !isNil(temp.planLatestExecution1)) {
            template += `
            <tr>
              <td>${t('aipcmcty.page.dxDiffAnalyze.planLatest')}(${t('aipcmcty.page.paramter')}1)</td>
              <td style="text-align: right; padding-left: 20px;">
                ${(temp.planLatestPreSale1.value + temp.planLatestProposal1.value + temp.planLatestExecution1.value).toLocaleString()}
              </td>
            </tr>
            `;
          }
          if (!isNil(temp.planLatestPreSale1)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestPreSale1.marker}${
              temp.planLatestPreSale1.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestPreSale1.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestProposal1)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestProposal1.marker}${
              temp.planLatestProposal1.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestProposal1.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestExecution1)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestExecution1.marker}${
              temp.planLatestExecution1.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestExecution1.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestPreSale2) && !isNil(temp.planLatestProposal2) && !isNil(temp.planLatestExecution2)) {
            template += `
            <tr>
              <td>${t('aipcmcty.page.dxDiffAnalyze.planLatest')}(${t('aipcmcty.page.paramter')}2)</td>
              <td style="text-align: right; padding-left: 20px;">
                ${(temp.planLatestPreSale2.value + temp.planLatestProposal2.value + temp.planLatestExecution2.value).toLocaleString()}
              </td>
            </tr>
            `;
          }
          if (!isNil(temp.planLatestPreSale2)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestPreSale2.marker}${
              temp.planLatestPreSale2.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestPreSale2.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestProposal2)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestProposal2.marker}${
              temp.planLatestProposal2.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestProposal2.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestExecution2)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestExecution2.marker}${
              temp.planLatestExecution2.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestExecution2.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestPreSale3) && !isNil(temp.planLatestProposal3) && !isNil(temp.planLatestExecution3)) {
            template += `
            <tr>
              <td>${t('aipcmcty.page.dxDiffAnalyze.planLatest')}(${t('aipcmcty.page.paramter')}3)</td>
              <td style="text-align: right; padding-left: 20px;">
                ${(temp.planLatestPreSale3.value + temp.planLatestProposal3.value + temp.planLatestExecution3.value).toLocaleString()}
              </td>
            </tr>
            `;
          }
          if (!isNil(temp.planLatestPreSale3)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestPreSale3.marker}${
              temp.planLatestPreSale3.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestPreSale3.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestProposal3)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestProposal3.marker}${
              temp.planLatestProposal3.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestProposal3.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.planLatestExecution3)) {
            template += `
              <tr>
                <td style="padding-left: ${latestPlanPadding}px;">${temp.planLatestExecution3.marker}${
              temp.planLatestExecution3.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.planLatestExecution3.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.forecastPreSale) && !isNil(temp.forecastProposal) && !isNil(temp.forecastExecution) && !isNil(temp.actual)) {
            template += `
              <tr>
                <td>${t('aipcmcty.page.dxDiffAnalyze.forecastAndActual')}</td>
                <td style="text-align: right; padding-left: 20px;">
                  ${(
                    temp.forecastPreSale.value +
                    temp.forecastProposal.value +
                    temp.forecastExecution.value +
                    temp.actual.value
                  ).toLocaleString()}
                </td>
              </tr>
            `;
          }
          if (!isNil(temp.forecastPreSale) && !isNil(temp.forecastProposal) && !isNil(temp.forecastExecution)) {
            template += `
              <tr>
                <td style="padding-left: ${predictPadding}px;">${t('aipcmcty.page.dxDiffAnalyze.forecast')}</td>
                <td style="text-align: right; padding-left: 20px;">
                  ${(temp.forecastPreSale.value + temp.forecastProposal.value + temp.forecastExecution.value).toLocaleString()}
                </td>
              </tr>
            `;
          }
          if (!isNil(temp.forecastPreSale)) {
            template += `
              <tr>
                <td style="padding-left: ${predictLeavesPadding}px;">${temp.forecastPreSale.marker}${temp.forecastPreSale.seriesName}</td>
                <td style="text-align: right; padding-left: 20px;">${temp.forecastPreSale.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.forecastProposal)) {
            template += `
              <tr>
                <td style="padding-left: ${predictLeavesPadding}px;">${temp.forecastProposal.marker}${temp.forecastProposal.seriesName}</td>
                <td style="text-align: right; padding-left: 20px;">${temp.forecastProposal.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.forecastExecution)) {
            template += `
              <tr>
                <td style="padding-left: ${predictLeavesPadding}px;">${temp.forecastExecution.marker}${
              temp.forecastExecution.seriesName
            }</td>
                <td style="text-align: right; padding-left: 20px;">${temp.forecastExecution.value.toLocaleString()}</td>
              </tr>
            `;
          }
          if (!isNil(temp.actual)) {
            template += `
              <tr>
                <td style="padding-left: ${predictPadding}px;">${temp.actual.marker}${temp.actual.seriesName}</td>
                <td style="text-align: right; padding-left: 20px;">${temp.actual.value.toLocaleString()}</td>
              </tr>
            `;
          }
          return `${template}</table>`;
        },
      },
      legend: {
        type: 'scroll',
      },
      grid: {
        left: '10%',
        right: '6%',
        bottom: '10%',
      },
      xAxis: {
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: t('aipcmcty.page.billion'),
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value) => (value / 100000000).toLocaleString(),
        },
      },
      series: [],
    },
  });

  const updateDXDiff = () => {
    if (!Reflect.ownKeys(data).length) {
      return;
    }
    const rateDataFunc = (value, sName, param) => {
      if (param) {
        const { progressRate, accumulateRate, cuteEate } = param;
        if (sName === 'planLatestPreSale') {
          return value * progressRate * accumulateRate * cuteEate;
        }
        if (sName === 'planLatestProposal' || sName === 'planLatestExecution') {
          return value * cuteEate;
        }
      }
      return value;
    };
    const combinedData = cloneDeep(dxDiffOptions);
    const temp1 = Object.entries(data).sort((a, b) => (a[0] < b[0] ? -1 : 1));
    combinedData.chartOptions.xAxis.data = temp1.map((t) => categoryMappings[t[0]]);
    const temp = temp1.map((d) =>
      Object.keys(d[1])
        .filter((dK) => !keysNeedFilter.includes(dK))
        .reduce((prev, curK) => {
          prev[curK] = d[1][curK];
          return prev;
        }, {} as any)
    );
    const keys = Object.keys(temp[0]);
    let cachedKey = null;
    let cachedKeyIndex = null;
    keys.forEach((k, i) => {
      if (k.includes('PreSale')) {
        cachedKey = k;
        cachedKeyIndex = i;
      }
      if (k.includes('Execution')) {
        keys[i] = cachedKey;
        keys[cachedKeyIndex] = k;
        cachedKey = null;
        cachedKeyIndex = null;
      }
    });
    const seriesNames = keys.reduce((acc, cur) => {
      if (cur.includes('planLatest')) {
        acc.push(`${cur}_1`);
        acc.push(`${cur}_2`);
        acc.push(`${cur}_3`);
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    const series = seriesNames.map((sName) => {
      let stack;
      const [sNamePre, paramNo] = sName.split('_');
      if (latestPlan.includes(sNamePre)) {
        stack = `planLatest_${paramNo}`;
      }
      if (actualAndPredict.includes(sNamePre)) {
        stack = 'forecastAndActual';
      }
      return {
        type: 'bar',
        name: `${keyLabelMappings[sNamePre]}${paramNo ? `(${t('aipcmcty.page.paramter')}${paramNo})` : ''}`,
        stack,
        data: temp.map((t) => rateDataFunc(t[sNamePre], sNamePre, paramData[`param${paramNo}`])),
        itemStyle: {
          color: keyColorMapping[sNamePre],
        },
        emphasis: {
          focus: 'series',
        },
        paramNo,
      };
    });
    combinedData.chartOptions.series = series;
    setDxDiffOptions(combinedData);
  };

  useEffect(() => {
    updateDXDiff();
  }, [data, paramData]);

  return (
    <TableChart
      showTable={false}
      chartOptions={dxDiffOptions.chartOptions}
      title={
        title
          ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
          : dxDiffOptions.title
      }
      height={graphHeight}
      loading={loading}
      titleLoading={!loading && graphLoading}
    />
  );
};

export default DXDiffChart;
