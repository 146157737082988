import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession } from '@aws-amplify/auth';
import { Loading, APIService, registerAmplify, themeList } from '@meteor/frontend-core';
import i18n from '../locales/i18n';
import jaJP from 'antd/locale/ja_JP';
import enUS from 'antd/locale/en_US';
import 'antd/dist/reset.css';

import '../styles/index.css';
import { AppContext } from '../contexts/AppContext';
import useStorage from '../hooks/useStorage';
import { defaultTheme } from '../consts/default-configuration';
import menuRoutes from '../routes';
import mockList from '../http/APIMock';
import { useTranslation } from 'react-i18next';

registerAmplify(process.env);
APIService.mockApiList = mockList;
APIService.env = process.env;

export default function Root() {
  // common utilities & common hooks
  const { getItem, setItem } = useStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const [authInfo, setAuthInfo] = useState<any>({
    disableAuth: process.env.REACT_APP_DISABLE_AUTH === 'true',
    loading: process.env.REACT_APP_DISABLE_AUTH !== 'true',
    user: null,
    loggedOut: true,
    signOut: async () => {
      setAuthInfo((prev) => ({ ...prev, user: null }));
      await signOut();
      await navigate('/auth/login', { replace: true });
    },
  });

  // language settings
  const selectedLang = getItem('language', 'local') || 'ja';
  const [locale, setLocale] = useState(selectedLang === 'ja' ? jaJP : enUS);
  const { t } = useTranslation();

  // layout loading
  const [contextLoading, setContextLoading] = useState<boolean>(false);

  // setting modal
  const [showSettings, setShowSettings] = useState(false);

  // theme settings
  const [color, setColor] = useState<any>();
  const chartThemeName =
    process.env.REACT_APP_CHART_THEME && Object.keys(themeList).includes(process.env.REACT_APP_CHART_THEME)
      ? process.env.REACT_APP_CHART_THEME
      : 'accenture';

  // const chartThemeName = process.env.REACT_APP_CHART_THEME && registerList.includes(process.env.REACT_APP_CHART_THEME)
  //   ? process.env.REACT_APP_CHART_THEME
  //   : 'accenture';
  themeList[chartThemeName] = {
    color: ['#1A4F99', '#3299D9', '#9FD9F6', '#093B5E', '#619BC5', '#195D87', '#B0CEDB', '#507FA2'],
  };

  const themeName = process.env.REACT_APP_THEME && defaultTheme[process.env.REACT_APP_THEME] ? process.env.REACT_APP_THEME : 'accenture';

  const [configuration, setConfiguration] = useState<any>(defaultTheme[themeName].configuration);

  const [echartTheme, setEchartTheme] = useState<any>(chartThemeName);
  const [customEchartTheme, setCustomEchartTheme] = useState<any>({});
  const [customAntChartTheme, setCustomAntChartTheme] = useState<any>({});

  useEffect(() => {
    const { theme } = defaultTheme[themeName].configuration;
    setColor(defaultTheme[themeName].color[theme]);
  }, []);

  // version management
  const versionInfoStr = getItem('cmcVersion', 'local') ?? '{ "snapshot": "", "snapshotVersion": "", "userId": "", "isShared": true }';
  const [aipcmctyVersion, setAipcmctyVersion] = useState(versionInfoStr);
  const [cmcSelectedVersion, setCmcSelectedVersion] = useState({
    snapshot: '',
    snapshotVersion: '',
    userId: '',
    isShared: true,
  });
  const [versionList, setVersionList] = useState<any[]>([]);

  // header operations
  const [selectedModule, setSelectedModule] = useState<any>(menuRoutes);
  const [chatEvent, setChatEvent] = useState<any>(null);
  const [clearEvent, setClearEvent] = useState<any>(null);
  const [simulationEvent, setSimulationEvent] = useState<any>(null);
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
  const [scrollCount, setScrollCount] = useState(0);
  const [sizeMode, setSizeMode] = useState('small');
  const [canClearCache, setCanClearCache] = useState<any>(true);
  const [chartSelectors, setChartSelectors] = useState([]);

  // 算出設定
  // Budgetの売上の算出方法 0-確度なし 1-確度あり
  const [certainty, setCertainty] = useState(+(getItem('salesCompute', 'local') ?? 0));
  const [demandMhPercent, setDemandMhPercent] = useState(+(getItem('demandMhPercent', 'local') ?? 10));

  // period & fiscal options
  const [periodSwitch, setPeriodSwitch] = useState(false);
  const [defaultFiscalQuarter, setDefaultFiscalQuarter] = useState(['FY24', 'FY25', 'FY26', 'FY27', 'FY28']);
  const [fiscalQuarter, setFiscalQuarter] = useState<string[]>(['FY24', 'FY25', 'FY26', 'FY27', 'FY28']);
  const [periodList, setPeriodList] = useState<any[]>([]);

  useEffect(() => {
    if (periodSwitch) {
      setPeriodList([
        {
          label: 'FY21',
          options: [
            {
              label: 'Q1',
              value: 'FY21-Q1',
              key: 'FY21-Q1',
            },
            {
              label: 'Q2',
              value: 'FY21-Q2',
              key: 'FY21-Q2',
            },
            {
              label: 'Q3',
              value: 'FY21-Q3',
              key: 'FY21-Q3',
            },
            {
              label: 'Q4',
              value: 'FY21-Q4',
              key: 'FY21-Q4',
            },
          ],
        },
        {
          label: 'FY22',
          options: [
            {
              label: 'Q1',
              value: 'FY22-Q1',
              key: 'FY22-Q1',
            },
            {
              label: 'Q2',
              value: 'FY22-Q2',
              key: 'FY22-Q2',
            },
            {
              label: 'Q3',
              value: 'FY22-Q3',
              key: 'FY22-Q3',
            },
            {
              label: 'Q4',
              value: 'FY22-Q4',
              key: 'FY22-Q4',
            },
          ],
        },
        {
          label: 'FY23',
          options: [
            {
              label: 'Q1',
              value: 'FY23-Q1',
              key: 'FY23-Q1',
            },
            {
              label: 'Q2',
              value: 'FY23-Q2',
              key: 'FY23-Q2',
            },
            {
              label: 'Q3',
              value: 'FY23-Q3',
              key: 'FY23-Q3',
            },
            {
              label: 'Q4',
              value: 'FY23-Q4',
              key: 'FY23-Q4',
            },
          ],
        },
        {
          label: 'FY24',
          options: [
            {
              label: 'Q1',
              value: 'FY24-Q1',
              key: 'FY24-Q1',
            },
            {
              label: 'Q2',
              value: 'FY24-Q2',
              key: 'FY24-Q2',
            },
            {
              label: 'Q3',
              value: 'FY24-Q3',
              key: 'FY24-Q3',
            },
            {
              label: 'Q4',
              value: 'FY24-Q4',
              key: 'FY24-Q4',
            },
          ],
        },
        {
          label: 'FY25',
          options: [
            {
              label: 'Q1',
              value: 'FY25-Q1',
              key: 'FY25-Q1',
            },
            {
              label: 'Q2',
              value: 'FY25-Q2',
              key: 'FY25-Q2',
            },
            {
              label: 'Q3',
              value: 'FY25-Q3',
              key: 'FY25-Q3',
            },
            {
              label: 'Q4',
              value: 'FY25-Q4',
              key: 'FY25-Q4',
            },
          ],
        },
        {
          label: 'FY26',
          options: [
            {
              label: 'Q1',
              value: 'FY26-Q1',
              key: 'FY26-Q1',
            },
            {
              label: 'Q2',
              value: 'FY26-Q2',
              key: 'FY26-Q2',
            },
            {
              label: 'Q3',
              value: 'FY26-Q3',
              key: 'FY26-Q3',
            },
            {
              label: 'Q4',
              value: 'FY26-Q4',
              key: 'FY26-Q4',
            },
          ],
        },
        {
          label: 'FY27',
          options: [
            {
              label: 'Q1',
              value: 'FY27-Q1',
              key: 'FY27-Q1',
            },
            {
              label: 'Q2',
              value: 'FY27-Q2',
              key: 'FY27-Q2',
            },
            {
              label: 'Q3',
              value: 'FY27-Q3',
              key: 'FY27-Q3',
            },
            {
              label: 'Q4',
              value: 'FY27-Q4',
              key: 'FY27-Q4',
            },
          ],
        },
        {
          label: 'FY28',
          options: [
            {
              label: 'Q1',
              value: 'FY28-Q1',
              key: 'FY28-Q1',
            },
            {
              label: 'Q2',
              value: 'FY28-Q2',
              key: 'FY28-Q2',
            },
            {
              label: 'Q3',
              value: 'FY28-Q3',
              key: 'FY28-Q3',
            },
            {
              label: 'Q4',
              value: 'FY28-Q4',
              key: 'FY28-Q4',
            },
          ],
        },
      ]);
    } else {
      setPeriodList([
        {
          label: 'FY21',
          value: 'FY21',
          key: 'FY21',
        },
        {
          label: 'FY22',
          value: 'FY22',
          key: 'FY22',
        },
        {
          label: 'FY23',
          value: 'FY23',
          key: 'FY23',
        },
        {
          label: 'FY24',
          value: 'FY24',
          key: 'FY24',
        },
        {
          label: 'FY25',
          value: 'FY25',
          key: 'FY25',
        },
        {
          label: 'FY26',
          value: 'FY26',
          key: 'FY26',
        },
        {
          label: 'FY27',
          value: 'FY27',
          key: 'FY27',
        },
        {
          label: 'FY28',
          value: 'FY28',
          key: 'FY28',
        },
      ]);
    }
    if (periodSwitch) {
      setFiscalQuarter(['FY24-Q1', 'FY24-Q2', 'FY24-Q3', 'FY24-Q4']);
    } else {
      setFiscalQuarter(['FY24', 'FY25', 'FY26', 'FY27', 'FY28']);
    }
  }, [periodSwitch]);

  const setDefaultConfiguration = () => {
    const list: any = {};
    list[themeName] = {
      configuration: defaultTheme[themeName].configuration,
      colors: defaultTheme[themeName].color,
    };
    setItem('currentTheme', themeName, 'local');
    setItem('themeList', JSON.stringify(list), 'local');
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
    let settingsColor: any = null;
    const fontFamily = getItem('fontFamily', 'local');
    if (fontFamily) {
      document.body.style.fontFamily = fontFamily;
    } else {
      setItem(
        'fontFamily',
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        'local'
      );
    }
    const customEchartThemeLocal = getItem('customEchartTheme', 'local');
    if (customEchartThemeLocal && process.env.REACT_APP_FORCE_SETTINGS !== 'true') {
      setCustomEchartTheme(JSON.parse(customEchartThemeLocal));
    }
    const customAntChartThemeLocal = getItem('customAntChartTheme', 'local');
    if (customAntChartThemeLocal && process.env.REACT_APP_FORCE_SETTINGS !== 'true') {
      setCustomAntChartTheme(JSON.parse(customAntChartThemeLocal));
    }
    const currentEchartTheme = getItem('echartTheme', 'local');
    if (currentEchartTheme && process.env.REACT_APP_FORCE_SETTINGS !== 'true') {
      setEchartTheme(currentEchartTheme);
    }
    const currentTheme = getItem('currentTheme', 'local');
    if (currentTheme) {
      const themeString = getItem('themeList', 'local');
      if (themeString) {
        const themeInfo = JSON.parse(themeString);
        if (themeInfo[currentTheme]) {
          const themeConfiguration = themeInfo[currentTheme].configuration;
          if (themeConfiguration) {
            setConfiguration(themeConfiguration);
            const themeColors = themeInfo[currentTheme].colors;
            if (themeColors) {
              const themeColor = themeColors[themeConfiguration.theme];
              if (themeColor) {
                settingsColor = themeColor;
              }
            }
          }
        }
      }
    }
    if (!settingsColor || process.env.REACT_APP_FORCE_SETTINGS === 'true') {
      setDefaultConfiguration();
      const { theme } = defaultTheme[themeName].configuration;
      settingsColor = defaultTheme[themeName].color[theme];
    }
    setColor(settingsColor);
  }, []);

  useEffect(() => {
    if (!authInfo.disableAuth && !authInfo.user && location.pathname !== '/auth/login' && location.pathname !== '/aipcmcty') {
      getCurrentUser()
        .then(async (user) => {
          const tokens = (await fetchAuthSession()).tokens;
          const token = tokens.idToken.toString();
          APIService.baseConfig.headers = { Authorization: `Bearer ${token}` };

          const attributes = await fetchUserAttributes();
          setAuthInfo({
            ...authInfo,
            loading: false,
            loggedOut: false,
            user: { ...user, attributes },
          });
        })
        .catch((error: any) => {
          setAuthInfo({
            ...authInfo,
            loading: false,
            loggedOut: error && error === 'The user is not authenticated',
          });
          navigate('/auth/login');
        });
    } else if (location.pathname === '/auth/login') {
      setAuthInfo({
        ...authInfo,
        loading: false,
      });
    }
  }, [location]);

  const [canLeave, setCanLeave] = useState(true);

  return color ? (
    <AppContext.Provider
      value={{
        authInfo,
        setAuthInfo,
        aipcmctyVersion,
        setAipcmctyVersion,
        cmcSelectedVersion,
        setCmcSelectedVersion,
        versionList,
        setVersionList,
        color,
        setColor,
        sizeMode,
        setSizeMode,
        fiscalQuarter,
        setFiscalQuarter,
        periodSwitch,
        setPeriodSwitch,
        periodList,
        setPeriodList,
        defaultFiscalQuarter,
        setDefaultFiscalQuarter,
        scrollCount,
        setScrollCount,
        menuCollapsed,
        setMenuCollapsed,
        chatEvent,
        setChatEvent,
        clearEvent,
        setClearEvent,
        simulationEvent,
        setSimulationEvent,
        contextLoading,
        setContextLoading,
        canClearCache,
        setCanClearCache,
        chartSelectors,
        setChartSelectors,
        demandMhPercent,
        setDemandMhPercent,
        certainty,
        locale,
        setLocale,
        configuration,
        setConfiguration,
        echartTheme,
        setEchartTheme,
        customEchartTheme,
        setCustomEchartTheme,
        customAntChartTheme,
        setCustomAntChartTheme,
        themeName,
        showSettings,
        setShowSettings,
        canLeave,
        setCanLeave,
        setCertainty
      }}
    >
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: color.primaryColor,
            colorSuccess: color.successColor,
            colorWarning: color.warningColor,
            colorError: color.errorColor,
            colorInfo: color.infoColor,
            colorLink: color.primaryColor,
            borderRadius: 4,
          },
        }}
        prefixCls={'cmcty'}
      >
        {authInfo.loading ? (
          <Loading />
        ) : (
            <div className="aip-cmc" key={locale.locale}>
            <Outlet />
          </div>
        )}
      </ConfigProvider>
    </AppContext.Provider>
  ) : (
    <Loading />
  );
}
