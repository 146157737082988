export const TRINITY_CONST: {} = {
  TABLE_ROW_NAME1: [
    ['　　CN'],
    ['　　海外営業'],
    ['　　国内営業'],
    ['営業統括本部'],
    ['OFS本部'],
    ['[A] Toyo-J(協業)合計'],
    ['Toyo-India'],
    ['Toyo-Korea'],
    ['Toyo-Malaysia'],
    ['Toyo-China'],
    ['IKPT'],
    ['TPS'],
    ['TSPI'],
    ['[B]拠点(独自)合計'],
    ['[A + B]連結合計'],
    ['[C] OFS'],
    ['[A + B + C]'],
  ],
  TABLE_ROW_NAME2: [
    ['[A]\n独自案件', 'Toyo-Japan'],
    ['[A]\n独自案件', 'Toyo-India'],
    ['[A]\n独自案件', 'Toyo-Korea'],
    ['[A]\n独自案件', 'Toyo-Malaysia'],
    ['[A]\n独自案件', 'Toyo-China'],
    ['[A]\n独自案件', 'IKPT'],
    ['[A]\n独自案件', 'TPS'],
    ['[A]\n独自案件', 'TSPI'],
    ['[A]\n独自案件', '[A] 独自'],
    ['[B]\n協業案件', 'Toyo-Japan'],
    ['[B]\n協業案件', 'Toyo-India'],
    ['[B]\n協業案件', 'Toyo-Korea'],
    ['[B]\n協業案件', 'Toyo-Malaysia'],
    ['[B]\n協業案件', 'Toyo-China'],
    ['[B]\n協業案件', 'IKPT'],
    ['[B]\n協業案件', 'TPS'],
    ['[B]\n協業案件', 'TSPI'],
    ['[B]\n協業案件', '[B] 協業'],
    ['[A + B]\n全案件', 'Toyo-Japan'],
    ['[A + B]\n全案件', 'Toyo-India'],
    ['[A + B]\n全案件', 'Toyo-Korea'],
    ['[A + B]\n全案件', 'Toyo-Malaysia'],
    ['[A + B]\n全案件', 'Toyo-China'],
    ['[A + B]\n全案件', 'IKPT'],
    ['[A + B]\n全案件', 'TPS'],
    ['[A + B]\n全案件', 'TSPI'],
    ['[A + B]\n全案件', '[A + B] 合計'],
    ['[C]\n持分法適用会社', '　OFS(独自)'],
    ['[C]\n持分法適用会社', '　OFS(協業)'],
    ['[C]\n持分法適用会社', 'OFS(合計)'],
    ['合計', '[A + B + C]'],
  ],
  TABLE_DATA_MAPPING_KEY1: [
    { key: 'CN本部', value: '　　CN' },
    { key: '海外', value: '　　海外営業' },
    { key: '国内', value: '　　国内営業' },
    { key: '営業統括本部', value: '営業統括本部' },
    { key: 'OFS本部', value: 'OFS本部' },
    { key: 'Toyo-J(協業)合計', value: '[A] Toyo-J(協業)合計' },
    { key: 'Toyo-I', value: 'Toyo-India' },
    { key: 'Toyo-K', value: 'Toyo-Korea' },
    { key: 'Toyo-M', value: 'Toyo-Malaysia' },
    { key: 'Toyo-C', value: 'Toyo-China' },
    { key: 'IKPT', value: 'IKPT' },
    { key: 'TPS', value: 'TPS' },
    { key: 'TSPI', value: 'TSPI' },
    { key: '拠点(独自)合計', value: '[B]拠点(独自)合計' },
    { key: '連結合計', value: '[A + B]連結合計' },
    { key: 'OFS', value: '[C] OFS' },
    { key: 'ABC', value: '[A + B + C]' },
  ],
  TABLE_DATA_MAPPING_KEY2: [
    [
      { key: 'Toyo-J', value: 'Toyo-Japan' },
      { key: 'Toyo-I', value: 'Toyo-India' },
      { key: 'Toyo-K', value: 'Toyo-Korea' },
      { key: 'Toyo-M', value: 'Toyo-Malaysia' },
      { key: 'Toyo-C', value: 'Toyo-China' },
      { key: 'IKPT', value: 'IKPT' },
      { key: 'TPS', value: 'TPS' },
      { key: 'TSPI', value: 'TSPI' },
    ],
    [
      { key: 'OFS(独自)', value: '　OFS(独自)' },
      { key: 'OFS(独自)', value: '　OFS(協業' },
    ],
  ],
  SELECT_FOR_TABLE_COLUMN: [
    {
      label: '受注金額',
      value: 'a',
      desc: '受注金額',
    },
    {
      label: '受注粗利',
      value: 'b',
      desc: '受注粗利',
    },
    {
      label: '粗利率',
      value: 'c',
      desc: '粗利率',
    },
    {
      label: '跳ね返り粗利',
      value: 'd',
      desc: '跳ね返り粗利',
    },
    {
      label: '需要MH',
      value: 'e',
      desc: '需要MH',
    },
    {
      label: '付加価値',
      value: 'f',
      desc: '付加価値',
    },
    {
      label: 'MDあたり付加価値',
      value: 'g',
      desc: 'MDあたり付加価値',
    },
  ],
};
