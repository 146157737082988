import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Select, Space, Table } from 'antd';
import { useWindowSize } from '../../hooks/useWindowSize';
import APIList from '../../http/ApiList';
import { ChartGroup, ChartTableLayout } from '@meteor/frontend-core';
import DXDiffChart from '../../components/charts/dx-diff.chart';
import { AppContext } from '../../contexts/AppContext';
import useVersion from '../../hooks/useVersion';
import { camelCase, groupBy, mergeWith, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import Paramter from '../../components/paramter';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';

type DxDiffItem = {
  fiscalYear: string;
  /**  Plan❶当初計画  DX Allowance */
  dxAllowNetPlan01: number;
  /**  Plan❶当初計画  DX 課金 */
  dxChargePlan01: number;
  /**  Plan❷期初計画  DX Allowance */
  dxAllowNetPlan02: number;
  /**  Plan❷期初計画  DX 課金 */
  dxChargePlan02: number;
  /**  最新計画  DX Allowance */
  dxAllowNetPlanLatest: number;
  /**  最新計画  DX 課金 */
  dxChargePlanLatest: number;
  /**  最新計画、プロポーサル前  DX Allowance */
  dxAllowNetPlanLatestPreSale: number;
  /**  最新計画、プロポーサル前  DX 課金 */
  dxChargePlanLatestPreSale: number;
  /**  最新計画、プロポーサル済み  DX Allowance */
  dxAllowNetPlanLatestProposal: number;
  /**  最新計画、プロポーサル済み  DX 課金 */
  dxChargePlanLatestProposal: number;
  /**  最新計画、受注済み  DX Allowance */
  dxAllowNetPlanLatestExecution: number;
  /**  最新計画、受注済み  DX 課金 */
  dxChargePlanLatestExecution: number;
  /**  見込み+実績  DX Allowance */
  dxAllowNetForecastActualSum: number;
  /**  見込み+実績  DX 課金 */
  dxChargeForecastActualSum: number;
  /**  見込み  DX Allowance */
  dxAllowNetForecastSum: number;
  /**  見込み  DX 課金 */
  dxChargeForecastSum: number;
  /**  見込み、プロポーサル前  DX Allowance */
  dxAllowNetForecastPreSale: number;
  /**  見込み、プロポーサル前  DX 課金 */
  dxChargeForecastPreSale: number;
  /**  見込み、プロポーサル済み  DX Allowance */
  dxAllowNetForecastProposal: number;
  /**  見込み、プロポーサル済み  DX 課金 */
  dxChargeForecastProposal: number;
  /**  見込み、受注済み  DX Allowance */
  dxAllowNetForecastExecution: number;
  /**  見込み、受注済み  DX 課金 */
  dxChargeForecastExecution: number;
  /**  実績  DX Allowance */
  dxAllowNetActual: number;
  /**  実績  DX 課金 */
  dxChargeActual: number;
};

type Prefix = 'dxAllowNet' | 'dxCharge';
type RemovePrefix<T extends string, P extends string> = T extends `${P}${infer Key}` ? Uncapitalize<Key> : T;

type Transform<T, P extends string> = {
  [K in keyof T as RemovePrefix<K extends string ? K : '', P>]: T[K];
};

type TransformedItem = Transform<DxDiffItem, Prefix>;

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

const DXDiffPage = () => {
  const { t } = useTranslation();
  const { menuCollapsed, scrollCount, setScrollCount } = useContext(AppContext);
  const { paramData, setParamData } = useContext(AipcmctyContext);
  const { selectorHeight4Table } = useWindowSize({
    selector: '.dx-diff-table .cmcty-table-body',
    viewMode: 'chart-table',
  });

  // chart container
  const { selectorTop } = useWindowSize({
    selector: '.chart-section',
  });

  const { orgAllSnapshots, snapshot, snapshotVersion } = useVersion(false);
  const orgVersions = orgAllSnapshots
    .filter((o) => o.tag === 0)
    .map((o) => ({
      key: o.id,
      label: `${o.snapshot} ${o.snapshotVersionName}`,
      value: `${o.snapshot} ${o.snapshotVersion}`,
    }));
  const defaultOrgVersion = orgVersions[0];
  const [filterOpts, setFilterOpts] = useState({
    budgetOpts: [],
    tgcOpts: [],
    orgVersions: orgVersions,
  });
  const [filter, setFilter] = useState({
    budgets: ['Awarded', 'Budget', 'IF'],
    tgc: [],
    orgVersion: defaultOrgVersion.value,
  });
  const handleFilterChange = (e, key: keyof typeof filter) => {
    setFilter((prev) => ({ ...prev, [key]: e }));
  };

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    FY24: {},
    FY25: {},
    FY26: {},
  });
  const [dividedData, setDividedData] = useState<any>();
  const [tableData, setTableData] = useState([]);

  const keyMaps = new Map<string, string>([
    ['plan01', t('aipcmcty.page.dxDiffAnalyze.plan01')],
    ['plan02', t('aipcmcty.page.dxDiffAnalyze.plan02')],
    ['planLatest', t('aipcmcty.page.dxDiffAnalyze.planLatest')],
    ['planLatestPreSale', t('aipcmcty.page.dxDiffAnalyze.preSale')],
    ['planLatestProposal', t('aipcmcty.page.dxDiffAnalyze.proposal')],
    ['planLatestExecution', t('aipcmcty.page.dxDiffAnalyze.execution')],
    ['forecastActualSum', t('aipcmcty.page.dxDiffAnalyze.forecastAndActual')],
    ['forecastSum', t('aipcmcty.page.dxDiffAnalyze.forecast')],
    ['forecastPreSale', t('aipcmcty.page.dxDiffAnalyze.preSale')],
    ['forecastProposal', t('aipcmcty.page.dxDiffAnalyze.proposal')],
    ['forecastExecution', t('aipcmcty.page.dxDiffAnalyze.execution')],
    ['actual', t('aipcmcty.page.dxDiffAnalyze.actual')],
  ]);

  const swapAxisToObject = (data: TransformedItem[], baseKey: string) => {
    if (!data.length) {
      console.log('Data is Empty!');
      return [];
    }
    const transformKeys = Object.keys(data[0]).filter((k) => k !== baseKey);
    const transformedData = transformKeys.reduce((prev, k) => {
      prev[k] = {
        key: k,
        title: keyMaps.get(k),
      };
      return prev;
    }, {} as any);
    data.forEach((d) => {
      transformKeys.forEach((k) => {
        transformedData[k][d[baseKey]] = d[k];
      });
    });
    return transformedData;
  };

  useEffect(() => {
    initializePage();
  }, [snapshot, snapshotVersion]);

  const handleSearch = async () => {
    setLoading(true);
    await getChartAndTableData(filter.tgc, filter.budgets, ...(filter.orgVersion.split(' ') as [string, string]));
    setLoading(false);
  };

  const initializePage = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const [selectedTgc, selectedBudgets] = await Promise.all([
        getTgcOptions(),
        getBudgetsOptions()
      ]);
      const [orgSnapshot, orgVersion] = filter.orgVersion.split(' ');
      await getChartAndTableData(selectedTgc, selectedBudgets, orgSnapshot, orgVersion);
    } finally {
      setLoading(false);
    }
  };

  const getBudgetsOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'budgetCategory',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const budgetOpts = res.filter(r => r.value !== 'Exclusion').map((r) => ({
      key: r.value,
      label: r.value,
      value: r.value,
    }));
    setFilterOpts((prev) => ({
      ...prev,
      budgetOpts,
    }));
    const selectedBudgets = budgetOpts
      .filter((b) => ['Awarded', 'Budget', 'IF'].includes(b.value))
      .map(b => b.value);
    setFilter((prev) => ({ ...prev, budgets: selectedBudgets }));
    return selectedBudgets;
  };

  const getTgcOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'tgc',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const tgcOpts = res
      .filter((r) => r.attribute1 === 'subsidiary')
      .map((r) => ({
        key: r.value,
        label: r.value,
        value: r.value,
      }));
    setFilterOpts((prev) => ({
      ...prev,
      tgcOpts,
    }));
    const selectedTgc = tgcOpts.map((r) => r.value);
    setFilter((prev) => ({ ...prev, tgc: selectedTgc }));
    return selectedTgc;
  };

  const getChartAndTableData = async (tgc: string[], budgets: string[], orgSnapshot, orgVersion) => {
    const res = (await APIList.getDxDiffs().get({
      curSnapshot: snapshot,
      curVersion: snapshotVersion,
      orgSnapshot,
      orgVersion,
      tgc,
      budgets
    })) as DxDiffItem[];
    res.sort((a, b) => (a.fiscalYear < b.fiscalYear ? -1 : 1));
    const dividedData = divideByType(res);
    setDividedData(dividedData);
    genTableData(dividedData);
    genChartData(dividedData);
  };

  const rateDataFunc = (precessData, param, type: RATE_TYPE) => {
    const { progressRate, accumulateRate, cuteEate } = param;
    return Object.entries(precessData).reduce((acc, [key, value]) => {
      acc[key] =
        typeof value === 'number'
          ? (() => {
              if (type === 0) {
                return value * progressRate * accumulateRate * cuteEate;
              }
              if (type === 1) {
                return value * cuteEate;
              }
              return value;
            })()
          : value;
      return acc;
    }, {});
  };

  const genTableData = (dividedData: TransformedItem[]) => {
    if (!dividedData) {
      return;
    }
    const computeSum = (param) => {
      return mergeWith(
        {},
        rateDataFunc(data.planLatestPreSale, param, RATE_TYPE.ALL),
        rateDataFunc(data.planLatestProposal, param, RATE_TYPE.CUTE),
        rateDataFunc(data.planLatestExecution, param, RATE_TYPE.CUTE),
        (objValue, srcValue) => {
          return sum([objValue, srcValue]);
        }
      );
    };
    const data = swapAxisToObject(dividedData, 'fiscalYear');
    const tableData = [
      data.plan01,
      data.plan02,
      {
        ...computeSum(paramData.param1),
        key: 'planLatest1',
        title: `${t('aipcmcty.page.dxDiffAnalyze.planLatest')}(${t('aipcmcty.page.paramter')}1)`,
        children: [
          {
            ...rateDataFunc(data.planLatestPreSale, paramData.param1, RATE_TYPE.ALL),
            key: 'planLatestPreSale1',
          },
          {
            ...rateDataFunc(data.planLatestProposal, paramData.param2, RATE_TYPE.CUTE),
            key: 'planLatestProposal1',
          },
          {
            ...rateDataFunc(data.planLatestExecution, paramData.param3, RATE_TYPE.CUTE),
            key: 'planLatestExecution1',
          },
        ],
      },
      {
        ...computeSum(paramData.param2),
        key: 'planLatest2',
        title: `${t('aipcmcty.page.dxDiffAnalyze.planLatest')}(${t('aipcmcty.page.paramter')}2)`,
        children: [
          {
            ...rateDataFunc(data.planLatestPreSale, paramData.param2, RATE_TYPE.ALL),
            key: 'planLatestPreSale2',
          },
          {
            ...rateDataFunc(data.planLatestProposal, paramData.param2, RATE_TYPE.CUTE),
            key: 'planLatestProposa2',
          },
          {
            ...rateDataFunc(data.planLatestExecution, paramData.param2, RATE_TYPE.CUTE),
            key: 'planLatestExecution2',
          },
        ],
      },
      {
        ...computeSum(paramData.param3),
        key: 'planLatest3',
        title: `${t('aipcmcty.page.dxDiffAnalyze.planLatest')}(${t('aipcmcty.page.paramter')}3)`,
        children: [
          {
            ...rateDataFunc(data.planLatestPreSale, paramData.param3, RATE_TYPE.ALL),
            key: 'planLatestPreSale3',
          },
          {
            ...rateDataFunc(data.planLatestProposal, paramData.param3, RATE_TYPE.CUTE),
            key: 'planLatestProposal3',
          },
          {
            ...rateDataFunc(data.planLatestExecution, paramData.param3, RATE_TYPE.CUTE),
            key: 'planLatestExecution',
          },
        ],
      },
      {
        ...data.forecastActualSum,
        children: [
          {
            ...data.forecastSum,
            children: [data.forecastPreSale, data.forecastProposal, data.forecastExecution],
          },
          data.actual,
        ],
      },
    ];
    setTableData(tableData);
  };

  const genChartData = (dividedData: TransformedItem[]) => {
    const chartData = dividedData.reduce((prev, cur) => {
      const { 1: year, 2: bill } = cur.fiscalYear.match(/FY(\d{2})(Charge)?/);
      if (!prev[`FY${year}`]) {
        prev[`FY${year}`] = {};
      }
      const copiedData = { ...cur };
      Reflect.deleteProperty(copiedData, 'fiscalYear');
      if (!prev[`FY${year}`].allowance && !bill) {
        prev[`FY${year}`].allowance = copiedData;
      }
      if (!prev[`FY${year}`].billing && !!bill) {
        prev[`FY${year}`].billing = copiedData;
      }
      return prev;
    }, {} as any);
    setChartData(chartData);
  };

  const divideByType = (orgData: DxDiffItem[]): TransformedItem[] => {
    return orgData.reduce((prev, cur) => {
      const keys = Object.keys(cur);
      const chargeObj = {};
      const allowNetObj = {};
      keys.forEach((k) => {
        if (k === 'fiscalYear') {
          chargeObj[k] = `${cur[k]}Charge`;
          allowNetObj[k] = cur[k];
          return;
        }
        const reg = /dx(AllowNet|Charge)(.+)/g;
        const matches = reg.exec(k);
        if (!matches) {
          return;
        }
        const { 1: type, 2: fieldName } = matches;
        if (type === 'Charge') {
          chargeObj[camelCase(fieldName)] = Number(cur[k]);
        } else {
          allowNetObj[camelCase(fieldName)] = Number(cur[k]);
        }
      });
      prev.push(chargeObj, allowNetObj);
      return prev;
    }, []);
  };

  const tableCols = useMemo(
    () => [
      {
        title: '',
        key: 'title',
        dataIndex: 'title',
        align: 'left',
      },
      ...Object.keys(tableData[0] ?? {})
        .filter((k) => /FY\d{2}$/.test(k))
        .map((y) => ({
          title: y,
          key: y,
          dataIndex: y,
          children: ['', 'Charge'].map((txt) => ({
            title: txt === 'Charge' ? t('aipcmcty.page.dxDiffAnalyze.dxChargeTitle') : t('aipcmcty.page.dxDiffAnalyze.dxAllowNetTitle'),
            key: `${y}${txt}`,
            dataIndex: `${y}${txt}`,
            className: 'text-right',
            render: (value) => Number((value / 1000000).toFixed(0)).toLocaleString(),
          })),
        })),
    ],
    [tableData]
  );

  useEffect(() => {
    genTableData(dividedData);
  }, [paramData]);

  return (
    <div className="dx-diff">
      <Row justify="space-between" className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px', marginBottom: 2 }}>
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {/* ORG:
            <Select
              style={{ width: 240 }}
              options={filterOpts.orgVersions}
              value={filter.orgVersion}
              onChange={(e) => handleFilterChange(e, 'orgVersion')}
            />
            TGC:
            <Select
              mode="multiple"
              style={{ width: 180 }}
              options={filterOpts.tgcOpts}
              value={filter.tgc}
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange(e, 'tgc')}
            /> */}
            {t('aipcmcty.page.budgetCategory')}:
            <Select
              allowClear
              mode="multiple"
              style={{ width: 220 }}
              maxTagCount="responsive"
              options={filterOpts.budgetOpts}
              value={filter.budgets}
              onChange={(e) => handleFilterChange(e, 'budgets')}
            />
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Space>
        </Col>
      </Row>
      <ChartTableLayout viewMode="chart-table">
        <ChartTableLayout.Chart>
          <Row>
            <Col span={18}>
              <ChartGroup
                loading={false}
                syncScroll
                defaultItemCount={3}
                height={340}
                viewMode={'chart-table'}
                isScrollChart
                menuCollapsed={menuCollapsed}
                scrollCount={scrollCount}
                setScrollCount={setScrollCount}
                selectorTop={selectorTop}
                sizeMode={'small'}
              >
                {Object.entries(chartData).map(([cKey, cVal]) => (
                  <ChartGroup.Item key={`${cKey}`}>
                    <DXDiffChart data={cVal as any} title={`DX-${cKey}`} loading={loading} graphLoading={loading} graphHeight={340} />
                  </ChartGroup.Item>
                ))}
              </ChartGroup>
            </Col>
            <Col span={6}>
              <Paramter
                defaultValue={paramData}
                onChange={(data) => {
                  setParamData(data);
                }}
              />
            </Col>
          </Row>
        </ChartTableLayout.Chart>
        <ChartTableLayout.Table>
          <Table
            bordered
            size="small"
            style={{ marginTop: 5 }}
            className="dx-diff-table"
            columns={tableCols as any[]}
            dataSource={tableData}
            pagination={false}
            rowKey="key"
            loading={loading}
            expandable={{
              defaultExpandedRowKeys: ['planLatest1', 'planLatest2', 'planLatest3', 'forecastActualSum', 'forecastSum'],
            }}
            scroll={{ y: selectorHeight4Table - 78 }}
          />
        </ChartTableLayout.Table>
      </ChartTableLayout>
    </div>
  );
};

export enum RATE_TYPE {
  // Value * 施策進捗率 * 積込率 * 刈取率
  ALL,
  // Value * 刈取率
  CUTE,
}

export default DXDiffPage;
