/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Select, message } from 'antd';
import { uniqBy } from 'lodash';
import { ProjectSetupDetailContext } from '../contexts/project-setup-detail.context';
import { ProjectSetupContext } from '../contexts/project-setup.context';
import { useTranslation } from 'react-i18next';

type ProjectSetupDetailHeaderProp = {
  projectId: string;
  demandForecastType: string;
  loading?: boolean;
};

const ProjectSetupDetailHeader: React.FC<any> = (props: ProjectSetupDetailHeaderProp) => {
  const { loading } = props;
  const { t } = useTranslation();

  const [demandOption, setDemandOption] = useState<any>({
    division: [],
    discipline: [],
    category: [],
    stage: [],
    role: [],
  });

  const [division, setDivision] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [category, setCategory] = useState([]);
  const [stage, setStage] = useState([]);
  const [roles, setRoles] = useState([]);

  const { setSearchDemandForm, demandTableData } = useContext(ProjectSetupDetailContext);

  useEffect(() => {
    // filter form
    if (demandTableData) {
      const divisionOption = uniqBy(
        demandTableData
          .filter((i) => i.division)
          .map((item) => ({
            key: item.division,
            label: item.division,
            value: item.division,
          })),
        'key'
      );
      const disciplineOption = uniqBy(
        demandTableData
          .filter((i) => i.discipline)
          .map((item) => ({
            key: item.discipline,
            label: item.discipline,
            value: item.discipline,
          })),
        'key'
      );
      const categoryOption = uniqBy(
        demandTableData
          .filter((i) => i.category)
          .map((item) => ({
            key: item.category,
            label: item.category,
            value: item.category,
          })),
        'key'
      );
      const stageOption = uniqBy(
        demandTableData
          .filter((i) => i.stage)
          .map((item) => ({
            key: item.stage,
            label: item.stage,
            value: item.stage,
          })),
        'key'
      );
      const roleOption = uniqBy(
        demandTableData
          .filter((i) => i.role)
          .map((item) => ({
            key: item.role,
            label: item.role,
            value: item.role,
          })),
        'key'
      );
      setDemandOption({
        division: divisionOption,
        discipline: disciplineOption,
        category: categoryOption,
        stage: stageOption,
        role: roleOption,
      });
      const divisionValue = divisionOption.map((item: any) => item.value);
      const disciplineValue = disciplineOption.map((item: any) => item.value);
      const categoryValue = categoryOption.map((item: any) => item.value);
      const stageValue = stageOption.map((item: any) => item.value);
      const roleValue = roleOption.map((item: any) => item.value);
      setDivision(divisionValue);
      setDiscipline(disciplineValue);
      setCategory(categoryValue);
      setStage(stageValue);
      setRoles(roleValue);
      setSearchDemandForm({
        division: divisionValue,
        discipline: disciplineValue,
        category: categoryValue,
        stage: stageValue,
        roles: roleValue,
      });
      // setInit(false);
    }
  }, [demandTableData]);

  return (
    <div className="select-group-container flex-container" style={{ flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          padding: '8px 0',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label>Division</label>
            <div style={{ marginLeft: 30 }}>
              <Select
                allowClear
                mode="multiple"
                value={division}
                className="select-no-warp"
                style={{ width: 180 }}
                maxTagCount={1}
                maxTagTextLength={4}
                options={demandOption.division}
                onChange={(value) => {
                  setDivision(value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <label>Discipline</label>
            <div style={{ marginLeft: 30 }}>
              <Select
                allowClear
                mode="multiple"
                value={discipline}
                className="select-no-warp"
                style={{ width: 155 }}
                maxTagCount={1}
                maxTagTextLength={4}
                options={demandOption.discipline}
                onChange={(value) => {
                  setDiscipline(value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <label>Category</label>
            <div style={{ marginLeft: 30 }}>
              <Select
                allowClear
                mode="multiple"
                value={category}
                className="select-no-warp"
                style={{ width: 155 }}
                maxTagCount={1}
                maxTagTextLength={4}
                options={demandOption.category}
                onChange={(value) => {
                  setCategory(value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <label>Stage</label>
            <div style={{ marginLeft: 30 }}>
              <Select
                allowClear
                mode="multiple"
                value={stage}
                className="select-no-warp"
                style={{ width: 155 }}
                maxTagCount={1}
                maxTagTextLength={4}
                options={demandOption.stage}
                onChange={(value) => {
                  setStage(value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <label>Role</label>
            <div style={{ marginLeft: 30 }}>
              <Select
                allowClear
                mode="multiple"
                value={roles}
                className="select-no-warp"
                style={{ width: 155 }}
                maxTagCount={1}
                maxTagTextLength={4}
                options={demandOption.role}
                onChange={(value) => {
                  setRoles(value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <Button
              disabled={loading}
              onClick={() => {
                setSearchDemandForm({
                  division,
                  discipline,
                  category,
                  stage,
                  roles,
                });
              }}
            >
              {t('aipcmcty.page.extraction')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSetupDetailHeader;
