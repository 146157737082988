import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Space, Switch, Table, Tag } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ceil, cloneDeep, groupBy, has, isArray, isEmpty, isNumber, isObject, merge, sortBy, sumBy, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { useAccountPerspective } from '../../hooks/useAccountPerspective';
import { useWindowSize } from '../../hooks/useWindowSize';
import APIList from '../../http/ApiList';
import { convertToJSON, round } from '../../utils/commonUtil';
import { ChartGroup, TableChart } from '@meteor/frontend-core';

const TrinityEvaluationPage: React.FC = () => {
  // config
  const { color, menuCollapsed, scrollCount, setScrollCount } = useContext(AppContext);
  const { t } = useTranslation();
  const { snapshot: nowSnapshot, snapshotVersion: nowVersion, versionList, comment: nowSVComment } = useContext(AipcmctyContext);
  const NOW_LABEL = t('aipcmcty.page.trinityEvaluation.nowVersion');

  // filterOptions
  const [filterOptions, setFilterOptions] = useState({
    tgc: [],
    perspective: [],
    fy: [],
    snapshot: [],
    snapshotVersion: [],
  });

  // filter
  const [filterForm] = Form.useForm();
  const [listForm] = Form.useForm();
  const [displayGray, setDisplayGray] = useState(false);

  const { perspectiveOptions, setAccountPerspective, filterTgcOptions, selectedTgc, filterOptsAndSelected, perspectiveState } =
    useAccountPerspective(filterOptions.tgc);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // snapshot snapshotVersion legend table
  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState({
    tableSource: [],
    tableColumns: [],
  });

  // chart container
  const { selectorTop } = useWindowSize({
    selector: '.chart-section',
  });

  // chart legend
  const [legend, setLegend] = useState(['Awarded', 'Budget', 'DX_Awarded', 'DX_Budget']);

  const DX_LEGEND_MAP_COLOR = {
    Awarded: '#093B5E',
    Budget: '#3299D9',
    IF: '#9FD9F6',
    DX_Awarded: ['#6B288D', '#093B5E'],
    DX_Budget: ['#C15EF1', '#3299D9'],
    DX_IF: ['#DCABED', '#9FD9F6'],
  };

  const LEGEND_MAP_COLOR = {
    Awarded: '#093B5E',
    Budget: '#3299D9',
    IF: '#9FD9F6',
  };

  const [legendMap, setLegendMap] = useState<any>(DX_LEGEND_MAP_COLOR);
  const getLegendMap = (index = 0) =>
    Object.keys(legendMap).reduce((acc, cur) => {
      const legendColor = legendMap[cur];
      if (isArray(legendColor)) {
        acc[cur] = legendColor[index];
      } else {
        acc[cur] = legendColor;
      }
      return acc;
    }, {});

  // charts
  const [chartData, setChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  // const chartHeight = ((window.innerHeight - selectorTop - 135 - (expanded ? 31 * 2 : 0)) / 3);
  const chartHeight = (window.innerHeight - selectorTop - 65 - (expanded ? 31 * 2 : 0)) / 3;
  const chartOptions = {
    grid: {
      left: 45,
      right: 30,
      bottom: 30,
      top: 18,
    },
    legend: {
      show: false,
      type: 'scroll',
      top: 0,
      textStyle: {
        fontSize: 10,
      },
      itemGap: 5,
      itemWidth: 14,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      appendToBody: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 0,
        fontSize: 12,
        width: 50,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        onZero: true,
      },
      axisLabel: {
        fontSize: 12,
      },
      nameTextStyle: {
        fontSize: 12,
      },
      nameGap: 5,
    },
  };
  const { options, setSeriesInfo, getGroup } = useChartOptions([
    {
      group: GROUP_TYPE.UP,
      key: KPI_KEY.grossProfitOrders,
      title: t('aipcmcty.page.trinityEvaluation.orderGrossProfit'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.finance'), color: color.primaryColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.UP,
      key: KPI_KEY.grossProfitReboundFy24,
      title: t('aipcmcty.page.trinityEvaluation.grossProfitReboundFy24'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.finance'), color: color.primaryColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.UP,
      key: KPI_KEY.grossProfitReboundFy25,
      title: t('aipcmcty.page.trinityEvaluation.grossProfitReboundFy25'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.finance'), color: color.primaryColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.UP,
      key: KPI_KEY.mhDemandTotal,
      title: t('aipcmcty.page.trinityEvaluation.mhDemandTotal'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.resource'), color: color.warningColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.MIDDLE,
      key: KPI_KEY.grossProfitOrdersDx,
      title: t('aipcmcty.page.trinityEvaluation.orderGrossProfitDx'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: 'DX', color: '#7500c0' }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.MIDDLE,
      key: KPI_KEY.grossProfitReboundFy24Dx,
      title: t('aipcmcty.page.trinityEvaluation.grossProfitReboundFy24Dx'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: 'DX', color: '#7500c0' }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.MIDDLE,
      key: KPI_KEY.grossProfitReboundFy25Dx,
      title: t('aipcmcty.page.trinityEvaluation.grossProfitReboundFy25Dx'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: 'DX', color: '#7500c0' }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.MIDDLE,
      key: KPI_KEY.mhDemandTotalDx,
      title: t('aipcmcty.page.trinityEvaluation.mhDemandTotalDx'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: 'DX', color: '#7500c0' }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.UP,
      key: KPI_KEY.netProfitFy24,
      title: t('aipcmcty.page.trinityEvaluation.netProfitFy24'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.finance'), color: color.primaryColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.UP,
      key: KPI_KEY.netProfitFy25,
      title: t('aipcmcty.page.trinityEvaluation.netProfitFy25'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.finance'), color: color.primaryColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.DOWN,
      key: KPI_KEY.grossProfitPerMh,
      title: t('aipcmcty.page.trinityEvaluation.grossProfitPerMh'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.resource'), color: color.warningColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.DOWN,
      key: KPI_KEY.epcRatio,
      title: t('aipcmcty.page.trinityEvaluation.epcRatio'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.risk'), color: color.successColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.DOWN,
      key: KPI_KEY.expectedValue,
      title: t('aipcmcty.page.trinityEvaluation.expectedValue'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.risk'), color: color.successColor }],
      tooltips: ['zoomIn'],
    },
    {
      group: GROUP_TYPE.DOWN,
      key: KPI_KEY.preSalesRatio,
      title: t('aipcmcty.page.trinityEvaluation.preSalesRatio'),
      height: chartHeight,
      chartOptions: cloneDeep(chartOptions),
      ribbon: [{ label: t('aipcmcty.page.trinityEvaluation.risk'), color: color.successColor }],
      tooltips: ['zoomIn'],
    },
  ]);

  /**
   * APIから、チャットデータを取得
   */
  const getChartData = () => {
    setChartLoading(true);
    const snapshotVersions = [
      {
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
      },
      ...listForm.getFieldValue('list').map((item) => ({
        snapshot: item.snapshot,
        snapshotVersion: item.snapshotVersion,
      })),
    ];
    const kpis = [
      KPI_KEY.grossProfitOrders,
      KPI_KEY.grossProfitOrdersGoal,
      KPI_KEY.grossProfitOrdersDx,
      KPI_KEY.grossProfitReboundFy24,
      KPI_KEY.grossProfitReboundFy24Goal,
      KPI_KEY.grossProfitReboundFy24Dx,
      KPI_KEY.grossProfitReboundFy25,
      KPI_KEY.grossProfitReboundFy25Goal,
      KPI_KEY.grossProfitReboundFy25Dx,
      KPI_KEY.preSalesRatio,
      KPI_KEY.expectedValue,
      KPI_KEY.grossProfitPerMh,
      KPI_KEY.epcRatio,
      KPI_KEY.epcRatioGoal,
      KPI_KEY.mhDemandTotal,
      KPI_KEY.mhDemandTotalDx,
      KPI_KEY.netProfitFy24,
      KPI_KEY.netProfitFy25,
      KPI_KEY.netProfitFy24Goal,
      KPI_KEY.netProfitFy25Goal,
    ];
    let { fiscalYears, tgc } = filterForm.getFieldsValue();
    if (!fiscalYears.length) {
      fiscalYears = filterOptions.fy.map((item) => item.value);
    }
    if (!tgc.length) {
      tgc = filterTgcOptions.map((item) => item.value);
    }
    APIList.getTrinityEvaluationViz()
      .post({
        ...filterForm.getFieldsValue(),
        ...perspectiveState,
        snapshotVersions,
        kpis,
        fiscalYears,
        tgc,
      })
      .then((res) => {
        const checked = filterForm.getFieldValue('dxOn');
        if (checked) {
          setDisplayGray(false);
          setLegendMap(DX_LEGEND_MAP_COLOR);
        } else {
          setDisplayGray(true);
          setLegendMap(LEGEND_MAP_COLOR);
        }
        setChartData(
          res.map((data, index) => ({
            type: kpis[index],
            data: convertToJSON(data),
          }))
        );
      })
      .finally(() => {
        setChartLoading(false);
      });
  };

  /**
   * 初期化
   */
  useEffect(() => {
    setChartLoading(true);
    // snapshotとversionの列表を取得
    initSVOptions();
    // 会計年度とTGCの列表を取得
    Promise.all([
      APIList.getCmcOptions().get({
        category: 'accountingYear',
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
      }),
      APIList.getCmcOptions().get({
        category: 'tgc',
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
      }),
    ]).then(([ayOptions, tgcOptions]) => {
      initFilterOptions({ ayOptions, tgcOptions });
    });
  }, [nowSnapshot, nowVersion]);

  /**
   * チャットデータによると、チャットOptionをセット
   */
  useEffect(() => {
    if (chartData) {
      setChartOptions(chartData);
    }
  }, [chartData]);

  /**
   * チャットLegendによると、チャットOptionをセット
   */
  // useEffect(() => {
  //   setChartOptions(chartData);
  // }, [upLegend, middleLegend, downLegend]);
  useEffect(() => {
    setChartOptions(chartData);
  }, [legend]);

  /**
   * 計算したTGCから、TGCの値をセット
   */
  useEffect(() => {
    filterForm.setFieldsValue({
      tgc: selectedTgc,
    });
  }, [selectedTgc]);

  /**
   * snapshotとversionの列表を取得
   */
  const initSVOptions = () => {
    // const list = versionList.filter(item => !(item.snapshot === nowSnapshot && item.snapshotVersion === nowVersion));
    const snapshot = uniqBy(versionList, 'snapshot')
      .map((item: any) => ({ key: item.snapshot, label: item.snapshot, value: item.snapshot }))
      .sort((a, b) => (b.value > a.value ? 1 : -1));
    const snapshotVersion = versionList.map((item: any) => ({
      key: item.snapshotVersion,
      label: item.snapshotVersionName,
      value: item.snapshotVersion,
      snapshot: item.snapshot,
      snapshotVersionName: item.snapshotVersionName,
      comment: item.comment,
    }));
    setFilterOptions((prev) => ({ ...prev, snapshot, snapshotVersion }));
    let showList = [];
    let localSnapshotList = localStorage.getItem('snapshot-evaluation');
    if (!localSnapshotList && !JSON.parse(localSnapshotList)) {
      const findNo1 = versionList.find((item) => item.snapshot === '2024-02-28' && item.snapshotVersion === 'default');
      const findNo2 = versionList.find((item) => item.snapshot === '2024-08-14' && item.snapshotVersion === 'default');
      showList = [findNo1, findNo2];
    } else {
      showList = JSON.parse(localSnapshotList);
    }
    setListFormValues(showList);
    setTableDataHandler(showList);
  };

  const saveListToLocalStorage = (list) => {
    if (!isEmpty(list)) {
      localStorage.setItem('snapshot-evaluation', JSON.stringify(list));
    }
  };

  /**
   * 絞り込み条件の列表の初期化
   * @param param0
   */
  const initFilterOptions = ({ ayOptions, tgcOptions }) => {
    const [, tgcSelected] = filterOptsAndSelected(perspectiveOptions[1].value, tgcOptions);
    setFilterOptions((prev) => ({
      ...prev,
      tgc: tgcOptions.map((option) => ({
        ...option,
        disabled: true,
      })),
      perspective: perspectiveOptions.map((option) => ({
        ...option,
        disabled: option.value === 4,
      })),
      fy: ayOptions,
    }));
    filterForm.setFieldsValue({
      accountingPerspective: perspectiveOptions[1].value,
      tgc: tgcSelected,
      fiscalYears: ['FY24'],
      dxOn: true,
    });
    getChartData();
  };

  /**
   * チャットOptionをセット
   * @param data
   */
  const setChartOptions = (data) => {
    const snapshotVersionData = [
      ...getListFormValues(),
      {
        snapshot: nowSnapshot,
        snapshotVersion: nowVersion,
        defaultShow: nowSnapshot,
      },
    ];

    // 準備データが取得
    const getTempData = ({ key, stackKey, compute, x, y, yMaxGroup }) => {
      const processKey = key;
      const processGoalKey = `${key.replace(/Dx$/, '')}Goal`;
      const findData = data.find((item) => item.type === processKey)?.data;
      const findGoalData = data.find((item) => item.type === processGoalKey)?.data;
      const findYGroupDataList = data.filter((item) => yMaxGroup.includes(item.type));

      if (!findData) {
        return { tempData: [], tempGoalData: [] };
      }

      const tempData = [];
      const tempGoalData = [];
      const tempYGroupDataList = [];
      if (snapshotVersionData) {
        snapshotVersionData.forEach((snapshotVersionItem) => {
          const snapshotValue = snapshotVersionItem.snapshot;
          const versionValue = snapshotVersionItem.snapshotVersion;
          // data
          const findItems = findData.filter((item) => {
            const legendFlag = legend.includes(item[stackKey]);
            return legendFlag && item.snapshot === snapshotValue && item.snapshotVersion === versionValue;
          });
          if (findItems) {
            cloneDeep(findItems).forEach((findItem) => {
              findItem.defaultShow = snapshotVersionItem.defaultShow;
              tempData.push(findItem);
            });
          }
          // goal
          if (findGoalData) {
            const findGoalItems = findGoalData.filter((item) => item.snapshot === snapshotValue && item.snapshotVersion === versionValue);
            if (findGoalItems) {
              cloneDeep(findGoalItems).forEach((findItem) => {
                findItem.defaultShow = snapshotVersionItem.defaultShow;
                tempGoalData.push(findItem);
              });
            }
          }
          // y group
          if (yMaxGroup.length > 0 && findYGroupDataList.length > 0) {
            findYGroupDataList.forEach((findYGroupData) => {
              const tempYGroupData = [];
              const findYGroupItems = findYGroupData.data.filter(
                (item) => item.snapshot === snapshotValue && item.snapshotVersion === versionValue
              );
              if (findYGroupItems) {
                cloneDeep(findYGroupItems).forEach((findItem) => {
                  findItem.defaultShow = snapshotVersionItem.defaultShow;
                  tempYGroupData.push(findItem);
                });
              }
              tempYGroupDataList.push(tempYGroupData);
            });
          }
        });
      }

      if (compute) {
        const gbData = groupBy(tempData, 'defaultShow');
        const computeData = Object.keys(gbData).map((defaultShow) => {
          const data = gbData[defaultShow];
          return {
            defaultShow,
            [y]: compute(data),
          };
        });
        return {
          tempData: computeData,
          tempGoalData,
        };
      }

      let maxY;
      if (tempYGroupDataList.length > 0) {
        tempYGroupDataList.forEach((tempYGroupData) => {
          const showYGroupData = tempYGroupData.filter(
            (item) => (!has(item, stackKey) && !legend.every((value) => value.includes('DX'))) || legend.includes(item[stackKey])
          );
          const curMaxY = sumBy(showYGroupData, y);
          maxY = maxY > curMaxY ? maxY : curMaxY;
        });
      }

      return {
        tempData,
        tempGoalData,
        maxY,
      };
    };

    // 準備Seriesを取得
    const getSeriesDataset = ({
      hidden = false,
      showGray = false,
      key,
      title = undefined,
      x,
      y,
      goalType = GOAL_TYPE.NONE,
      singleSeries = false,
      stackKey,
      yUnit = undefined,
      compute = undefined,
      yMaxGroup = [],
    }) => {
      const { tempData, tempGoalData, maxY } = getTempData({
        key,
        stackKey,
        compute,
        x,
        y,
        yMaxGroup,
      });
      const selfTitle = title;
      // bar width
      const width = (window.innerWidth - 300) / 4;
      const preContainer = width / tableData.tableColumns.length - 1; // x count
      const barWidth = preContainer * 0.45;
      const targetWidth = barWidth * 1.3;

      let yAxis: any = {};
      let tooltip;
      if (yUnit === AXIS_UNIT.billion) {
        yAxis = {
          name: t('aipcmcty.page.billion'),
          axisLabel: {
            formatter: (value) => {
              const result = ceil(value / 100000000);
              if (`${result}`.length <= 1) {
                return ceil(value / 100000000, 1) || '';
              }
              return result;
            },
          },
        };
        if (!showGray) {
          tooltip = {
            show: true,
            formatter: (params) => {
              if (params && params.length > 0) {
                let tooltip = `<div style="text-align: left;"><span style="font-size: 16px;font-weight: bold;">${params[0]?.name}</span><br/>`;
                tooltip = params.reverse().reduce((acc, element) => {
                  if (isNumber(element.value) && element.value > 0) {
                    return `${acc}${element.marker} ${element.seriesName}:  ${element.value ? round(element.value / 100000000) : 0} <br/>`;
                  }
                  if (isObject(element.value)) {
                    return `${acc}${element.marker}${element.seriesName}:  ${
                      element.value ? round(element.value[y] / 100000000) : 0
                    } <br/>`;
                  }
                  return acc;
                }, tooltip);
                tooltip += '</div>';
                return tooltip;
              }
              return null;
            },
          };
        } else {
          tooltip = {
            show: false,
          };
        }
      } else if (yUnit === AXIS_UNIT.tenThousand) {
        yAxis = {
          name: t('aipcmcty.page.tenThousand'),
          axisLabel: {
            formatter: (value) => {
              const result = ceil(value / 10000);
              if (`${result}`.length <= 1) {
                return ceil(value / 10000, 1) || '';
              }
              return result;
            },
          },
        };
        if (!showGray) {
          tooltip = {
            show: true,
            formatter: (params) => {
              if (params && params.length > 0) {
                let tooltip = `<div style="text-align: left;"><span style="font-size: 16px;font-weight: bold;">${params[0]?.name}</span><br/>`;
                tooltip = params.reverse().reduce((acc, element) => {
                  if ([KPI_KEY.mhDemandTotalDx].includes(key) && element.value) {
                    return `${acc}${element.marker}${element.seriesName}:  ${
                      element.value
                        ? element.seriesName.includes('DX')
                          ? -round(element.value[y] / 10000)
                          : round(element.value[y] / 10000)
                        : 0
                    } <br/>`;
                  }
                  if (isNumber(element.value) && element.value > 0) {
                    return `${acc}${element.marker}${element.seriesName}:  ${element.value ? round(element.value / 10000) : 0} <br/>`;
                  }
                  if (isObject(element.value)) {
                    return `${acc}${element.marker}${element.seriesName}:  ${element.value ? round(element.value[y] / 10000) : 0} <br/>`;
                  }
                  return acc;
                }, tooltip);
                tooltip += '</div>';
                return tooltip;
              }
              return null;
            },
          };
        } else {
          tooltip = {
            show: false,
          };
        }
      } else if (yUnit === AXIS_UNIT.percent) {
        yAxis = {
          axisLabel: {
            formatter: (value) => `${round(value * 100)}%`,
          },
        };
        if (!showGray) {
          tooltip = {
            show: true,
            formatter: (params) => {
              if (params && params.length > 0) {
                let tooltip = `<div style="text-align: left;"><span style="font-size: 16px;font-weight: bold;">${params[0]?.name}</span><br/>`;
                tooltip = params.reverse().reduce((acc, element) => {
                  if (isNumber(element.value) && element.value > 0) {
                    return `${acc}${element.marker}${element.seriesName}:  ${element.value ? round(element.value * 100) : 0}% <br/>`;
                  }
                  if (isObject(element.value)) {
                    return `${acc}${element.marker}${element.seriesName}: ${round(element.value[y] * 100)}% <br/>`;
                  }
                  return acc;
                }, tooltip);
                tooltip += '</div>';
                return tooltip;
              }
              return null;
            },
          };
        } else {
          tooltip = {
            show: false,
          };
        }
      } else {
        yAxis = {};
        tooltip = showGray
          ? {
              show: false,
            }
          : {
              show: true,
              formatter: (params) => {
                if (params && params.length > 0) {
                  let tooltip = `<div style="text-align: left;"><span style="font-size: 16px;font-weight: bold;">${params[0]?.name}</span><br/>`;
                  tooltip = params
                    .reverse()
                    .reduce((acc, element) => `${acc}${element.marker}${element.seriesName}: ${round(element.value[y])} <br/>`, tooltip);
                  tooltip += '</div>';
                  return tooltip;
                }
                return null;
              },
            };
      }

      if (maxY) {
        yAxis.max = maxY * 1.1;
      }

      // 目標Seriesを取得
      const getGoalSeries = (goalType: GOAL_TYPE) => {
        // 目標データがないなら、目標バー表示されない
        if (tempGoalData.length === 0) {
          return { series: [] };
        }
        // DXありますだけなら、目標バー表示されない
        if (legend.every((value) => value.includes('DX'))) {
          return { series: [] };
        }
        if (goalType === GOAL_TYPE.BAR) {
          // targetを組み立て
          const greenList = [];
          const yellowList = [];
          const redList = [];
          const gbNoGoal = groupBy(tempGoalData, 'defaultShow');
          const gbNoData = groupBy(tempData, 'defaultShow');
          Object.keys(merge({}, gbNoData, gbNoGoal)).forEach((defaultShow) => {
            const data = sumBy(gbNoData[defaultShow], y) || 0;
            const goal = sumBy(gbNoGoal[defaultShow], y);
            const ratio = goal ? data / goal : 0;
            greenList.push(ratio >= 1 ? goal : 0);
            redList.push(ratio <= 0.9 ? goal : 0);
            yellowList.push(ratio > 0.9 && ratio < 1 ? goal : 0);
          });
          const series = [
            {
              name: '目標',
              type: 'bar',
              stack: 'goal',
              data: greenList,
              itemStyle: {
                color: '#9bca63',
              },
              barWidth: targetWidth,
            },
            {
              name: '目標',
              type: 'bar',
              stack: 'goal',
              data: yellowList,
              itemStyle: {
                color: '#faad14',
              },
              barWidth: targetWidth,
            },
            {
              name: '目標',
              type: 'bar',
              stack: 'goal',
              data: redList,
              itemStyle: {
                color: color.errorColor,
              },
              barWidth: targetWidth,
            },
          ];
          return { series };
        }
        if (goalType === GOAL_TYPE.LINE) {
          const gbNoData = groupBy(tempData, 'defaultShow');
          const gbNoGoal = groupBy(tempGoalData, 'defaultShow');
          const seriesItem = {
            name: '目標',
            type: 'line',
            data: Object.keys(merge({}, gbNoData, gbNoGoal)).map((defaultShow) => sumBy(gbNoGoal[defaultShow], y) || 0),
            symbol: 'none',
            itemStyle: {
              color: color.errorColor,
            },
            lineStyle: {
              color: color.errorColor,
              width: 2,
              type: 'dashed',
            },
          };
          return { series: [seriesItem] };
        }

        return { series: [] };
      };

      if (stackKey) {
        const { series: goalSeries } = getGoalSeries(goalType);
        if (singleSeries) {
          const seriesItem = {
            type: 'bar',
            name: t('aipcmcty.page.value'),
            label: { show: true },
            barWidth,
            encode: { x, y },
          };
          const series = [seriesItem];
          const dataset = [
            {
              dimensions: [x, y],
              source: tempData,
            },
          ];
          const xAxis = { data: snapshotVersionData.map((item) => item.defaultShow) };
          return {
            key,
            title: selfTitle,
            series: [...series, ...goalSeries],
            dataset,
            xAxis,
            yAxis,
            tooltip,
            showGray,
          };
        }
        const stacks = uniqBy(tempData, stackKey).map((item) => item[stackKey]);
        const series = sortBy(
          stacks.map((stackValue, index) => {
            const seriesItem: any = {
              name: stackValue,
              type: 'bar',
              stack: 'category',
              label: { show: true },
              datasetIndex: index + 1,
              encode: { x, y },
              barWidth,
              itemStyle: {
                color: getLegendMap()[stackValue],
              },
            };
            if (goalType === GOAL_TYPE.BAR) {
              seriesItem.barGap = '-115%';
              seriesItem.z = 10;
            }
            if ([KPI_KEY.mhDemandTotalDx].includes(key) && stackValue.includes('DX')) {
              seriesItem.itemStyle.opacity = 0;
            }
            return seriesItem;
          }),
          (item) => Object.keys(getLegendMap()).indexOf(item.name)
        );

        if ([KPI_KEY.mhDemandTotalDx].includes(key)) {
          series.push({
            name: 'DX',
            type: 'bar',
            stack: 'category',
            barWidth,
            barMinHeight: 2.5,
            itemStyle: {
              color: '#fff',
              decal: {
                color: color.errorColor,
                dashArrayX: [2, 2],
                dashArrayY: [3, 0],
                rotation: 0,
              },
            },
            data: Object.keys(groupBy(tempData, x)).map((v) => 0),
          });
        }

        const dataset = [
          {
            dimensions: [x, y, stackKey],
            source: tempData,
          },
          ...stacks.map((stackValue) => ({
            transform: [
              {
                type: 'filter',
                config: { dimension: stackKey, value: stackValue },
              },
            ],
          })),
        ];

        const xAxis = {
          data: snapshotVersionData
            .map((item) => item.defaultShow)
            .sort((a, b) => {
              if (a === nowSnapshot) return 1;
              if (b === nowSnapshot) return -1;
              return a.localeCompare(b);
            }),
        };
        return {
          hidden,
          key,
          title: selfTitle,
          series: [...series, ...goalSeries],
          dataset,
          xAxis,
          yAxis,
          tooltip,
          showGray,
        };
      }
    };

    setSeriesInfo([
      getSeriesDataset({
        key: KPI_KEY.expectedValue,
        x: 'defaultShow',
        y: 'expectedValue',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.percent,
        singleSeries: true,
        compute: (data) => {
          const wonOrderGrossProfit = sumBy(data, 'wonOrderGrossProfit');
          const notWonOrderGrossProfit = sumBy(data, 'notWonOrderGrossProfit');
          return notWonOrderGrossProfit ? wonOrderGrossProfit / notWonOrderGrossProfit : 0;
        },
      }),
      getSeriesDataset({
        key: KPI_KEY.preSalesRatio,
        x: 'defaultShow',
        y: 'countProjectRatio',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.percent,
        singleSeries: true,
        compute: (data) => {
          const preSalesCountProject = sumBy(data, 'preSalesCountProject');
          const allSalesCountProject = sumBy(data, 'preSalesCountProject') + sumBy(data, 'notPreSalesCountProject');
          return allSalesCountProject ? preSalesCountProject / allSalesCountProject : 0;
        },
      }),
      getSeriesDataset({
        key: KPI_KEY.epcRatio,
        goalType: GOAL_TYPE.LINE,
        x: 'defaultShow',
        y: 'epcRatio',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.percent,
        singleSeries: true,
        compute: (data) => {
          const epcOrderGrossProfit = sumBy(data, 'epcOrderGrossProfit');
          const allOrderGrossProfit = sumBy(data, 'epcOrderGrossProfit') + sumBy(data, 'notEpcOrderGrossProfit');
          return allOrderGrossProfit ? epcOrderGrossProfit / allOrderGrossProfit : 0;
        },
      }),
      getSeriesDataset({
        key: KPI_KEY.mhDemandTotal,
        x: 'defaultShow',
        y: 'demandMH',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.tenThousand,
        yMaxGroup: [KPI_KEY.mhDemandTotal, KPI_KEY.mhDemandTotalDx],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitReboundFy25,
        goalType: GOAL_TYPE.BAR,
        x: 'defaultShow',
        y: 'grossProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
        yMaxGroup: [KPI_KEY.grossProfitReboundFy25, KPI_KEY.grossProfitReboundFy25Dx, KPI_KEY.grossProfitReboundFy25Goal],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitReboundFy24,
        goalType: GOAL_TYPE.BAR,
        x: 'defaultShow',
        y: 'grossProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
        yMaxGroup: [KPI_KEY.grossProfitReboundFy24, KPI_KEY.grossProfitReboundFy24Dx, KPI_KEY.grossProfitReboundFy24Goal],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitOrders,
        goalType: GOAL_TYPE.BAR,
        x: 'defaultShow',
        y: 'orderGrossProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
        yMaxGroup: [KPI_KEY.grossProfitOrders, KPI_KEY.grossProfitOrdersDx, KPI_KEY.grossProfitOrdersGoal],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitOrdersDx,
        showGray: displayGray,
        goalType: GOAL_TYPE.BAR,
        x: 'defaultShow',
        y: 'orderGrossProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
        yMaxGroup: [KPI_KEY.grossProfitOrders, KPI_KEY.grossProfitOrdersDx, KPI_KEY.grossProfitOrdersGoal],
      }),
      getSeriesDataset({
        key: KPI_KEY.mhDemandTotalDx,
        showGray: displayGray,
        x: 'defaultShow',
        y: 'demandMH',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.tenThousand,
        yMaxGroup: [KPI_KEY.mhDemandTotal, KPI_KEY.mhDemandTotalDx],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitReboundFy25Dx,
        showGray: displayGray,
        goalType: GOAL_TYPE.BAR,
        x: 'defaultShow',
        y: 'grossProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
        yMaxGroup: [KPI_KEY.grossProfitReboundFy25, KPI_KEY.grossProfitReboundFy25Dx, KPI_KEY.grossProfitReboundFy25Goal],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitReboundFy24Dx,
        showGray: displayGray,
        goalType: GOAL_TYPE.BAR,
        x: 'defaultShow',
        y: 'grossProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
        yMaxGroup: [KPI_KEY.grossProfitReboundFy24, KPI_KEY.grossProfitReboundFy24Dx, KPI_KEY.grossProfitReboundFy24Goal],
      }),
      getSeriesDataset({
        key: KPI_KEY.grossProfitPerMh,
        x: 'defaultShow',
        y: 'grossProfitPerMh',
        stackKey: 'budgetCategoryCustom',
        singleSeries: true,
        compute: (data) => {
          const orderGrossProfit = sumBy(data, 'orderGrossProfit');
          const demandMH = sumBy(data, 'demandMH');
          return demandMH ? orderGrossProfit / demandMH : 0;
        },
      }),
      getSeriesDataset({
        hidden: filterForm.getFieldValue('accountingPerspective') !== 1,
        goalType: GOAL_TYPE.BAR,
        key: KPI_KEY.netProfitFy24,
        x: 'defaultShow',
        y: 'netProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
      }),
      getSeriesDataset({
        hidden: filterForm.getFieldValue('accountingPerspective') !== 1,
        goalType: GOAL_TYPE.BAR,
        key: KPI_KEY.netProfitFy25,
        x: 'defaultShow',
        y: 'netProfit',
        stackKey: 'budgetCategoryCustom',
        yUnit: AXIS_UNIT.billion,
      }),
    ]);
  };

  /**
   * snapshot versionのlistフォームをセット
   * @param list
   */
  const setListFormValues = (list) => {
    list.forEach((item, index) => {
      if (!item) {
        item = {};
      }
      item.defaultShow = `No.${index + 1}`;
    });
    listForm.setFieldsValue({ list });
  };

  /**
   * snapshot versionのlistフォームを取得
   * @returns
   */
  const getListFormValues = () => listForm.getFieldValue('list');

  /**
   * snapshotVersionの列表を取得
   * @param index
   * @returns
   */
  const getVersionOptions = (index) => {
    if (index === undefined) {
      return;
    }
    const options = filterOptions.snapshotVersion.filter((item) => {
      const infoList = getListFormValues();
      const versions = infoList
        .filter((info) => info.snapshot === infoList[index]?.snapshot && !(info.snapshotVersion === infoList[index]?.snapshotVersion))
        .map((info) => info.snapshotVersion);
      return infoList[index]?.snapshot === item.snapshot && !versions.includes(item.value);
    });
    return options;
  };

  /**
   * テーブルデータをセット
   * @param list
   */
  const setTableDataHandler = (list) => {
    const snapshotVersion = versionList.find((sv) => sv.snapshot === nowSnapshot && sv.snapshotVersion === nowVersion);
    const snapshotItem = {
      category: 'Snapshot',
      now: snapshotVersion?.snapshot,
    };
    const versionItem = {
      category: 'Version',
      now: snapshotVersion?.snapshotVersionName,
    };
    const tableSource = {
      category: '',
      now: `${nowSnapshot} ${nowSVComment ? `: ${nowSVComment}` : ``}`,
      children: [snapshotItem, versionItem],
    };
    list.forEach((item) => {
      snapshotItem[item.defaultShow] = item.snapshot;
      versionItem[item.defaultShow] = item.snapshotVersionName;
      tableSource[item.defaultShow] = `${item.defaultShow} ${item.comment ? `: ${item.comment}` : ``}`;
    });
    setTableData({
      tableColumns: [
        {
          key: 'category',
          title: '',
          dataIndex: 'category',
          width: 180,
        },
        ...list.map((item) => ({
          key: item.defaultShow,
          title: item.defaultShow,
          dataIndex: item.defaultShow,
          ellipsis: true,
        })),
        {
          key: 'now',
          title: nowVersion,
          dataIndex: 'now',
        },
      ],
      tableSource: [tableSource],
    });
  };

  /**
   * snapshot versionの切り替える操作
   * @param changedFields
   * @returns
   */
  const snapshotChangeHandler = (changedFields) => {
    if (changedFields.length === 0) {
      return;
    }
    const [name, index, key] = changedFields[0].name as any[];
    const infoList = getListFormValues();
    const versionOptions = getVersionOptions(index);
    if (key === 'snapshot') {
      setFilterOptions({ ...filterOptions });
      infoList[index] = {
        ...infoList[index],
        snapshot: infoList[index]?.snapshot,
        snapshotVersion: versionOptions[0]?.value,
        snapshotVersionName: versionOptions[0]?.snapshotVersionName,
        comment: versionOptions[0]?.comment,
      };
    }
    if (key === 'snapshotVersion') {
      const option = versionOptions.find((sv) => sv.value === changedFields[0].value);
      infoList[index] = {
        ...infoList[index],
        snapshotVersionName: option?.snapshotVersionName,
        comment: option?.comment,
      };
    }
    setListFormValues(infoList);
  };

  /**
   * snapshot versionを比較
   */
  const snapshotVersionCompareHandler = () => {
    const list = listForm.getFieldValue('list') || [];
    saveListToLocalStorage(list);
    setIsModalOpen(false);
    setTableDataHandler(list);
    getChartData();
  };

  const switchChangeHandler = (checked) => {
    if (checked) {
      setFilterOptions({
        ...filterOptions,
        perspective: filterOptions.perspective.map((option) => ({
          ...option,
          disabled: option.value === 4,
        })),
        tgc: filterOptions.tgc.map((option) => ({
          ...option,
          disabled: true,
        })),
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        perspective: filterOptions.perspective.map((option) => ({
          ...option,
          disabled: false,
        })),
        tgc: filterOptions.tgc.map((option) => ({
          ...option,
          disabled: false,
        })),
      });
    }
  };

  return (
    <div className="trinity-evaluation">
      <div className="select-group-container flex-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px 18px 0',
            justifyContent: 'space-between',
            width: 'calc(100%)',
          }}
        >
          <Form form={filterForm} style={{ padding: '0 8px' }} name="filters" autoComplete="off">
            <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} align="baseline">
              <Space style={{ display: 'flex' }} align="baseline">
                <Form.Item name="accountingPerspective" label={t('aipcmcty.page.accountingPerspective')} style={{ marginBottom: 12 }}>
                  <Select
                    style={{ width: 220 }}
                    maxTagCount="responsive"
                    options={filterOptions.perspective}
                    onChange={(value) => {
                      setAccountPerspective(value);
                      setChartOptions(chartData);
                    }}
                  />
                </Form.Item>
                <Form.Item name="tgc" label="TGC" style={{ marginBottom: 12 }}>
                  <Select
                    mode="multiple"
                    className="select-height-32"
                    style={{ width: 220 }}
                    maxTagCount={1}
                    maxTagTextLength={6}
                    options={filterTgcOptions}
                  />
                </Form.Item>
                <Form.Item name="fiscalYears" label={t('aipcmcty.page.trinityEvaluation.fiscalYears')} style={{ marginBottom: 12 }}>
                  <Select
                    mode="multiple"
                    className="select-height-32"
                    style={{ width: 220 }}
                    maxTagCount={1}
                    maxTagTextLength={6}
                    options={filterOptions.fy}
                  />
                </Form.Item>
                <Form.Item name="dxOn" label="DX" style={{ marginBottom: 12 }}>
                  <Switch
                    disabled={chartLoading}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    defaultChecked
                    onChange={switchChangeHandler}
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: 12 }}>
                  <Button
                    disabled={chartLoading}
                    type="dashed"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('aipcmcty.page.trinityEvaluation.compareVersionAdd')}
                  </Button>
                </Form.Item>
              </Space>
              <Space style={{ display: 'flex', flex: 1 }} align="baseline">
                <Form.Item style={{ marginBottom: 12 }}>
                  <Button
                    disabled={chartLoading}
                    type="primary"
                    onClick={() => {
                      getChartData();
                    }}
                  >
                    {t('aipcmcty.page.trinityEvaluation.compare')}
                  </Button>
                </Form.Item>
              </Space>
            </Space>
          </Form>
        </div>
      </div>
      <Table
        showHeader={false}
        className="legend-table"
        bordered
        size="small"
        pagination={false}
        columns={tableData.tableColumns}
        dataSource={tableData.tableSource}
        expandable={{
          onExpand: (expanded, record) => {
            setExpanded(expanded);
          },
        }}
      />
      <div className="chart-section">
        {/* <Card bodyStyle={{ padding: 6 }}>
          <div style={{ display: 'flex' }}>
            {Object.keys(upLegendMap).map(key => (
              <div
                key={`${key}_UP`} style={{
                  cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }} onClick={() => {
                  if (upLegend.includes(key)) {
                    if (upLegend.length > 1) {
                      setUpLegend(upLegend.filter(item => item !== key));
                    }
                  } else {
                    setUpLegend([...upLegend, key]);
                  }
                }}
              >
                {upLegend.includes(key)
                  ? (
                    <>
                      <span style={{
                        display: 'block', width: 14, height: 14, borderRadius: 3, marginRight: 15, background: upLegendMap[key]
                      }}
                      />
                      <span>{key}</span>
                    </>
                  )
                  : (
                    <>
                      <span style={{
                        display: 'block', width: 14, height: 14, borderRadius: 3, marginRight: 15, background: '#ccc'
                      }}
                      />
                      <span style={{ color: '#ccc' }}>{key}</span>
                    </>
                  )}
              </div>
            ))}
          </div>
        </Card> */}
        <Card
          styles={{
            body: { padding: 6 },
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ marginLeft: 10 }}>{t('aipcmcty.page.trinityEvaluation.legend')}：</div>
            {Object.keys(getLegendMap()).map((key) => (
              <div
                key={`${key}_LEGEND`}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 20,
                }}
                onClick={() => {
                  if (legend.includes(key)) {
                    if (legend.length > 1) {
                      setLegend(legend.filter((item) => item !== key));
                    }
                  } else {
                    setLegend([...legend, key]);
                  }
                }}
              >
                {legend.includes(key) ? (
                  <>
                    <span
                      style={{
                        position: 'relative',
                        display: 'block',
                        width: 14,
                        height: 14,
                        borderRadius: 3,
                        marginRight: 15,
                        background: getLegendMap()[key],
                      }}
                    />
                    <span>{key}</span>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        display: 'block',
                        width: 14,
                        height: 14,
                        borderRadius: 3,
                        marginRight: 15,
                        background: '#ccc',
                      }}
                    />
                    <span style={{ color: '#ccc' }}>{key}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </Card>
        <ChartGroup
          loading={false}
          syncScroll
          height={chartHeight}
          viewMode={'chart-table'}
          isScrollChart
          menuCollapsed={menuCollapsed}
          scrollCount={scrollCount}
          setScrollCount={setScrollCount}
          selectorTop={selectorTop}
          sizeMode={'small'}
        >
          {options
            .filter((option) => option.group === GROUP_TYPE.UP && !option.hidden)
            .map((option) => (
              <ChartGroup.Item key={option.title}>
                <TableChart
                  tooltips={option.tooltips}
                  loading={chartLoading}
                  title={option.title}
                  showTable={false}
                  chartOptions={option.chartOptions}
                  height={chartHeight}
                />
              </ChartGroup.Item>
            ))}
        </ChartGroup>
        {/* <Card bodyStyle={{ padding: 6 }}>
          <div style={{ display: 'flex' }}>
            {Object.keys(middleLegendMap).map(key => (
              <div
                key={`${key}_MIDDLE`} style={{
                  cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }} onClick={() => {
                  if (middleLegend.includes(key)) {
                    if (middleLegend.length > 1) {
                      setMiddleLegend(middleLegend.filter(item => item !== key));
                    }
                  } else {
                    setMiddleLegend([...middleLegend, key]);
                  }
                }}
              >
                {middleLegend.includes(key)
                  ? (
                    <>
                      <span style={{
                        display: 'block', width: 14, height: 14, borderRadius: 3, marginRight: 15, background: middleLegendMap[key]
                      }}
                      />
                      <span>{key}</span>
                    </>
                  )
                  : (
                    <>
                      <span style={{
                        display: 'block', width: 14, height: 14, borderRadius: 3, marginRight: 15, background: '#ccc'
                      }}
                      />
                      <span style={{ color: '#ccc' }}>{key}</span>
                    </>
                  )}
              </div>
            ))}
          </div>
        </Card> */}

        <ChartGroup
          loading={false}
          syncScroll
          height={chartHeight}
          viewMode={'chart-table'}
          isScrollChart
          menuCollapsed={menuCollapsed}
          scrollCount={scrollCount}
          setScrollCount={setScrollCount}
          selectorTop={selectorTop}
          sizeMode={'small'}
        >
          {options
            .filter((option) => option.group === GROUP_TYPE.MIDDLE && !option.hidden)
            .map((option) => (
              <ChartGroup.Item key={option.title}>
                <div className={option.showGray && !chartLoading ? 'display-gray' : ''} style={{ height: chartHeight }}>
                  <TableChart
                    tooltips={displayGray ? [] : option.tooltips}
                    loading={chartLoading}
                    title={option.title}
                    showTable={false}
                    chartOptions={option.chartOptions}
                    height={chartHeight}
                  />
                </div>
              </ChartGroup.Item>
            ))}
        </ChartGroup>
        {/* <Card bodyStyle={{ padding: 6 }}>
          <div style={{ display: 'flex' }}>
            {Object.keys(downLegendMap).map(key => (
              <div
                key={`${key}_DOWN`} style={{
                  cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }} onClick={() => {
                  if (downLegend.includes(key)) {
                    if (downLegend.length > 1) {
                      setDownLegend(downLegend.filter(item => item !== key));
                    }
                  } else {
                    setDownLegend([...downLegend, key]);
                  }
                }}
              >
                {downLegend.includes(key)
                  ? (
                    <>
                      <span style={{
                        display: 'block', width: 14, height: 14, borderRadius: 3, marginRight: 15, background: downLegendMap[key]
                      }}
                      />
                      <span>{key}</span>
                    </>
                  )
                  : (
                    <>
                      <span style={{
                        display: 'block', width: 14, height: 14, borderRadius: 3, marginRight: 15, background: '#ccc'
                      }}
                      />
                      <span style={{ color: '#ccc' }}>{key}</span>
                    </>
                  )}
              </div>
            ))}
          </div>
        </Card> */}
        <ChartGroup
          loading={false}
          syncScroll
          height={chartHeight}
          viewMode={'chart-table'}
          isScrollChart
          menuCollapsed={menuCollapsed}
          scrollCount={scrollCount}
          setScrollCount={setScrollCount}
          selectorTop={selectorTop}
          sizeMode={'small'}
        >
          {options
            .filter((option) => option.group === GROUP_TYPE.DOWN && !option.hidden)
            .map((option) => (
              <ChartGroup.Item key={`${option.title}`}>
                <TableChart
                  tooltips={option.tooltips}
                  loading={chartLoading}
                  title={option.title}
                  showTable={false}
                  chartOptions={option.chartOptions}
                  height={chartHeight}
                />
              </ChartGroup.Item>
            ))}
        </ChartGroup>
      </div>
      <Modal
        width={650}
        title={t('aipcmcty.page.trinityEvaluation.compareVersionAdd')}
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <Form
          name="snapshotVersions"
          form={listForm}
          onFieldsChange={(changedFields, allFields) => {
            snapshotChangeHandler(changedFields);
          }}
        >
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item hidden {...restField} name={[name, 'defaultShow']} style={{ marginBottom: 12 }}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('aipcmcty.page.trinityEvaluation.compareSnapshot')}
                      name={[name, 'snapshot']}
                      style={{ marginBottom: 12 }}
                    >
                      <Select style={{ width: 150 }} options={filterOptions.snapshot} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('aipcmcty.page.trinityEvaluation.compareVersion')}
                      name={[name, 'snapshotVersion']}
                      style={{ marginBottom: 12 }}
                    >
                      <Select style={{ width: 150 }} options={getVersionOptions(name)} />
                    </Form.Item>
                    <Form.Item hidden {...restField} name={[name, 'snapshotVersionName']} style={{ marginBottom: 12 }}>
                      <Input />
                    </Form.Item>
                    <Form.Item hidden {...restField} name={[name, 'comment']} style={{ marginBottom: 12 }}>
                      <Input />
                    </Form.Item>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name);
                          setListFormValues(
                            getListFormValues().map((item, index) => ({
                              ...item,
                            }))
                          );
                        }}
                      />
                    )}
                  </Space>
                ))}
                {fields.length < 4 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ width: 510 }}
                      onClick={() => {
                        add();
                        setListFormValues(
                          getListFormValues().map((item, index) => ({
                            ...item,
                          }))
                        );
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item style={{ marginTop: 30 }} wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                snapshotVersionCompareHandler();
              }}
            >
              {t('aipcmcty.page.trinityEvaluation.compare')}
            </Button>
            <Button
              htmlType="button"
              style={{ margin: '0 8px' }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              {t('aipcmcty.page.cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

enum AXIS_UNIT {
  billion,
  tenThousand,
  percent,
}

const useChartOptions = (params: any[]) => {
  const [options, setOptions] = useState<any[]>(params.map((param) => ({ ...param, title: { value: param.key } })));

  const setSeriesInfo = (
    seriesInfo: {
      hidden?: boolean;
      key;
      title;
      dataset?: any;
      series?: any[];
      xAxis?: any;
      yAxis?: any;
      tooltip?: any;
      grid?: any;
      showGray?: boolean;
    }[]
  ) => {
    options.forEach((option, index) => {
      const { key, ribbon } = option;
      const seriesInfoByKey = seriesInfo.find((item) => item.key === key);
      const title = seriesInfoByKey?.title || params[index].title;
      const styles = {
        styles: {
          fontSize: 18,
          paddingLeft: 0,
        },
      };
      option.hidden = seriesInfoByKey?.hidden;
      option.title = {
        styles,
        value: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {ribbon.map(({ label, color }) => (
              <Tag style={{ height: 22 }} color={color} key={label}>
                {label}
              </Tag>
            ))}
            {title}
          </div>
        ),
      };
      if (seriesInfoByKey) {
        if (seriesInfoByKey.dataset) {
          option.chartOptions.dataset = seriesInfoByKey.dataset;
        }
        if (seriesInfoByKey.series) {
          option.chartOptions.series = seriesInfoByKey.series;
        }
        if (seriesInfoByKey.xAxis) {
          option.chartOptions.xAxis = { ...option.chartOptions.xAxis, ...seriesInfoByKey.xAxis };
        }
        if (seriesInfoByKey.yAxis) {
          option.chartOptions.yAxis = { ...option.chartOptions.yAxis, ...seriesInfoByKey.yAxis };
        }
        if (seriesInfoByKey.tooltip) {
          option.chartOptions.tooltip = { ...option.chartOptions.tooltip, ...seriesInfoByKey.tooltip };
        }
        if (seriesInfoByKey.grid) {
          option.chartOptions.grid = { ...option.chartOptions.grid, ...seriesInfoByKey.grid };
        }
        option.showGray = seriesInfoByKey.showGray;
      }
    });
    setOptions(cloneDeep(options));
  };

  const getGroup = (key) => options.find((item) => item.key === key)?.group;

  return { options, setSeriesInfo, getGroup };
};

export const enum GROUP_TYPE {
  UP = 'UP',
  MIDDLE = 'MIDDLE',
  DOWN = 'DOWN',
}

export const enum GOAL_TYPE {
  NONE = 'NONE',
  BAR = 'BAR',
  LINE = 'LINE',
}

export const enum KPI_KEY {
  // 受注粗利
  grossProfitOrders = 'grossProfitOrders',
  // 受注粗利-Goal
  grossProfitOrdersGoal = 'grossProfitOrdersGoal',
  // 受注粗利-Dx
  grossProfitOrdersDx = 'grossProfitOrdersDx',
  // FY24粗利跳ね返り
  grossProfitReboundFy24 = 'grossProfitReboundFy24',
  // FY24粗利跳ね返り-Goal
  grossProfitReboundFy24Goal = 'grossProfitReboundFy24Goal',
  // FY24粗利跳ね返り-Dx
  grossProfitReboundFy24Dx = 'grossProfitReboundFy24Dx',
  // FY25粗利跳ね返り
  grossProfitReboundFy25 = 'grossProfitReboundFy25',
  // FY25粗利跳ね返り-Goal
  grossProfitReboundFy25Goal = 'grossProfitReboundFy25Goal',
  // FY25粗利跳ね返り-Dx
  grossProfitReboundFy25Dx = 'grossProfitReboundFy25Dx',
  // 需要MH総量（MH）
  mhDemandTotal = 'mhDemandTotal',
  // 需要MH総量（MH）-Dx
  mhDemandTotalDx = 'mhDemandTotalDx',
  // EPC比率（粗利ベース）
  epcRatio = 'epcRatio',
  // EPC比率（粗利ベース）-Goal
  epcRatioGoal = 'epcRatioGoal',
  // 期待値（粗利ベース）
  expectedValue = 'expectedValue',
  // プレセールス件数比率
  preSalesRatio = 'preSalesRatio',
  // MHあたり粗利
  grossProfitPerMh = 'grossProfitPerMh',
  // FY24純利益
  netProfitFy24 = 'netProfitFy24',
  // FY25純利益
  netProfitFy25 = 'netProfitFy25',
  // FY24純利益-Goal
  netProfitFy24Goal = 'netProfitFy24Goal',
  // FY25純利益-Goal
  netProfitFy25Goal = 'netProfitFy25Goal',
}

export default TrinityEvaluationPage;
